import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Field } from '../../../api/models/club'
import { FieldType, FormErrors } from '../../../api/models/common'
import { Module } from '../../../api/models/permission'
import { Permission, PermissionGroup, PermissionResponse, RoleResponse } from '../../../api/models/role'
import { getPermissions } from '../../../api/role'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import FormSection from '../components/FormSection'
import MenuConfiguration from './components/MenuConfiguration'

export interface RoleFormProps {
  role: Partial<RoleResponse>
  setRole: Dispatch<SetStateAction<Partial<RoleResponse>>>
  setNewModules: Dispatch<SetStateAction<Module[]>>
  errors: FormErrors<RoleResponse>
  handleSubmit: () => void
}

const fields: Field<RoleResponse>[] = [
  {
    name: 'name',
    label: 'label.name',
    type: FieldType.TEXT,
    required: true
  },
  {
    name: 'description',
    label: 'label.description',
    type: FieldType.TEXT,
    columnSize: 12
  }
]

export const RoleForm = ({ role, setRole, setNewModules, errors, handleSubmit }: RoleFormProps) => {
  const { t } = useTranslation()
  const { isMasterUser } = useAuthContext()

  const [permissions, setPermissions] = useState<PermissionResponse>()
  const [newPermissions, setNewPermissions] = useState<number[]>(role?.permissions?.map((permission) => permission.id) || [])
  const prevRoleRef = useRef<Partial<RoleResponse>>(role)

  const onChangeRole = (id: number) => {
    if (!newPermissions?.includes(id)) {
      setNewPermissions([...newPermissions, id])
    } else {
      setNewPermissions(newPermissions.filter((perm) => perm !== id))
    }
  }

  const showGroup = (group: Permission[]) => {
    if (isMasterUser || group.some((permission) => !permission.onlyMaster)) return true
    return false
  }

  useEffect(() => {
    if (permissions) {
      const flattenPermission: Permission[] = Object.keys(permissions!).reduce(
        (acc: Permission[], key) => [...acc, ...permissions![key as PermissionGroup]],
        []
      )
      setRole({ ...role, permissions: flattenPermission?.filter((permission) => newPermissions?.includes(permission.id)) })
    }
  }, [newPermissions])

  useEffect(() => {
    if (!role?.permissions) return
    if (!prevRoleRef.current.permissions && role?.permissions) {
      setNewPermissions(role?.permissions?.map((permission) => permission.id))
      prevRoleRef.current = role
    }
  }, [role])

  useEffect(() => {
    getPermissions().then((response) => {
      setPermissions(response)
    })
  }, [])

  return (
    <div className="container-fluid mt-3">
      <FormSection startCollapsed={false} fields={fields} formObject={role} setFormObject={setRole} errors={errors} />
      <MenuConfiguration role={role} setNewModules={setNewModules} />
      <div className="row">
        {permissions &&
          Object.keys(permissions).map((permissionGroup) => (
            <>
              {showGroup(permissions[permissionGroup as PermissionGroup]) ? (
                <div className="col-4" key={permissionGroup}>
                  <div className="card collapsed-card">
                    <div className="card-header">
                      <h3 className="card-title">{t(`roles.${permissionGroup}`)}</h3>
                      <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="form-role">
                        {permissions[permissionGroup as PermissionGroup].map((permission, index) => (
                          <>
                            {isMasterUser || !permission.onlyMaster ? (
                              <div key={index} className="form-check">
                                <input
                                  checked={newPermissions?.includes(permission.id)}
                                  onChange={() => onChangeRole(permission.id)}
                                  value={permission.id}
                                  name="permission[]"
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <label className="form-check-label">{t(`roles.${permission.name.replaceAll('.', '_')}`)}</label>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))}
      </div>
      <div className="col-12 mt-2 card-body">
        <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit}>
          {t('app.save')}
        </button>
      </div>
    </div>
  )
}

export default RoleForm
