export enum PrivacyOptionType {
  CONSENT = 'consent',
  DECLARATION = 'declaration',
  FIELD = 'field'
}

export enum PrivacyElement {
  PRIVACY = 'privacy',
  TERMS = 'terms',
  INTERNAL_RULES = 'internalRules',
  INTERNAL_COMMUNICATIONS = 'internalCommunications',
  IMAGE_USAGE = 'imageUsage',
  MEDICAL_DECLARATION = 'medicalDeclaration',
  TUTOR_DECLARATION = 'tutorDeclaration',
  BANK_ACCOUNT = 'bankAccount',
  COMMENTS = 'comments'
}

export interface PrivacyFormConfigElement {
  id: string
  name: PrivacyElement
  type: string
  showInForm?: boolean
  required?: boolean
  defaultDoc?: boolean
  hasCustomDoc?: boolean
  customDoc?: File | null
  documentPath?: string
}

export type PrivacyFormConfigGroups = {
  [k in PrivacyOptionType]?: PrivacyFormConfigElement[]
}

export type PrivacyFormConfigRequest = {
  [key in PrivacyElement]?: PrivacyFormConfigElement
}

export type MemberPrivacyRequest = {
  [key in PrivacyElement]?: boolean | string
}

export interface PrivacyConfigRequest {
  token?: string
}
