import { useTranslation } from 'react-i18next'

import { PermissionGroup } from '../../api/models/role'
import ClubList from '../../components/Club/ClubList'
import CardHeader from '../../components/common/CardHeader'
import Layout from '../../components/common/Layout'

export const ListClubs = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid mt-5">
          <div className="card">
            <CardHeader title={t('clubs.title')} group={PermissionGroup.CLUB} />
            <ClubList />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ListClubs
