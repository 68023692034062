import { useEffect, useState } from 'react'

import { Option } from '../../../../api/models/club'
import { getColors } from '../../../../api/params'

interface ColorPickerProps {
  onChange?: (_: Option) => void
  value?: Option
}

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  const [colors, setColors] = useState<Array<Option>>([])
  const [backgroundColor, setBackgroundColor] = useState(value?.label || '')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    getColors().then((response) => {
      const colorOptions = response.map((color) => ({ value: color.id, label: color.code }))
      const firstColor = colorOptions[Math.floor(Math.random() * response.length)]
      setColors(colorOptions)
      if (!backgroundColor) {
        setBackgroundColor(firstColor.label)
        onChange && onChange(firstColor)
      }
    })
  }, [])

  const onClickPicker = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className={`color-picker bg-${backgroundColor}`} onClick={onClickPicker}></div>
      {open && (
        <div className="dropdown-menu d-flex flex-wrap" style={{ top: 'auto', maxWidth: 240 }}>
          {colors.map((color, index) => (
            <div
              key={index}
              className={`d-flex color-picker-item m-1 bg-${color.label}`}
              onClick={() => {
                onChange && onChange(color)
                setBackgroundColor(color.label)
                setOpen(false)
              }}
            ></div>
          ))}
        </div>
      )}
    </>
  )
}

export default ColorPicker
