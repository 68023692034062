import { Module } from './permission'

export enum NotificationType {
  PREREGISTRATION = 'preregistration',
  ACCEPT_PRIVACY = 'accept_privacy',
  INFO_REQUEST = 'info_request'
}
export interface Notification {
  id: string
  type: NotificationType
  data?: {
    id: number | string
    name: string
    lastname: string
  }
  readAt: Date
  createdAt: Date
}

export interface UserResponse {
  id?: number
  name: string
  lastname: string
  email: string
  phone?: string
  status?: string
  lang: string
  clubId: number
  clubCode?: string
  club?: number
  role?: string
}

export interface UserGrantedResponse extends UserResponse {
  modules: Module[]
  permissions?: string[]
  roles?: string[]
  notifications?: Notification[]
}
