import './styles.css'

import { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CropImageModal from '../../../modals/CropImageModal'
import ShowImageModal from '../../../modals/ShowImageModal'
import FormControl, { FormFieldProps } from '../FormControl'

interface InputImageProps extends Omit<FormFieldProps, 'value' | 'onChange'> {
  id: number
  value: File
  valuePreview?: string
  editOnHover?: boolean
  onChange: (file: File | null) => void
  onChangePreview?: (file: string | null) => void
}

export const InputImage = ({
  name,
  label,
  required = false,
  disabled = false,
  value,
  valuePreview,
  columnSize = 6,
  editOnHover = true,
  onChange,
  onChangePreview,
  error,
  id
}: InputImageProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  const [open, setOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [event, setEvent] = useState<ChangeEvent<HTMLInputElement>>()

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    setEvent(e)
    setOpen(true)
    window.scrollTo(0, 0)
  }

  const hasImagePreview = !!valuePreview || !!value

  const showImagePath = () => {
    if (value) {
      return URL.createObjectURL(value)
    } else if (valuePreview) {
      return `${process.env.REACT_APP_LOGO_BASE_URL}${valuePreview}`
    } else {
      return require('../../../../assets/img/no_image.png')
    }
  }

  return (
    <>
      <div className={hasImagePreview ? 'image-container' : 'no-image-container'}>
        <a
          onClick={
            hasImagePreview
              ? () => {
                setPreviewOpen(true)
                window.scrollTo(0, 0)
              }
              : () => {}
          }
          aria-disabled={!hasImagePreview}
          rel="stylesheet"
        >
          {editOnHover && hasImagePreview && (
            <div className="edit-on-hover">
              <div className="show-image-button">
                <i className="fas fa-ellipsis-h m-auto" />
              </div>
            </div>
          )}
          {value ? (
            <img src={URL.createObjectURL(value)} width={98} />
          ) : valuePreview ? (
            <img src={`${process.env.REACT_APP_LOGO_BASE_URL}${valuePreview}`} width={98} />
          ) : (
            <img src={require('../../../../assets/img/no_image.png')} width={98} style={{ marginTop: 8 }} />
          )}
        </a>
      </div>
      <FormControl
        label={label}
        columnSize={columnSize}
        required={required}
        error={error}
        helpText={t('help_text.image', { size: '2MB', formats: 'jpg, jpeg, png, svg, gif' })}
      >
        <input type="file" className="form-control" accept="image/*" onChange={onSelectFile} disabled={disabled} ref={inputRef} />
      </FormControl>
      <CropImageModal
        isOpen={open}
        closeModal={() => {
          setOpen(false)
          // eslint-disable-next-line
          inputRef.current!.value = ''
          inputRef.current?.scrollIntoView({ behavior: 'instant' as ScrollBehavior })
        }}
        event={event!}
        onSave={onChange}
      />
      <ShowImageModal
        isOpen={previewOpen}
        closeModal={() => {
          setPreviewOpen(false)
          // eslint-disable-next-line
          inputRef.current?.scrollIntoView({ behavior: 'instant' as ScrollBehavior })
        }}
        id={id}
        fieldName={name as string}
        imagePath={showImagePath()}
        onSave={onChange}
        onChangePreview={onChangePreview}
      />
    </>
  )
}

export default InputImage
