import '../styles.css'

import { useTranslation } from 'react-i18next'

import { AttendanceAnnualReportCount, AttendanceReportResponse } from '../../../../../api/models/reports'

interface ByMonthsReportTableProps {
  attendanceReport: AttendanceReportResponse | null
}

export const ByMonthsReportTable = ({ attendanceReport }: ByMonthsReportTableProps) => {
  const { t } = useTranslation()

  if (!attendanceReport) {
    return null
  }

  return (
    <div className="overflow-auto">
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th style={{ width: 180 }}>{t('label.name')}</th>
            <th style={{ fontSize: 12, width: 18, textAlign: 'center' }}> {t('reports.attendance.total')}</th>

            {(attendanceReport.members?.[0].attendance as AttendanceAnnualReportCount[]).map((element) => (
              <th key={`${element.month}-${element.year}`} style={{ fontSize: 12, width: 18, textAlign: 'center' }}>
                <p className="month-day">{`${element.month}/${element.year.substring(2, 4)}`}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {attendanceReport.members?.map((member) => (
            <tr key={member.name}>
              <td>{`${member.name} ${member.lastname}`}</td>
              <td style={{ textAlign: 'center' }}>
                {(member.attendance as AttendanceAnnualReportCount[]).reduce(
                  (acc: number, attendance: AttendanceAnnualReportCount) => acc + attendance.total,
                  0
                )}
              </td>
              {(member.attendance as AttendanceAnnualReportCount[]).map((day) => {
                return (
                  <td
                    key={`${member.name}-${day.year}-${day.month}`}
                    style={{
                      fontSize: 12,
                      minWidth: 25,
                      width: 25,
                      maxWidth: 25,
                      textAlign: 'center',
                      background: day.total ? '#e9eaeb' : 'white',
                      padding: 2
                    }}
                  >
                    {day.total}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ByMonthsReportTable
