import FormControl, { FormFieldProps } from '../FormControl'

export const InputPassword = ({ name, label, required = false, value, columnSize = 6, onChange, error, placeholder }: FormFieldProps) => {
  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error}>
      <input
        type="password"
        className="form-control"
        value={typeof value !== 'object' ? value : undefined}
        name={name as string | undefined}
        onChange={onChange}
        placeholder={placeholder}
      />
    </FormControl>
  )
}

export default InputPassword
