import { ClubConfigResponse } from '../api/models/club'

export enum ClubConfigActionType {
  SET_CLUB_CONFIG = 'SET_CLUB_CONFIG'
}

export interface ClubConfigState extends ClubConfigResponse {}

interface ClubConfigAction {
  type: ClubConfigActionType
  payload?: ClubConfigState | any
}

const clubConfigReducer: (state: ClubConfigState, action: ClubConfigAction) => ClubConfigState = (state, action) => {
  switch (action.type) {
    case ClubConfigActionType.SET_CLUB_CONFIG:
      return { ...state, ...action.payload }
  }
}

export default clubConfigReducer
