import axios, { AxiosResponse } from 'axios'

import { AttendanceReportRequest, AttendanceReportResponse, MemberRegistrationRequest, MemberRegistrationResponse } from './models/reports'

const MEMBERS_REGISTRATION_URL = 'reports/members/registration'
const ATTENDANCE_REPORT_URL = 'reports/attendance/monthly'
const ATTENDANCE_ANNUAL_REPORT_URL = 'reports/attendance/annual'
const ATTENDANCE_SEASON_REPORT_URL = 'reports/attendance/season'

export const getAttendanceMonthlyReport: (_: AttendanceReportRequest) => Promise<AttendanceReportResponse> = ({ id }) => {
  return axios.get<AttendanceReportResponse>(`${ATTENDANCE_REPORT_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const getAttendanceAnnualReport: (_: AttendanceReportRequest) => Promise<AttendanceReportResponse> = ({ id }) => {
  return axios.get<AttendanceReportResponse>(`${ATTENDANCE_ANNUAL_REPORT_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const getAttendanceSeasonReport: (_: AttendanceReportRequest) => Promise<AttendanceReportResponse> = ({ id }) => {
  return axios.get<AttendanceReportResponse>(`${ATTENDANCE_SEASON_REPORT_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const getMembersRegistrationData: (_: MemberRegistrationRequest) => Promise<Array<MemberRegistrationResponse>> = ({ startDate, endDate }) => {
  return axios
    .get<Array<MemberRegistrationResponse>>(MEMBERS_REGISTRATION_URL, { params: { startDate, endDate } })
    .then((response: AxiosResponse) => response?.data)
}
