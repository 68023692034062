import { RouteObject } from 'react-router-dom'

import AttendanceReports from '../../pages/Reports/AttendanceReports'
import MemberRegistration from '../../pages/Reports/MemberRegistration'

export enum ReportsRouterPaths {
  ATTENDANCE_REPORT = ':clubCode/reports/attendance',
  MEMBER_REGISTRATION_REPORT = ':clubCode/reports/member-registration'
}

export const reportsRoutes: RouteObject[] = [
  {
    path: ReportsRouterPaths.ATTENDANCE_REPORT,
    Component: AttendanceReports
  },
  {
    path: ReportsRouterPaths.MEMBER_REGISTRATION_REPORT,
    Component: MemberRegistration
  }
]
