import { ChangeEvent, PropsWithChildren } from 'react'

import { Field } from '../../../../api/models/club'
import { isDevice } from '../../../../helpers/layout'

export interface FormFieldProps extends Omit<Field<any>, 'type'> {
  value?: string | File
  columnSize?: number
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  helpText?: string
  error?: string
  removeError?: () => void
  size?: 'small' | 'medium' | 'large'
}

interface FormControlsProps {
  label: string
  columnSize?: number
  required?: boolean
  error?: string
  helpText?: string
  labelClassName?: string
}

export const FormControl = ({
  columnSize,
  label,
  required = false,
  error,
  helpText,
  labelClassName,
  children
}: PropsWithChildren<FormControlsProps>) => {
  const marginBottomForDevices = isDevice ? 'mb-1' : ''
  return (
    <div className={`col-${columnSize} mb-2 d-flex flex-column justify-content-center`}>
      <label className={`form-label ${labelClassName}`}>
        {label}
        {required && '*'}
      </label>
      {children}
      <p className={`help-block ${marginBottomForDevices}`}>{helpText}</p>
      <p className={`error-block ${marginBottomForDevices}`}>{error}</p>
    </div>
  )
}

export default FormControl
