import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import * as XLSX from 'xlsx'

import { getMembers } from '../../../api/member'
import { MemberQueryRequest } from '../../../api/models/member'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import { mapFields } from '../../../helpers/export'
import { sectionConfig } from '../../../helpers/forms/member'

interface ExportMembersModalProps {
  isOpen: boolean
  closeModal: () => void
  filter?: MemberQueryRequest
}

export const ExportMembersModal = ({ isOpen, closeModal, filter }: ExportMembersModalProps) => {
  const { t } = useTranslation()
  const { clubCode } = useClubConfigContext()
  const [selectedFields, setSelectedFields] = useState<string[]>([])
  const [csvData, setCsvData] = useState<any[]>([])
  const [csvHeaders, setCsvHeaders] = useState<{ label: string; key: string }[]>([])

  const fileName = `${clubCode}_members`.replace(/[^a-z0-9]/gi, '_').toLowerCase()

  const fields = sectionConfig({})
    .map((field) => field.fields)
    .flat()

  const selectAll = () => {
    setSelectedFields(['number', ...fields.map((field) => (field.objectKey ? `${field.objectKey}.${field.name}` : (field.name as string))), 'groups'])
  }

  useEffect(() => {
    // TODO use totalmembers
    getMembers({ ...filter, paginate: 200, export: true }).then((response) => {
      setCsvData(response.data.map((member) => mapFields(member)))
    })
  }, [filter])

  useEffect(() => {
    const csvHeadersAll = [
      { label: t('members.number_member'), key: 'number' },
      ...fields.map((field) => ({
        label: field?.objectKey?.includes('tutor') ? t(`members.tutors.${field.name}`) : t(field.label),
        key: (field.objectKey ? `${field.objectKey}.${field.name}` : field.name) as string
      })),
      { label: t('groups.title'), key: 'groups' }
    ]

    const headers = csvHeadersAll.filter((header) => selectedFields.includes(header.key))

    setCsvHeaders(headers as any)
  }, [selectedFields])

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, [csvHeaders.map((header) => header.label)])

    const keysToKeep = csvHeaders.map((header) => header.key)

    const data = csvData.map((o) =>
      keysToKeep.reduce((acc: any, curr: string) => {
        acc[curr] = o[curr]
        return acc
      }, {})
    )

    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, t('members.title'))
    XLSX.writeFile(wb, `${fileName}.xlsx`)
  }

  return (
    <ReactModal
      isOpen={isOpen}
      // @ts-ignore
      setAppElement={'#app-body'}
      overlayClassName={'overlay'}
      className="modal-dialog modal-element modal-content modal-dialog-full"
    >
      <div className="modal-header align-items-center">
        <h3 className="modal-title">{t('modal.export_members_title')}</h3>
        <div className="ml-auto">
          <button className="btn-sm btn-primary" onClick={selectAll}>
            {t('buttons.select_all')}
          </button>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="modal-body p-4">
        <input
          type="checkbox"
          className="mr-1"
          checked={selectedFields.includes('number')}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedFields([...selectedFields, 'number'])
            } else {
              setSelectedFields(selectedFields.filter((field) => field !== 'number'))
            }
          }}
        />
        <label>{t('members.number_member')}</label>
        {sectionConfig({}).map((field, key) => (
          <div key={key}>
            <h5 key={field.title}>{t(field.title || '')}</h5>
            <div className="mb-2">
              {field.fields.map((subField) => (
                <div key={subField.name} className="mr-3 w-auto d-inline-block">
                  <input
                    type="checkbox"
                    className="mr-1"
                    checked={selectedFields.includes(subField.objectKey ? `${subField.objectKey}.${subField.name}` : (subField.name as string))}
                    onChange={(e) => {
                      const subFieldName = subField.objectKey ? `${subField.objectKey}.${subField.name}` : (subField.name as string)
                      if (e.target.checked) {
                        setSelectedFields([...selectedFields, subFieldName])
                      } else {
                        setSelectedFields(selectedFields.filter((field) => field !== subFieldName))
                      }
                    }}
                  />
                  <label className="font-weight-normal">{t(subField.label)}</label>
                </div>
              ))}
            </div>
          </div>
        ))}
        <input
          type="checkbox"
          className="mr-1"
          checked={selectedFields.includes('groups')}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedFields([...selectedFields, 'groups'])
            } else {
              setSelectedFields(selectedFields.filter((field) => field !== 'groups'))
            }
          }}
        />
        <label>{t('groups.title')}</label>
      </div>
      <div className="modal-footer">
        <CSVLink filename={`${fileName}.csv`} data={csvData} headers={csvHeaders} className="btn btn-primary">
          <i className="fa fa-file-csv mr-2"></i>
          {t('CSV')}
        </CSVLink>
        <button className="btn btn-primary" onClick={exportToExcel}>
          <i className="fa fa-file-excel mr-2"></i>
          {t('Excel')}
        </button>
        <button className="btn btn-secondary" onClick={closeModal}>
          {t('app.cancel')}
        </button>
      </div>
    </ReactModal>
  )
}
