import axios, { AxiosResponse } from 'axios'

import { AttendanceLabelRequest, AttendanceLabelResponse, AttendanceRequest, AttendanceSheet } from './models/attendance'
import { SuccessResponse } from './models/common'

const ATTENDANCE_URL = 'attendance'
const ATTENDANCE_SHEET_URL = 'attendance-sheet'
const ATTENDANCE_SHEETS_URL = 'attendance-sheets'
const ATTENDANCE_LABELS_URL = 'attendance/labels'
const ATTENDANCE_LABEL_URL = 'attendance/label'

export const saveAttendance: (attendance: AttendanceRequest) => Promise<SuccessResponse<{}>> = (attendance) => {
  return axios.post(ATTENDANCE_URL, attendance).then((response: AxiosResponse) => response?.data)
}

export const getAttendanceSheet: (id: string) => Promise<AttendanceSheet> = (id) => {
  return axios
    .get<AttendanceSheet>(`${ATTENDANCE_SHEET_URL}/${id}`, { params: { date: new Date() } })
    .then((response: AxiosResponse) => response?.data)
}

export const getAllAttendanceSheets: (_: { date?: Date }) => Promise<Array<AttendanceSheet>> = ({ date = new Date() }) => {
  return axios.get<Array<AttendanceSheet>>(ATTENDANCE_SHEETS_URL, { params: { date } }).then((response: AxiosResponse) => response?.data)
}

export const createAttendanceSheet: (_: AttendanceSheet) => Promise<SuccessResponse<AttendanceSheet>> = (body) => {
  return axios.post(ATTENDANCE_SHEET_URL, body).then((response: AxiosResponse) => response?.data)
}

export const deleteAttendanceSheet: (_: string) => Promise<SuccessResponse<{}>> = (groupId) => {
  return axios.delete(`${ATTENDANCE_SHEET_URL}/${groupId}`).then((response: AxiosResponse) => response?.data)
}

export const getAttendanceLabels: () => Promise<Array<AttendanceLabelResponse>> = () => {
  return axios.get<Array<AttendanceLabelResponse>>(ATTENDANCE_LABELS_URL).then((response: AxiosResponse) => response?.data)
}

export const createAttendanceLabel: (_: AttendanceLabelRequest) => Promise<SuccessResponse<{}>> = (body) => {
  return axios.post(ATTENDANCE_LABEL_URL, body).then((response: AxiosResponse) => response?.data)
}

export const deleteAttendanceLabel: (_: number) => Promise<SuccessResponse<Array<AttendanceLabelResponse>>> = (id) => {
  return axios
    .delete<SuccessResponse<Array<AttendanceLabelResponse>>>(`${ATTENDANCE_LABEL_URL}/${id}`)
    .then((response: AxiosResponse) => response?.data)
}

export const updateAttendanceLabel: (_: AttendanceLabelRequest & { id: number }) => Promise<SuccessResponse<Array<AttendanceLabelResponse>>> = (
  body
) => {
  return axios
    .patch<SuccessResponse<Array<AttendanceLabelResponse>>>(`${ATTENDANCE_LABEL_URL}/${body.id}`, body)
    .then((response: AxiosResponse) => response?.data)
}
