import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { ParamResponse } from './models/params'

const COLORS_URL = 'colors'
const ORIGINS_URL = 'origins'
const SUSPENDED_REASONS_URL = 'suspended-reasons'
const MEMBER_TYPES_URL = 'member-types'
const PARAM_URL = 'param'

export const getColors: () => Promise<Array<ParamResponse>> = () => {
  return axios.get<Array<ParamResponse>>(COLORS_URL).then((response: AxiosResponse) => response?.data)
}

export const getOrigins: () => Promise<Array<ParamResponse>> = () => {
  return axios.get<Array<ParamResponse>>(ORIGINS_URL).then((response: AxiosResponse) => response?.data)
}

export const getOriginsByClubCode: () => Promise<Array<ParamResponse>> = () => {
  const clubCode = localStorage.getItem('clubCode')
  return axios.get<Array<ParamResponse>>(`${clubCode}/${ORIGINS_URL}`).then((response: AxiosResponse) => response?.data)
}

export const getSuspendedReasons: () => Promise<Array<ParamResponse>> = () => {
  return axios.get<Array<ParamResponse>>(SUSPENDED_REASONS_URL).then((response: AxiosResponse) => response?.data)
}

export const getMemberTypes: () => Promise<Array<ParamResponse>> = () => {
  return axios.get<Array<ParamResponse>>(MEMBER_TYPES_URL).then((response: AxiosResponse) => response?.data)
}

export const createParam: (body: Omit<ParamResponse, 'id' | 'code'>) => Promise<SuccessResponse<{}>> = (body) => {
  return axios.post<SuccessResponse<{}>>(PARAM_URL, body).then((response: AxiosResponse) => response?.data)
}

export const editParam: (body: Omit<ParamResponse, 'code'>) => Promise<SuccessResponse<Array<ParamResponse>>> = (body) => {
  return axios.patch<SuccessResponse<Array<ParamResponse>>>(`${PARAM_URL}/${body.id}`, body).then((response: AxiosResponse) => response?.data)
}

export const deleteParam: (id: string | number) => Promise<SuccessResponse<Array<ParamResponse>>> = (id) => {
  return axios.delete<SuccessResponse<Array<ParamResponse>>>(`${PARAM_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}
