import '../../App.css'

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ZodError } from 'zod'

import { postMemberPreinscription } from '../../api/member'
import { DocumentationType, FormErrors } from '../../api/models/common'
import { MemberErrors, RecaptchaError } from '../../api/models/error'
import { MemberResponse, Status } from '../../api/models/member'
import CustomToast from '../../components/common/CustomToast'
import PreinscriptionForm from '../../components/forms/PreinscriptionForm'
import LanguageSelector from '../../components/Preinscription/LanguageSelector'
import PreinscriptionHeader from '../../components/Preinscription/PreinscriptionHeader'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'
import { standarizeLanguage } from '../../helpers/common'
import { SCREEN_SIZE } from '../../helpers/layout'
import { memberSchema } from '../../helpers/validations/preregistered'
import { handleValidationErrors } from '../../helpers/validations/validationErrors'
import { setLanguage } from '../../translations'
import ClubConfigWrapper from '../../wrappers/ClubConfigWrapper'

export const Preinscription = () => {
  const { t } = useTranslation()
  const { clubCode, basicInfo } = useClubConfigContext()

  const lang = useParams<{ lang: string }>()?.lang

  useEffect(() => {
    if (lang) {
      setLanguage(standarizeLanguage(lang))
    }
  }, [lang])

  const initialMember = {
    lang: lang ? standarizeLanguage(lang) : undefined,
    documentType: DocumentationType.DNI,
    status: Status.PENDING,
    nationality: basicInfo.country,
    country: basicInfo.country
  }

  const [member, setMember] = useState<Partial<MemberResponse>>(initialMember)
  const [errors, setErrors] = useState<FormErrors<MemberResponse>>({})

  useEffect(() => {
    setMember((prev) => ({ ...prev, nationality: basicInfo.country, country: basicInfo.country }))
  }, [basicInfo])

  useEffect(() => {
    if (member.nationality !== basicInfo.country) {
      setMember((prev) => ({ ...prev, documentType: DocumentationType.NIE }))
    }
  }, [member?.nationality])

  useEffect(() => {
    if (member.birthdate && member.birthdate.getFullYear() + 18 < new Date().getFullYear()) {
      setErrors((prev) => ({
        ...prev,
        'tutor.name': undefined,
        'tutor.lastname': undefined,
        'tutor.documentType': undefined,
        'tutor.documentValue': undefined,
        'tutor.email': undefined,
        'tutor.phone': undefined
      }))
    }
  }, [member.birthdate])

  const handleSubmit = (recaptchaToken: string) => {
    try {
      const validMember = memberSchema.parse(member)
      postMemberPreinscription({ clubCode, recaptchaToken, member: validMember as MemberResponse })
        .then(() => {
          toast.custom(<CustomToast text={t('notifications.created')} />)
          setMember(initialMember)
        })
        .catch((e) => {
          console.log(e)
          const error = e?.response?.data

          if (error.errors) {
            setErrors(error.errors)
          }

          switch (error?.code) {
            case RecaptchaError.REQUIRED:
              toast.custom(<CustomToast type="danger" text={t('notifications.recaptcha_error')} />)
              break
            case RecaptchaError.INVALID:
              toast.custom(<CustomToast type="danger" text={`Error: ${error.code} :: ${t('notifications.preinscription_error')}`} />)
              break
            case MemberErrors.UNIQUE:
              toast.custom(<CustomToast type="danger" text={t('notifications.member.unique')} />)
              break
          }
        })
    } catch (error) {
      toast.custom(<CustomToast type="danger" text={t('notifications.form_with_errors')} />)
      const issues = (error as ZodError).issues
      console.log(issues)

      setErrors(handleValidationErrors(issues))
      window.scrollTo(0, 0)
    }
  }

  return (
    <ClubConfigWrapper>
      <section className="content m-4 d-flex flex-column">
        <LanguageSelector />
        <div className="card">
          <div className="card-body row">
            <div className={`${window.innerWidth > SCREEN_SIZE.md ? 'col-4 pt-5' : 'col-12 pt-2'} text-center d-flex justify-content-center`}>
              <PreinscriptionHeader title={t('members.preinscription_title')} />
            </div>
            <div className={window.innerWidth > SCREEN_SIZE.md ? 'col-8' : 'col-12'}>
              <PreinscriptionForm member={member} setMember={setMember} errors={errors} handleSubmit={handleSubmit} setErrors={setErrors} />
            </div>
          </div>
        </div>
      </section>
    </ClubConfigWrapper>
  )
}

export default Preinscription
