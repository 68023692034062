import { DEFAULT_TERTIARY_COLOR } from '../../../theme/colors'

const colors =
  localStorage.getItem('colors') && localStorage.getItem('colors') !== 'undefined' ? JSON.parse(localStorage.getItem('colors') || '') : {}

export const styles = {
  components: {
    UploadStep: {
      baseStyle: {
        heading: {
          mb: '0.5rem'
        },
        title: {
          mb: '0.5rem'
        },
        subtitle: {
          mb: '1.5rem'
        },
        tableWrapper: {
          h: '100px'
        },
        dropZoneBorder: colors?.tertiary || DEFAULT_TERTIARY_COLOR,
        dropzoneButton: {
          bg: colors?.tertiary || DEFAULT_TERTIARY_COLOR,
          color: 'white'
        }
      }
    },
    MatchColumnsStep: {
      baseStyle: {
        userTable: {
          header: {
            padding: 0,
            mb: 0
          },
          cell: {
            padding: 2,
            mb: 2
          }
        }
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          fontSize: 'md'
        },
        backButton: {
          bg: colors?.tertiary || DEFAULT_TERTIARY_COLOR,
          color: 'white',
          _hover: {
            bg: colors?.tertiary || DEFAULT_TERTIARY_COLOR
          }
        },
        continueButton: {
          bg: colors?.tertiary || DEFAULT_TERTIARY_COLOR,
          color: 'white',
          _hover: {
            bg: colors?.tertiary || DEFAULT_TERTIARY_COLOR
          }
        }
      }
    },
    Button: {
      defaultProps: {
        colorScheme: 'rsi',
        bg: colors?.tertiary || DEFAULT_TERTIARY_COLOR
      }
    }
  }
}

export default styles
