import { FormFieldProps } from '../../../../forms/components/FormControl'
import Input from '../../../../forms/components/Input'

export interface IntegrationInputProps extends FormFieldProps {
  editAvailable?: boolean
}

export const IntegrationInput = ({ value, label, onChange, editAvailable }: IntegrationInputProps) => {
  return <Input label={label} value={value} onChange={onChange} columnSize={12} size="small" disabled={!editAvailable} />
}

export default IntegrationInput
