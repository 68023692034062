// @ts-nocheck
import './styles.css'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { contact } from '../../../api/contact'
import { ContactRequest } from '../../../api/models/contact'
import Input from '../../forms/components/Input'
import Textarea from '../../forms/components/Textarea'

export type ModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const ContactModal = ({ isOpen, closeModal }: ModalProps) => {
  const { t } = useTranslation()

  const [contactRequest, setContactRequest] = useState<Partial<ContactRequest>>()
  const [errors, setErrors] = useState<Partial<ContactRequest>>()

  const sendForm = () => {
    contact(contactRequest as ContactRequest)
      .then((response) => {
        console.log(response)
        // todo response success show ok.
      })
      .catch((e) => {
        const error = e?.response?.data
        if (error.errors) {
          setErrors(error.errors)
        }
      })
  }

  Modal.setAppElement(document.getElementById('app-body'))

  Modal.defaultStyles.overlay = {
    zIndex: 1000
  }

  return (
    <Modal isOpen={isOpen} setAppElement={'#app-body'} overlayClassName={'overlay'} className="modal-dialog modal-element modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{t('menu.contact')}</h5>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <Input
              label={t('contact.reason')}
              required={true}
              columnSize={12}
              value={contactRequest?.reason}
              onChange={(e) => setContactRequest((prev) => ({ ...prev, reason: e.target.value }))}
              error={errors?.reason}
            />
            <Input
              label={t('label.email')}
              required={true}
              columnSize={12}
              value={contactRequest?.email}
              onChange={(e) => setContactRequest((prev) => ({ ...prev, email: e.target.value }))}
              error={errors?.email}
            />
            <Textarea
              label={t('label.observations')}
              required={true}
              columnSize={12}
              value={contactRequest?.observations}
              onChange={(e) => setContactRequest((prev) => ({ ...prev, observations: e.target.value }))}
              error={errors?.observations}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={closeModal} className="close-modal btn btn-default" data-bs-dismiss="modal">
            {t('app.close')}
          </button>
          <button onClick={sendForm} className="btn btn-primary">
            {t('app.send')}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default ContactModal
