import axios, { AxiosResponse } from 'axios'

import { PaginatedData, SuccessResponse, SuccessWithErrorsResponse } from './models/common'
import { PrivacyConfigRequest } from './models/formsConfig'
import {
  ImportMembersDataResponse,
  MemberAcceptanceRequest,
  MemberDocument,
  MemberPreinscriptionRequest,
  MemberQueryRequest,
  MemberResponse
} from './models/member'

const MEMBERS_URL = 'members'
const MEMBER_URL = 'member'
const MEMBERS_IMPORT_URL = 'members/import'
const ALL_MEMBERS_URL = 'members/all'
const MEMBER_PREINSCRIPTION = (clubCode: string) => `${clubCode}/member/preinscription`
const MEMBER_PRIVACY_URL = 'member-privacy'
const MEMBER_PRIVACY_NOTFY_URL = 'member-privacy/notify'
const MEMBER_DOCUMENTS_URL = (memberId: number) => `member/${memberId}/documents`

export const getMembers: (_?: MemberQueryRequest) => Promise<PaginatedData<MemberResponse>> = (params) => {
  return axios.get<PaginatedData<MemberResponse>>(MEMBERS_URL, { params }).then((response: AxiosResponse) => response?.data)
}

export const getAllMembers: () => Promise<Array<MemberResponse>> = () => {
  return axios.get<Array<MemberResponse>>(ALL_MEMBERS_URL).then((response: AxiosResponse) => response?.data)
}

export const getMember: (id: string) => Promise<MemberResponse> = (id) => {
  return axios.get<MemberResponse>(`${MEMBER_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const createMember: (member: MemberResponse) => Promise<SuccessResponse<{ id: number }>> = (member) => {
  return axios.post<MemberResponse>(MEMBER_URL, member).then((response: AxiosResponse) => response?.data)
}

export const importMemberList: (members: Array<MemberResponse>) => Promise<SuccessWithErrorsResponse<ImportMembersDataResponse>> = (members) => {
  return axios
    .post<SuccessWithErrorsResponse<ImportMembersDataResponse>>(MEMBERS_IMPORT_URL, { members })
    .then((response: AxiosResponse) => response?.data)
}

export const updateMember: (member: MemberResponse) => Promise<SuccessResponse<MemberResponse>> = (member) => {
  return axios.patch<MemberResponse>(`${MEMBER_URL}/${member.id}`, member).then((response: AxiosResponse) => response?.data)
}

export const deleteMember: (id: string) => Promise<SuccessResponse<{}>> = (id) => {
  return axios.delete(`${MEMBER_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const postMemberPreinscription: (_: MemberPreinscriptionRequest) => Promise<SuccessResponse<{}>> = ({ member, clubCode, recaptchaToken }) => {
  return axios
    .post<MemberResponse & { recaptchaToken: string }>(MEMBER_PREINSCRIPTION(clubCode), { ...member, recaptchaToken })
    .then((response: AxiosResponse) => response?.data)
}

export const getMemberPrivacy: (_: PrivacyConfigRequest) => Promise<MemberResponse> = ({ token }) => {
  return axios
    .get<MemberResponse>(MEMBER_PRIVACY_URL, { headers: { authorization: `Bearer ${token}` } })
    .then((response: AxiosResponse) => response?.data)
}

export const saveMemberPrivacy: (_: MemberAcceptanceRequest) => Promise<SuccessResponse<{}>> = ({ acceptances, token }) => {
  return axios
    .post<MemberResponse>(MEMBER_PRIVACY_URL, { acceptances }, { headers: { authorization: `Bearer ${token}` } })
    .then((response: AxiosResponse) => response?.data)
}

export const notifyMemberPrivacy: (memberId: number) => Promise<SuccessResponse<{}>> = (memberId) => {
  return axios.post<MemberResponse>(MEMBER_PRIVACY_NOTFY_URL, { memberId }).then((response: AxiosResponse) => response?.data)
}

export const getMemberDocuments: (memberId: number) => Promise<Array<MemberDocument>> = (memberId) => {
  return axios.get<Array<MemberDocument>>(MEMBER_DOCUMENTS_URL(memberId)).then((response: AxiosResponse) => response?.data)
}
