export enum ParamType {
  COLOR = 'color',
  KNOWN_OPTIONS = 'known',
  ORIGIN = 'origin',
  SUSPENDED_REASON = 'suspended_reason',
  MEMBER_TYPE = 'member_type'
}

export interface ParamResponse {
  id: number
  type: ParamType
  code: string
  value: string
}
