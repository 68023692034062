import { PropsWithChildren } from 'react'

import ClubConfigWrapper from '../../../wrappers/ClubConfigWrapper'
import PrivateRouteWrapper from '../../../wrappers/PrivateRouteWrapper'
import MainSidebar from '../../menu/MainSidebar'
import NavBar from '../../menu/NavBar'
import Footer from '../Footer'

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ClubConfigWrapper>
      <PrivateRouteWrapper>
        <div className="wrapper">
          <NavBar />
          <MainSidebar />
          <div className="content-wrapper">{children}</div>
          <Footer />

          {/*
<!--Modals-->
@include("Modals/removeModal")
  */}
        </div>
      </PrivateRouteWrapper>
    </ClubConfigWrapper>
  )
}

export default Layout
