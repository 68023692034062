import './styles.css'

import { FormFieldProps } from '../FormControl'

export interface SwitchCheckboxProps extends Omit<FormFieldProps, 'value'> {
  value?: boolean
}

export const SwitchCheckbox = ({ name, label, required = false, disabled = false, value, columnSize = 6, onChange, size }: SwitchCheckboxProps) => {
  return (
    <div className={`col-${columnSize} mb-2`}>
      <div className={`custom-control custom-switch ${size}`}>
        <input
          type="checkbox"
          checked={value}
          className="custom-control-input checkbox-custom"
          name={name as string}
          onChange={onChange}
          disabled={disabled}
        />
        <label className="custom-control-label">
          {label}
          {required && '*'}
        </label>
      </div>
    </div>
  )
}

export default SwitchCheckbox
