import useAuthContext from '../contexts/hooks/useAuthContext'

export const useCleanContexts = () => {
  const { cleanAuth } = useAuthContext()

  return () => {
    cleanAuth()
  }
}

export default useCleanContexts
