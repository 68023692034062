import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { ZodError } from 'zod'

import { transferFencer } from '../../api/integration'
import { createMember, getMember, updateMember } from '../../api/member'
import { DocumentationType, FormErrors } from '../../api/models/common'
import { MemberErrors } from '../../api/models/error'
import { FileType } from '../../api/models/files'
import { MemberResponse, PREREGISTERED_STATUS, Status, Tutor } from '../../api/models/member'
import { PermissionGroup } from '../../api/models/role'
import { uploadImage } from '../../api/uploadFiles'
import DeleteMemberButton from '../../components/buttons/DeleteMemberButton'
import CustomToast from '../../components/common/CustomToast'
import Layout from '../../components/common/Layout'
import { MemberForm } from '../../components/forms/MemberForm'
import useAuthContext from '../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'
import useUIContext from '../../contexts/hooks/useUIContext'
import { memberSchema } from '../../helpers/validations/member'
import { handleValidationErrors } from '../../helpers/validations/validationErrors'

export const CreateMember = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { permissions, showFederationLink } = useAuthContext()
  const { clubCode, basicInfo } = useClubConfigContext()
  const { showErrorModal } = useUIContext()

  const urlParams = useParams()
  const memberId = urlParams?.id || false

  const [member, setMember] = useState<Partial<MemberResponse & { tutor2?: Tutor }>>({
    documentType: DocumentationType.DNI,
    status: Status.ACTIVE
  })
  const [errors, setErrors] = useState<FormErrors<MemberResponse>>({})
  const [loading, setLoading] = useState(false)

  const redirectUrl = PREREGISTERED_STATUS.includes(member.status!) ? `/${clubCode}/members/preregistered` : `/${clubCode}/members`

  const handleSubmit = async () => {
    try {
      const validMember = await memberSchema.parseAsync(member)

      const action = memberId ? updateMember : createMember

      action(validMember as MemberResponse)
        .then((response) => {
          if (response.success) {
            if (member.photo) {
              const formData = new FormData()
              formData.append('file', member.photo)
              formData.append('clubCode', clubCode!)
              formData.append('id', response?.data?.id?.toString() || '')
              formData.append('type', FileType.MEMBER)

              uploadImage(formData).then((res) => {
                toast.custom(<CustomToast text={t(`notifications.${memberId ? 'updated' : 'created'}`)} />)
                navigate(redirectUrl)
              })
            } else {
              toast.custom(<CustomToast text={t(`notifications.${memberId ? 'updated' : 'created'}`)} />)
              navigate(redirectUrl)
            }
          }
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error.errors) {
            setErrors(error.errors)
          }
          switch (error?.code) {
            case MemberErrors.UNIQUE:
              toast.custom(<CustomToast type="danger" text={t('notifications.member.unique')} />)
              break
            case MemberErrors.NUMBER_UNIQUE:
              toast.custom(<CustomToast type="danger" text={t('notifications.member.number_unique')} />)
              break
          }
        })
    } catch (error) {
      toast.custom(<CustomToast type="danger" text={t('notifications.form_with_errors')} />)
      const issues = (error as ZodError).issues
      console.log('error', error)
      console.log(issues)
      setErrors(handleValidationErrors(issues))
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    if (memberId) {
      setLoading(true)
      getMember(memberId).then((response) => {
        setMember(response)
        setLoading(false)
      })
    }
  }, [memberId])

  useEffect(() => {
    if (member.status === Status.SUSPENDED && !member.suspendedAt) {
      setMember((prev) => ({ ...prev, suspendedAt: new Date() }))
    }
    if (member.status !== Status.SUSPENDED) {
      setMember((prev) => ({ ...prev, suspendedAt: undefined, suspendedReason: undefined }))
    }
  }, [member.status])

  useEffect(() => {
    if (!member.nationality && !member.country) {
      setMember((prev) => ({ ...prev, nationality: basicInfo.country, country: basicInfo.country }))
    }
  }, [basicInfo])

  const onClickTransfer = () => {
    transferFencer(memberId?.toString()!)
      .then((response) => {
        if (response.success) {
          return toast.custom(<CustomToast text={t('notifications.member.transferred', { federation: basicInfo.federationId })} />)
        }
        if (response.error) {
          return toast.custom(<CustomToast type="danger" text={response.error} />)
        }
      })
      .catch((e) => {
        const error = e?.response?.data.error
        if (error) {
          const errorData = Object.keys(e.response.data.data).reduce((acc: any, curr: any) => [...acc, ...e.response.data.data[curr]], [])
          showErrorModal(
            <div>
              <p>{t(`notifications.${error}`)}</p>
              <ul className="list-unstyled">
                {errorData.map((err, key) => (
                  <li key={key}>{err}</li>
                ))}
              </ul>
            </div>
          )
        }
      })
  }

  return (
    <Layout>
      <section className="content">
        <div className="col-12 d-flex justify-content-between align-items-center pt-3">
          <h1 className="pt-3">{memberId ? t('members.edit_member') : t('members.create_member')}</h1>
          <div className="d-flex align-items-center flex-row-reverse">
            <DeleteMemberButton id={member?.id} />
            {permissions?.includes(`${PermissionGroup.MEMBER}.edit`) && memberId && showFederationLink && (
              <button onClick={onClickTransfer} className="btn btn-primary">
                <span className="fa fa-exchange mr-1"></span>
                {basicInfo?.federationId
                  ? `${t(member.federationId ? 'members.update_in' : 'members.transfer_to')} ${basicInfo.federationId}`
                  : t('members.transfer')}
              </button>
            )}
          </div>
        </div>
        <MemberForm member={member} errors={errors} setMember={setMember} handleSubmit={handleSubmit} loading={loading} setErrors={setErrors} />
      </section>
    </Layout>
  )
}

export default CreateMember
