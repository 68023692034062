import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { PaginatedData } from '../../../api/models/common'
import { MemberQueryRequest } from '../../../api/models/member'

export const Pagination = (props: PaginatedData<{}> & { setPagination: (_: PaginatedData<any>) => void; filter?: MemberQueryRequest }) => {
  const { total, from, to, currentPage, links = [], setPagination, filter } = props
  const { t } = useTranslation()

  const onClick = (url: string | null) => () => {
    axios.get(url!, filter ? { params: filter } : {}).then((response) => {
      setPagination(response.data)
    })
  }

  return (
    <div className="row pt-3">
      <div className="col-sm-12 col-md-5">
        <div className="dataTables_info">{t('pagination.showing', { from, to, total })}</div>
      </div>
      <div className="col-sm-12 col-md-7">
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            {links.map((link, index, links) => (
              <li
                key={index}
                className={`paginate_button page-item ${currentPage.toString() === link.label && 'active'} ${link.url === null && `disabled`}`}
              >
                <button className="page-link" onClick={onClick(link.url)}>
                  {index === 0 && (
                    <>
                      <span className="fas fa-chevron-left mr-2"></span>
                      {t('pagination.previous')}
                    </>
                  )}
                  {index === links.length - 1 && (
                    <>
                      {t('pagination.next')}
                      <span className="fas fa-chevron-right ml-2"></span>
                    </>
                  )}
                  {index !== 0 && index !== links.length - 1 && link.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Pagination
