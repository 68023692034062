import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { getNextMember } from '../../../api/memberConfig'
import { Option } from '../../../api/models/club'
import { FieldType, FormErrors } from '../../../api/models/common'
import { MemberResponse } from '../../../api/models/member'
import { ParamResponse } from '../../../api/models/params'
import { PermissionGroup } from '../../../api/models/role'
import { getMemberTypes, getOrigins, getSuspendedReasons } from '../../../api/params'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import { sectionConfig } from '../../../helpers/forms/member'
import FormLoader from '../../loaders/FormLoader'
import MemberAttendance from '../../Member/MemberAttendance'
import MemberDocumentation from '../../Member/MemberDocumentation'
import MemberGroupsList from '../../Member/MemberGroupsList'
import FormSection from '../components/FormSection'

export interface MemberFormProps {
  member: Partial<MemberResponse>
  setMember: Dispatch<SetStateAction<Partial<MemberResponse>>>
  errors: FormErrors<MemberResponse>
  setErrors?: Dispatch<SetStateAction<FormErrors<MemberResponse>>>
  handleSubmit: () => void
  loading?: boolean
}

export const MemberForm = ({ member, setMember, errors, handleSubmit, loading, setErrors }: MemberFormProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { showBankAccount } = useClubConfigContext()
  const { permissions } = useAuthContext()

  const [originOptions, setOriginOptions] = useState<Array<Option>>([])
  const [suspendedReasonOptions, setSuspendedReasonOptions] = useState<Array<Option>>([])
  const [memberTypeOptions, setMemberTypeOptions] = useState<Array<Option>>([])

  useEffect(() => {
    if (location.pathname.includes('create')) {
      getNextMember().then((response) => {
        setMember((prev) => ({ ...prev, ...response }))
      })
    }
    getOrigins().then((response: ParamResponse[]) => {
      if (response) {
        setOriginOptions(response.map((origin) => ({ value: origin.id.toString(), label: origin.value })))
      }
    })
    getSuspendedReasons().then((response: ParamResponse[]) => {
      if (response) {
        setSuspendedReasonOptions(response.map((reason) => ({ value: reason.id.toString(), label: reason.value })))
      }
    })
    getMemberTypes().then((response: ParamResponse[]) => {
      if (response) {
        setMemberTypeOptions(response.map((type) => ({ value: type.id.toString(), label: type.value })))
      }
    })
  }, [])

  return (
    <div className="container-fluid mt-3">
      {loading ? (
        <FormLoader
          width={'100%'}
          style={{ background: 'white', padding: '1.25rem', borderRadius: 5, boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)' }}
        />
      ) : (
        sectionConfig({
          originOptions,
          suspendedReasonOptions,
          memberTypeOptions,
          showBankAccount,
          status: member.status,
          age: member.age,
          disabled: !permissions?.includes(`${PermissionGroup.MEMBER}.edit`) || !permissions?.includes(`${PermissionGroup.MEMBER}.create`)
        }).map((section, key) => (
          <FormSection
            key={section.title}
            name={section.title}
            firstElement={key === 0}
            startCollapsed={section.startCollapsed}
            title={section?.title ? t(section.title) : undefined}
            fields={section.fields}
            formObject={member}
            setFormObject={setMember}
            errors={errors}
            setErrors={setErrors}
            conditionalHide={section.conditionalHide}
          />
        ))
      )}
      <FormSection
        key={'acceptance'}
        name={'members.authorization_info'}
        startCollapsed
        title={t('members.authorization_info')}
        fields={Object.keys(member?.acceptances! || {}).map((acceptance) => ({
          type: FieldType.CHECKBOX,
          disabled: true,
          name: acceptance,
          label: `accept_privacy.${acceptance}`,
          objectKey: 'acceptances'
        }))}
        formObject={member}
        setFormObject={setMember}
        errors={errors}
        setErrors={setErrors}
      />

      <MemberDocumentation memberId={member.id || 0} />
      {member.id && <MemberAttendance memberId={member.id || 0} />}
      <MemberGroupsList groups={member.groups || []} setMember={setMember} />

      {(permissions?.includes(`${PermissionGroup.MEMBER}.edit`) || permissions?.includes(`${PermissionGroup.MEMBER}.create`)) && (
        <div className="col-12 mt-2 card-body">
          <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit}>
            {t('app.save')}
          </button>
        </div>
      )}
    </div>
  )
}

export default MemberForm
