import { useLocation, useParams } from 'react-router-dom'

import { NO_CLUB_CODE } from '../contexts/ClubConfigContext'

export const useClubCode = () => {
  const url = useLocation()
  const isMaster = url.pathname.includes(NO_CLUB_CODE)
  const clubCode = isMaster ? NO_CLUB_CODE : useParams<{ clubCode: string }>()?.clubCode

  if (clubCode) {
    localStorage.setItem('clubCode', clubCode)
  }

  return clubCode
}
