import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { deleteMember } from '../../../api/member'
import { PermissionGroup } from '../../../api/models/role'
import CustomToast from '../../../components/common/CustomToast'
import DeleteModal from '../../../components/modals/DeleteModal'
import useAuthContext from '../../../contexts/hooks/useAuthContext'

interface DeleteMemberButtonProps {
  id?: string | number
  size?: 'xs' | 'lg'
}

export const DeleteMemberButton = ({ id, size }: DeleteMemberButtonProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { permissions } = useAuthContext()

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const onRemove = (memberId: string) => {
    deleteMember(memberId as string).then((response) => {
      if (response.success) {
        toast.custom(<CustomToast text={t('notifications.member.removed')} />)
        setDeleteModalOpen(false)
        navigate(-1)
      }
    })
  }

  if (!id) return null

  return (
    <>
      {permissions?.includes(`${PermissionGroup.MEMBER}.delete`) && (
        <button onClick={() => setDeleteModalOpen(true)} className={`btn btn-danger ml-1 ${size && `btn-${size}`}`}>
          <i className={`fa fa-trash-alt mr-2`}></i>
          {t('app.remove')}
        </button>
      )}

      <DeleteModal isOpen={deleteModalOpen} closeModal={() => setDeleteModalOpen(false)} onRemove={() => onRemove(id?.toString()!)} />
    </>
  )
}

export default DeleteMemberButton
