import chroma from 'chroma-js'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { components, OptionProps, SingleValueProps } from 'react-select'

import { Option } from '../../../../api/models/club'
import { InfoRequestStatus } from '../../../../api/models/contact'
import { InfoRequestFormType } from '../../../../types'
import { statusBackgroundColor, statusColor } from '../../../../utils/infoRequest'
import FormControl from '../../components/FormControl'
import { statusCustomStyles } from './styles'

export type ColouredOption = Option & { color: string }

export interface StatusSelectProps {
  infoRequest: InfoRequestFormType
  setInfoRequest: Dispatch<SetStateAction<InfoRequestFormType>>
}

export const StatusSelect = ({ infoRequest, setInfoRequest }: StatusSelectProps) => {
  const { t } = useTranslation()

  const options = Object.values(InfoRequestStatus)
    .filter((status) => !isNaN(Number(status)))
    .map((status) => ({ value: status, label: t(`info_request.status.${status}`), color: statusColor[status as InfoRequestStatus] }))

  const Option = ({ children, ...props }: OptionProps<ColouredOption>) => {
    const color = chroma(props.data.color)

    return (
      <components.Option {...props}>
        <div className={'row w-100 p-2'} style={{ backgroundColor: props.isFocused ? color.alpha(0.3).css() : 'transparent' }}>
          <div className={`badge ${statusBackgroundColor[props.data.value as InfoRequestStatus]} mx-2 p-2`}>{children}</div>
        </div>
      </components.Option>
    )
  }

  const SingleValue = ({ children, ...props }: SingleValueProps<ColouredOption>) => (
    <components.SingleValue {...props}>
      <div className={`badge ${statusBackgroundColor[props.data.value as InfoRequestStatus]} p-2`}>{children}</div>
    </components.SingleValue>
  )
  return (
    <FormControl
      label={t('label.status')}
      columnSize={6}
      required={true}
      // error={error}
    >
      <ReactSelect
        menuPortalTarget={document.body}
        value={infoRequest.status ? options.find((option) => option.value === infoRequest.status) : null}
        options={options}
        components={{ Option, SingleValue }}
        styles={statusCustomStyles}
        onChange={(selected) => {
          // @ts-ignore
          setInfoRequest((prev) => ({ ...prev, status: selected?.value }))
        }}
      />
    </FormControl>
  )
}

export default StatusSelect
