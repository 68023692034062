import { useContext } from 'react'

import { ClubConfigContext } from '../ClubConfigContext'

export const useClubConfigContext = () => {
  const context = useContext(ClubConfigContext)

  return context
}

export default useClubConfigContext
