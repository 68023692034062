import { PermissionGroup } from '../api/models/role'
import ChangelogCard from '../components/cards/ChangelogCard'
import MemberRegistrationReportCard from '../components/cards/MemberRegistrationReportCard'
import Layout from '../components/common/Layout'
import TotalMembersCard from '../components/Dashboard/TotalMembersCard'
import useAuthContext from '../contexts/hooks/useAuthContext'

export const Dashboard = () => {
  const { isMasterUser, permissions } = useAuthContext()
  return (
    <Layout>
      <section className="content">
        <div className="container-fluid pt-5">
          <div className="row">{!isMasterUser && <TotalMembersCard />}</div>
          {permissions?.includes(`${PermissionGroup.REPORT}.show`) && (
            <div className="row">
              <MemberRegistrationReportCard />
            </div>
          )}
          <div className="row">
            <ChangelogCard />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Dashboard
