import { InfoRequestOrigin, InfoRequestStatus } from '../api/models/contact'

export const icon = {
  [InfoRequestOrigin.PHONE]: 'fa-phone',
  [InfoRequestOrigin.EMAIL]: 'fa-at',
  [InfoRequestOrigin.FORM]: 'fa-file-invoice',
  [InfoRequestOrigin.CHAT]: 'fa-comments',
  [InfoRequestOrigin.SITE]: 'fa-user'
}

export const statusBackgroundColor = {
  [InfoRequestStatus.OPENED]: 'bg-info',
  [InfoRequestStatus.ANSWERED]: 'bg-lightblue',
  [InfoRequestStatus.CLOSED]: 'bg-maroon',
  [InfoRequestStatus.PREINSCRIPTION]: 'bg-success'
}

export const statusColor = {
  [InfoRequestStatus.OPENED]: '#17a2b8',
  [InfoRequestStatus.ANSWERED]: '#3c8dbc',
  [InfoRequestStatus.CLOSED]: '#d81b60',
  [InfoRequestStatus.PREINSCRIPTION]: '#28a745'
}
