import { PropsWithChildren, useEffect } from 'react'

import { getClubConfig } from '../../api/club'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'
import { updateCssVariable } from '../../helpers/common'
import { useClubCode } from '../../hooks/useClubCode'
import {
  DEFAULT_BG_INFO_CUSTOM,
  DEFAULT_BG_UNREAD_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  DEFAULT_TERTIARY_COLOR
} from '../../theme/colors'

export const ClubConfigWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { setClubConfig } = useClubConfigContext()
  const clubCode = useClubCode()

  const colors =
    localStorage.getItem('colors') && localStorage.getItem('colors') !== 'undefined' ? JSON.parse(localStorage.getItem('colors') || '') : {}

  useEffect(() => {
    if (localStorage.getItem('colors')) {
      updateCssVariable('--var-primary-color', colors.primary || DEFAULT_PRIMARY_COLOR)
      updateCssVariable('--var-secondary-color', colors.secondary || DEFAULT_SECONDARY_COLOR)
      updateCssVariable('--var-tertiary-color', colors.tertiary || DEFAULT_TERTIARY_COLOR)
      updateCssVariable('--var-button-background-color', `${colors.tertiary}DD` || DEFAULT_TERTIARY_COLOR)
      updateCssVariable('--var-unread-background-color', `${colors.tertiary}12` || DEFAULT_BG_UNREAD_COLOR)
      updateCssVariable('--var-bg-info-custom', `${colors?.primary}BF` || DEFAULT_BG_INFO_CUSTOM)
    }
    if (!clubCode) {
      updateCssVariable('--var-primary-color', DEFAULT_PRIMARY_COLOR)
      updateCssVariable('--var-secondary-color', DEFAULT_SECONDARY_COLOR)
      updateCssVariable('--var-tertiary-color', DEFAULT_TERTIARY_COLOR)
      updateCssVariable('--var-button-background-color', DEFAULT_TERTIARY_COLOR)
      updateCssVariable('--var-unread-background-color', DEFAULT_BG_UNREAD_COLOR)
      return
    }

    getClubConfig({ clubCode })
      .then((config) => {
        setClubConfig(config)
        updateCssVariable('--var-primary-color', config?.colors?.primary || DEFAULT_PRIMARY_COLOR)
        updateCssVariable('--var-secondary-color', config?.colors?.secondary || DEFAULT_SECONDARY_COLOR)
        updateCssVariable('--var-tertiary-color', config?.colors?.tertiary || DEFAULT_TERTIARY_COLOR)
        updateCssVariable('--var-button-background-color', config?.colors?.tertiary ? `${config?.colors?.tertiary}DD` : DEFAULT_TERTIARY_COLOR)
        updateCssVariable('--var-unread-background-color', config?.colors?.tertiary ? `${config?.colors?.tertiary}12` : DEFAULT_BG_UNREAD_COLOR)
        updateCssVariable('--var-bg-info-custom', config?.colors?.primary ? `${config?.colors?.primary}BF` : DEFAULT_BG_INFO_CUSTOM)

        localStorage.setItem('colors', JSON.stringify(config?.colors))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [clubCode])

  return <>{children}</>
}

export default ClubConfigWrapper
