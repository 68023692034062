import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Field } from '../../../api/models/club'
import { FieldType, FormErrors } from '../../../api/models/common'
import { GroupResponse } from '../../../api/models/group'
import GroupMembersList from '../../Group/GroupMembersList'
import FormSection from '../components/FormSection'

export interface GroupFormProps {
  group: Partial<GroupResponse>
  setGroup: Dispatch<SetStateAction<Partial<GroupResponse>>>
  errors: FormErrors<GroupResponse>
  handleSubmit: () => void
  openModal: () => void
  temporalMembers?: number[]
}

const fields: Field<GroupResponse>[] = [
  {
    name: 'name',
    label: 'label.name',
    type: FieldType.TEXT,
    required: true
  },
  {
    name: 'description',
    label: 'label.description',
    type: FieldType.TEXT
  }
]

export const GroupForm = ({ group, setGroup, errors, handleSubmit, openModal }: GroupFormProps) => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid mt-3">
      <FormSection startCollapsed={false} fields={fields} formObject={group} setFormObject={setGroup} errors={errors} />
      <GroupMembersList members={group.members!} openModal={openModal} groupName={group.name} />
      <div className="col-12 mt-2 card-body">
        <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit}>
          {t('app.save')}
        </button>
      </div>
    </div>
  )
}

export default GroupForm
