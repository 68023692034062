import i18n from 'i18next'

import { Country, DocumentationType, Laterality, TutorType, Weapon } from '../api/models/common'
import { Gender, Status } from '../api/models/member'

const convertDiacritialHeaders = (header: string) => {
  return header
    .replace('á', '&aacute;')
    .replace('é', '&eacute')
    .replace('í', '&iacute;')
    .replace('ó', '&oacute;')
    .replace('ú', '&uacute;')
    .replace('ñ', '&ntilde;')
    .replace('Ñ', '&Ntilde;')
    .replace('ü', '&uuml;')
    .replace('Ü', '&Uuml;')
    .replace('ç', '&ccedil;')
    .replace('Ç', '&Ccedil;')
    .replace('à', '&agrave')
    .replace('è', '&egrave')
    .replace('ì', '&igrave')
    .replace('ò', '&ograve')
    .replace('ù', '&ugrave')
    .replace('À', '&Agrave')
    .replace('È', '&Egrave')
    .replace('Ì', '&Igrave')
    .replace('Ò', '&Ograve')
    .replace('Ù', '&Ugrave')
}

const fields = [
  {
    label: i18n.t('members.number_member'),
    key: 'number',
    alternateMatches: [
      'Member number',
      'nº de socio',
      i18n.t('members.number_member'),
      convertDiacritialHeaders(i18n.t('members.number_member')),
      i18n.t('members.number_member').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.number_member')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    }
  },
  {
    label: i18n.t('label.name'),
    key: 'name',
    alternateMatches: [
      'first name',
      'nombre',
      i18n.t('label.name'),
      convertDiacritialHeaders(i18n.t('label.name')),
      i18n.t('label.name').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.name')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'Juan',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.name').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.lastname'),
    key: 'lastname',
    alternateMatches: [
      'surname',
      'apellido',
      'apellidos',
      i18n.t('label.lastname'),
      convertDiacritialHeaders(i18n.t('label.lastname')),
      i18n.t('label.lastname').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.lastname')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'Pérez Martín',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.lastname').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.document_type'),
    key: 'documentType',
    alternateMatches: [
      i18n.t('label.document_type'),
      convertDiacritialHeaders(i18n.t('label.document_type')),
      i18n.t('label.document_type').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.document_type')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.values(DocumentationType).map((documentType) => ({
        value: documentType,
        label: i18n.t('register.' + documentType.toLowerCase())
      }))
    },
    example: 'Opciones: DNI, NIE, Pasaporte, SS'
  },
  {
    label: i18n.t('label.document'),
    key: 'documentValue',
    alternateMatches: [
      'DNI',
      i18n.t('label.document'),
      convertDiacritialHeaders(i18n.t('label.document')),
      i18n.t('label.document').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.document')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '05305436C',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.document').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.gender'),
    key: 'gender',
    alternateMatches: [
      i18n.t('label.gender'),
      convertDiacritialHeaders(i18n.t('label.gender')),
      i18n.t('label.gender').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.gender')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.values(Gender).map((gender) => ({ value: gender, label: `${gender} - ${i18n.t('members.' + gender)}` }))
    },
    example: `M = ${i18n.t('members.M')}, W = ${i18n.t('members.W')}`,
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.gender').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.email'),
    key: 'email',
    alternateMatches: [
      'Correo Electrónico',
      'Email',
      i18n.t('label.email'),
      convertDiacritialHeaders(i18n.t('label.email')),
      i18n.t('label.email').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.email')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'name@gmail.com',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.email').toUpperCase() }),
        level: 'error'
      }
    ]
  },

  {
    label: i18n.t('label.phone'),
    key: 'phone',
    alternateMatches: [
      i18n.t('label.phone'),
      convertDiacritialHeaders(i18n.t('label.phone')),
      i18n.t('label.phone').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.phone')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '678901234',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.phone').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.email_2'),
    key: 'email2',
    alternateMatches: [
      'Correo Electrónico 2',
      'Email 2',
      i18n.t('label.email_2'),
      convertDiacritialHeaders(i18n.t('label.email_2')),
      i18n.t('label.email_2').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.email_2')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'name@gmail.com'
  },

  {
    label: i18n.t('label.phone_2'),
    key: 'phone2',
    alternateMatches: [
      i18n.t('label.phone_2'),
      convertDiacritialHeaders(i18n.t('label.phone_2')),
      i18n.t('label.phone_2').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.phone_2')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '678901234'
  },
  {
    label: i18n.t('members.nationality'),
    key: 'nationality',
    alternateMatches: [
      i18n.t('members.nationality'),
      convertDiacritialHeaders(i18n.t('members.nationality')),
      i18n.t('members.nationality').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.nationality')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.keys(Country).map((country) => ({ value: country, label: `${country} - ${i18n.t('common.countries.' + country)}` }))
    },
    example: `Country ISO code with two letters: FR, ES, IT, ...`
  },
  {
    label: i18n.t('label.birthdate'),
    key: 'birthdate',
    alternateMatches: [i18n.t('label.birthdate'), convertDiacritialHeaders(i18n.t('label.birthdate')), i18n.t('label.birthdate').toUpperCase()],
    fieldType: {
      type: 'input'
    },
    example: 'Format: yyyy-mm-dd',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.birthdate').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.weapon'),
    key: 'weapon',
    alternateMatches: [
      i18n.t('label.weapon'),
      convertDiacritialHeaders(i18n.t('label.weapon')),
      i18n.t('label.weapon').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.weapon')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.keys(Weapon).map((weapon) => ({ value: weapon, label: `${weapon} - ${i18n.t('common.weapon.' + weapon)}` }))
    },
    example: `E = ${i18n.t('common.weapon.E')}, F = ${i18n.t('common.weapon.F')}, S = ${i18n.t('common.weapon.S')}`
  },
  {
    label: i18n.t('label.status'),
    key: 'status',
    alternateMatches: [
      i18n.t('label.status'),
      convertDiacritialHeaders(i18n.t('label.status')),
      i18n.t('label.status').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.status')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.values(Status).map((status) => ({ value: status, label: i18n.t('members.status.' + status) }))
    },
    example: `Status: pending, active, inactive, rejected`
  },
  {
    label: i18n.t('label.address'),
    key: 'address',
    alternateMatches: [
      i18n.t('label.address'),
      convertDiacritialHeaders(i18n.t('label.address')),
      i18n.t('label.address').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.address')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'C/ Mayor, 1',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.address').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.town'),
    key: 'town',
    alternateMatches: [
      'Ciudad',
      'Localidad',
      i18n.t('label.town'),
      convertDiacritialHeaders(i18n.t('label.town')),
      i18n.t('label.town').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.town')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'Madrid',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.town').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.postal_code'),
    key: 'postalCode',
    alternateMatches: [
      'CP',
      i18n.t('label.postal_code'),
      convertDiacritialHeaders(i18n.t('label.postal_code')),
      i18n.t('label.postal_code').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.postal_code')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '28001',
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.postal_code').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('label.country'),
    key: 'country',
    alternateMatches: [
      i18n.t('label.country'),
      convertDiacritialHeaders(i18n.t('label.country')),
      i18n.t('label.country').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.country')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.keys(Country).map((country) => ({ value: country, label: `${country} - ${i18n.t('common.countries.' + country)}` }))
    },
    example: `Country ISO code with two letters: FR, ES, IT, ...`,
    validations: [
      {
        rule: 'required',
        errorMessage: i18n.t('validation.required_field', { attribute: i18n.t('label.country').toUpperCase() }),
        level: 'error'
      }
    ]
  },
  {
    label: i18n.t('members.laterality'),
    key: 'laterality',
    alternateMatches: [
      i18n.t('members.laterality'),
      convertDiacritialHeaders(i18n.t('members.laterality')),
      i18n.t('members.laterality').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.laterality')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.keys(Laterality).map((laterality) => ({
        value: laterality,
        label: `${laterality} - ${i18n.t('common.laterality.' + laterality)}`
      }))
    }
  },
  {
    label: i18n.t('members.created_at'),
    key: 'createdAt',
    alternateMatches: [
      i18n.t('members.created_at'),
      convertDiacritialHeaders(i18n.t('members.created_at')),
      i18n.t('members.created_at').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.created_at')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '2020-03-02 (yyyy-mm-dd)'
  },
  {
    label: i18n.t('label.suspended_date'),
    key: 'suspendedAt',
    alternateMatches: [
      i18n.t('label.suspended_date'),
      convertDiacritialHeaders(i18n.t('label.suspended_date')),
      i18n.t('label.suspended_date').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.suspended_date')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '2020-03-02 (yyyy-mm-dd)'
  },
  {
    label: i18n.t('accept_privacy.bankAccount'),
    key: 'iban',
    alternateMatches: [
      i18n.t('members_iban'),
      convertDiacritialHeaders(i18n.t('accept_privacy.bankAccount')),
      i18n.t('accept_privacy.bankAccount').toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'ES00 XXXX XXXX XXXX 0000 0000'
  },
  {
    label: i18n.t('members.tutors.name'),
    key: 'tutorName',
    alternateMatches: [
      i18n.t('members.tutors.name'),
      convertDiacritialHeaders(i18n.t('members.tutors.name')),
      i18n.t('members.tutors.name').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.name')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'Juan'
  },
  {
    label: i18n.t('members.tutors.lastname'),
    key: 'tutorLastname',
    alternateMatches: [
      i18n.t('members.tutors.lastname'),
      convertDiacritialHeaders(i18n.t('members.tutors.lastname')),
      i18n.t('members.tutors.lastname').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.lastname')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'Pérez Martín'
  },
  {
    label: i18n.t('members.tutors.email'),
    key: 'tutorEmail',
    alternateMatches: [
      i18n.t('members.tutors.email'),
      convertDiacritialHeaders(i18n.t('members.tutors.email')),
      i18n.t('members.tutors.email').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.email')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: 'tutor@skermo.org'
  },
  {
    label: i18n.t('members.tutors.document_type'),
    key: 'tutorDocumentType',
    alternateMatches: [
      i18n.t('members.tutors.document_type'),
      convertDiacritialHeaders(i18n.t('members.tutors.document_type')),
      i18n.t('members.tutors.document_type').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.document_type')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.values(DocumentationType).map((documentType) => ({
        value: documentType,
        label: i18n.t('register.' + documentType.toLowerCase())
      }))
    },
    example: 'Opciones: DNI, NIE, Pasaporte, SS'
  },
  {
    label: i18n.t('members.tutors.document'),
    key: 'tutorDocumentValue',
    alternateMatches: [
      i18n.t('members.tutors.document'),
      convertDiacritialHeaders(i18n.t('members.tutors.document')),
      i18n.t('members.tutors.document').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.document')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '05305436C'
  },
  {
    label: i18n.t('members.tutors.phone'),
    key: 'tutorPhone',
    alternateMatches: [
      i18n.t('members.tutors.phone'),
      convertDiacritialHeaders(i18n.t('members.tutors.phone')),
      i18n.t('members.tutors.phone').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.phone')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    },
    example: '678901234'
  },
  {
    label: i18n.t('members.tutors.type'),
    key: 'tutorType',
    alternateMatches: [
      i18n.t('members.tutors.type'),
      convertDiacritialHeaders(i18n.t('members.tutors.type')),
      i18n.t('members.tutors.type').toUpperCase(),
      convertDiacritialHeaders(i18n.t('members.tutors.type')).toUpperCase()
    ],
    fieldType: {
      type: 'select',
      options: Object.values(TutorType).map((tutorType) => ({ value: tutorType, label: i18n.t('members.tutors_type.' + tutorType) }))
    }
  },
  {
    label: i18n.t('label.observations'),
    key: 'comments',
    alternateMatches: [
      i18n.t('label.observations'),
      convertDiacritialHeaders(i18n.t('label.observations')),
      i18n.t('label.observations').toUpperCase(),
      convertDiacritialHeaders(i18n.t('label.observations')).toUpperCase()
    ],
    fieldType: {
      type: 'input'
    }
  }
] as const

export default fields
