import './styles.css'

import { useEffect } from 'react'
import { MultiSelect as ReactMultiSelect } from 'react-multi-select-component'

import { Option } from '../../../../api/models/club'
import { updateCssVariable } from '../../../../helpers/common'
import translations from '../../../../translations/es_ES.json'
import FormControl, { FormFieldProps } from '../FormControl'

interface MultiSelectProps extends Omit<FormFieldProps, 'onChange' | 'value'> {
  value: Option[]
  onChange: (_: Option[]) => void
}

export const MultiSelect = ({
  label,
  options = [],
  required = false,
  disabled = false,
  value,
  columnSize = 3,
  onChange,
  size = 'small'
}: MultiSelectProps) => {
  useEffect(() => {
    switch (size) {
      case 'small':
        updateCssVariable('--rmsc-height', '31px')
        updateCssVariable('--rmsc-font-size', '0.875rem')
        break
      case 'medium':
        updateCssVariable('--rmsc-height', '38px')
        updateCssVariable('--rmsc-font-size', '1rem')
        break
      case 'large':
        updateCssVariable('--rmsc-height', '44px')
        updateCssVariable('--rmsc-font-size', '1.125rem')
        break
    }
  }, [size])

  return (
    <FormControl label={label} columnSize={columnSize} required={required} labelClassName={size === 'small' ? 'col-form-label-sm' : ''}>
      <ReactMultiSelect options={options} value={value} onChange={onChange} labelledBy="Select" overrideStrings={translations.forms.multiselect} />
    </FormControl>
  )
}

export default MultiSelect
