import ProfileInfo from '../ProfileInfo'
import SidebarMenu from '../SidebarMenu'

export const Sidebar = () => {
  return (
    <div className="sidebar">
      <ProfileInfo />
      <SidebarMenu />
    </div>
  )
}

export default Sidebar
