import './styles.css'

import ProfileInfoLoader from '../../../components/loaders/ProfileInfoLoader'
import useAuthContext from '../../../contexts/hooks/useAuthContext'

export const ProfileInfo = () => {
  const { user } = useAuthContext()

  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') || '') : user
  const clubCode = localStorage.getItem('clubCode')

  return (
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      {userInfo ? (
        <>
          <div className="image image-profile">
            <i className="name-profile fas fa-user-alt"></i>
          </div>
          <div className="info">
            <a href={`/${clubCode}/profile/edit`} className="d-block">
              {`${userInfo?.name} ${userInfo?.lastname}`}
            </a>
          </div>
        </>
      ) : (
        <ProfileInfoLoader />
      )}
    </div>
  )
}

export default ProfileInfo
