import { useTranslation } from 'react-i18next'

import { GroupResponse } from '../../../api/models/group'
import { PermissionGroup } from '../../../api/models/role'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { getRoute, RouterPaths } from '../../../router/routes'

export const GroupListItem = (group: GroupResponse) => {
  const { id, name, description, totalMembers } = group
  const { t } = useTranslation()
  const { permissions } = useAuthContext()

  return (
    <>
      <tr>
        <td>{name}</td>
        <td>{description}</td>
        <td style={{ width: 100, textAlign: 'right' }}>{totalMembers}</td>
        <td>
          {permissions?.includes(`${PermissionGroup.GROUP}.edit`) && (
            <a href={getRoute(RouterPaths.EDIT_GROUP.replace(':id', id?.toString()!))} className="btn btn-primary btn-xs mr-1">
              <span className="fas fa-edit"></span> {t('app.edit')}
            </a>
          )}
        </td>
      </tr>
    </>
  )
}

export default GroupListItem
