import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { Notification } from './models/user'

const NOTIFICATION_URL = 'notification'

export const markNotificationAsRead: (_: string) => Promise<SuccessResponse<Array<Notification>>> = (id) => {
  return axios.patch<SuccessResponse<Array<Notification>>>(`${NOTIFICATION_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}
