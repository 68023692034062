import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'

export const Logo = () => {
  const { logo } = useClubConfigContext()
  return (
    <div className="login-logo">
      {logo ? (
        <p>
          <img src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`} height="100" />
        </p>
      ) : (
        <p>
          <img src={require('../../../assets/img/skermo_solo_sm_trans.png')} width="100" />
        </p>
      )}
    </div>
  )
}

export default Logo
