import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { createAttendanceSheet, deleteAttendanceSheet } from '../../api/attendance'
import { createGroup, getGroup, updateGroup } from '../../api/group'
import { AttendanceSheet } from '../../api/models/attendance'
import { GroupResponse } from '../../api/models/group'
import DeleteGroupButton from '../../components/buttons/DeleteGroupButton'
import CustomToast from '../../components/common/CustomToast'
import Layout from '../../components/common/Layout'
import GroupForm from '../../components/forms/GroupForm'
import AddUsersModal from '../../components/modals/AddUsersModal'
import DeleteModal from '../../components/modals/DeleteModal'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'
import useUIContext from '../../contexts/hooks/useUIContext'

export type GroupErrors = Partial<{
  [key in keyof GroupResponse]?: string
}>

export const CreateGroup = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clubCode } = useClubConfigContext()
  const { updateAfterCloseModal, setUpdateAfterCloseModal } = useUIContext()

  const urlParams = useParams()
  const groupId = urlParams?.id || false

  const [group, setGroup] = useState<Partial<GroupResponse>>({})
  const [errors, setErrors] = useState<GroupErrors>({})
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const handleSubmit = () => {
    if (groupId) {
      updateGroup(group as GroupResponse)
        .then(() => {
          toast.custom(<CustomToast text={t('notifications.updated')} />)
          navigate(`/${clubCode}/groups`)
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error.errors) {
            setErrors(error.errors)
          }
        })
    } else {
      createGroup(group as GroupResponse)
        .then(() => {
          toast.custom(<CustomToast text={t('notifications.created')} />)
          navigate(`/${clubCode}/groups`)
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error.errors) {
            setErrors(error.errors)
          }
        })
    }
  }

  const onClickAttendance = () => {
    createAttendanceSheet({ name: group.name, group, members: group.members } as AttendanceSheet).then((response) => {
      if (response.success) {
        toast.custom(<CustomToast text={t('notifications.attendance_sheet_created')} />)
        setGroup((prev) => ({ ...prev, attendanceSheet: response.data }))
      }
    })
  }

  const onRemoveAttendanceSheet = (groupId: string) => {
    deleteAttendanceSheet(groupId as string).then((response) => {
      if (response.success) {
        toast.custom(<CustomToast text={t('notifications.attendance_sheet.removed')} />)
        setDeleteModalOpen(false)
        setGroup((prev) => ({ ...prev, attendanceSheet: null }))
      }
    })
  }

  useEffect(() => {
    if (groupId || (updateAfterCloseModal && groupId)) {
      getGroup(groupId).then((response) => {
        setGroup(response)
        setUpdateAfterCloseModal(false)
      })
    }
  }, [groupId, updateAfterCloseModal])

  return (
    <Layout>
      <section className="content">
        <div className="col-12 d-flex justify-content-between align-items-center pt-3">
          <h1>{groupId ? t('groups.edit_group') : t('groups.create_group')}</h1>
          <div className="d-flex align-items-center">
            {groupId &&
              Object.keys(group).length > 0 &&
              (!group.attendanceSheet ? (
                <a onClick={onClickAttendance} className="btn btn-primary mr-2">
                  <i className={`fa fa-calendar-check mr-2`}></i>
                  {t('buttons.create_attendance_sheet')}
                </a>
              ) : (
                <a className="btn btn-warning mr-2" onClick={() => setDeleteModalOpen(true)}>
                  <i className={`fa fa-calendar-times mr-2`}></i>
                  {t('buttons.remove_attendance_sheet')}
                </a>
              ))}
            <DeleteGroupButton id={group?.id} />
          </div>
        </div>
        <GroupForm group={group} errors={errors} setGroup={setGroup} handleSubmit={handleSubmit} openModal={() => setModalOpen(true)} />
      </section>
      <AddUsersModal isOpen={isModalOpen} closeModal={() => setModalOpen(false)} group={group} action={groupId ? 'update' : 'create'} />
      <DeleteModal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        onRemove={() => onRemoveAttendanceSheet(groupId?.toString()!)}
        textKey="notifications.attendance_sheet.deactivate"
      />
    </Layout>
  )
}

export default CreateGroup
