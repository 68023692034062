import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getSkermoUrl } from '../../../api/integration'
import SideBarMenuLoader from '../../../components/loaders/SidebarMenuLoader'
import { NO_CLUB_CODE } from '../../../contexts/ClubConfigContext'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import useUIContext from '../../../contexts/hooks/useUIContext'
import SidebarMenuItem from '../SidebarMenuItem'

export const SidebarMenu = () => {
  const { t } = useTranslation()

  const { modules, showFederationLink } = useAuthContext()
  const { openModal } = useUIContext()
  const { basicInfo } = useClubConfigContext()

  const [subMenuOpen, setSubMenuOpen] = useState<string | null>(null)

  const language = localStorage.getItem('language')
  const clubCode = localStorage.getItem('clubCode')

  const publicForms = {
    name: 'Forms',
    icon: 'fa-file-invoice',
    label: t('menu.forms'),
    subModules: [
      {
        id: -1,
        name: 'Preinscription',
        path: `/preinscription/${language}`,
        openNewPage: true
      },
      {
        id: -2,
        name: 'Contact',
        path: `/contact/${language}`,
        openNewPage: true
      }
    ]
  }
  const contact = {
    name: 'Help',
    icon: 'fa-question-circle',
    label: t('menu.help'),
    subModules: [
      {
        id: -10,
        name: 'Contact',
        icon: 'fa-envelope',
        onClickAction: openModal
      }
    ]
  }

  // const formEdit = {
  //   name: 'FormsConfiguration',
  //   icon: 'fa-file-signature',
  //   label: t('menu.form-configuration')
  // }

  const federation = {
    name: 'Federation',
    icon: 'fa-sign-in',
    label: basicInfo?.federation || t('menu.federation'),
    onClickAction: () => {
      getSkermoUrl().then((response) => {
        if (response.success) {
          window.open(response.data.redirectUrl, '_blank')
        }
      })
    }
  }

  return (
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {modules ? (
          modules
            .sort((a, b) => a.orderModule - b.orderModule)
            .map((module) => (
              <SidebarMenuItem
                key={module.id}
                icon={module.icon}
                name={module.name}
                label={t(`menu.${module.name.toLowerCase()}`)}
                path={module?.path}
                subModules={module?.subModules}
                subMenuOpen={subMenuOpen}
                setSubMenuOpen={setSubMenuOpen}
              />
            ))
        ) : (
          <SideBarMenuLoader />
        )}
        {clubCode !== NO_CLUB_CODE && (
          <>
            <SidebarMenuItem {...publicForms} subMenuOpen={subMenuOpen} setSubMenuOpen={setSubMenuOpen} />
            <SidebarMenuItem {...contact} subMenuOpen={subMenuOpen} setSubMenuOpen={setSubMenuOpen} />
            {/* <SidebarMenuItem {...formEdit} subMenuOpen={subMenuOpen} setSubMenuOpen={setSubMenuOpen} /> */}
            {showFederationLink && (
              <SidebarMenuItem
                {...federation}
                subMenuOpen={subMenuOpen}
                setSubMenuOpen={setSubMenuOpen}
                customStyle={{ marginTop: 16, backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: 5, fontSize: 12, whiteSpace: 'pre-wrap' }}
              />
            )}
          </>
        )}
      </ul>
    </nav>
  )
}

export default SidebarMenu
