export const SCREEN_SIZE = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const DEFAULT_COLUMN_SIZE = window.innerWidth > SCREEN_SIZE.md ? 6 : 12
export const isDevice = window.innerWidth < SCREEN_SIZE.sm
