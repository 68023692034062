import { ChangeEvent } from 'react'

export interface InputWithoutLabelProps {
  type?: string
  name: string
  value: string
  required?: boolean
  placeholder?: string
  iconClass?: string
  onChange: (_: ChangeEvent<HTMLInputElement>) => void
}

export const InputWithoutLabel = (props: InputWithoutLabelProps) => {
  const { type = 'text', name, value, required = false, placeholder = '', iconClass, onChange } = props
  return (
    <div className={`${iconClass ? 'input-group' : 'input'} mb-3`}>
      <input
        type={type}
        name={name as string | undefined}
        required={required}
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {iconClass && (
        <div className="input-group-append">
          <div className="input-group-text">
            <span className={`fas ${iconClass}`}></span>
          </div>
        </div>
      )}
    </div>
  )
}

export default InputWithoutLabel
