import '../styles.css'

import { useEffect, useState } from 'react'

import { AttendanceReportResponse } from '../../../../../api/models/reports'
import { getAttendanceSeasonReport } from '../../../../../api/reports'
import ByMonthsReportTable from './ByMonthsReportTable'

interface SeasonReportTableProps {
  id: number
}

export const SeasonReportTable = ({ id }: SeasonReportTableProps) => {
  const [attendanceReport, setAttendanceReport] = useState<AttendanceReportResponse | null>(null)

  useEffect(() => {
    getAttendanceSeasonReport({ id }).then((response) => {
      setAttendanceReport(response)
    })
  }, [])

  if (!id || !attendanceReport) {
    return null
  }

  return <ByMonthsReportTable attendanceReport={attendanceReport} />
}

export default SeasonReportTable
