import ContentLoader from 'react-content-loader'

export const SideBarMenuLoader = (props: any) => (
  <ContentLoader speed={2} width={250} height={200} viewBox="0 0 250 200" backgroundColor="#c2c7d0" foregroundColor="#f3f3f3" {...props}>
    <circle cx="30" cy="16" r="10" />
    <rect x="47" y="10" rx="2" ry="2" width="75" height="10" />
    <circle cx="30" cy="66" r="10" />
    <rect x="47" y="60" rx="2" ry="2" width="75" height="10" />
    <circle cx="30" cy="116" r="10" />
    <rect x="47" y="110" rx="2" ry="2" width="75" height="10" />
    <circle cx="30" cy="166" r="10" />
    <rect x="47" y="160" rx="2" ry="2" width="75" height="10" />
  </ContentLoader>
)

export default SideBarMenuLoader
