import './App.css'

import { Toaster } from 'react-hot-toast'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'

import { AuthProvider } from './contexts/AuthContext'
import { ClubConfigProvider } from './contexts/ClubConfigContext'
import { UIProvider } from './contexts/UIContext'
import { setupAxios } from './helpers/axios-interceptors'
import { router } from './router/router'
import i18n from './translations'

export const App = () => {
  setupAxios()

  return (
    <I18nextProvider i18n={i18n}>
      <UIProvider>
        <ClubConfigProvider>
          <AuthProvider>
            <RouterProvider router={router} />
            <Toaster toastOptions={{ duration: 3000 }} />
          </AuthProvider>
        </ClubConfigProvider>
      </UIProvider>
    </I18nextProvider>
  )
}

export default App
