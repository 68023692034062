import '../../App.css'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ContactForm } from '../../components/forms/ContactForm'
import LanguageSelector from '../../components/Preinscription/LanguageSelector'
import PreinscriptionHeader from '../../components/Preinscription/PreinscriptionHeader'
import { standarizeLanguage } from '../../helpers/common'
import { SCREEN_SIZE } from '../../helpers/layout'
import { setLanguage } from '../../translations'
import ClubConfigWrapper from '../../wrappers/ClubConfigWrapper'

export const Contact = () => {
  const { t } = useTranslation()
  const lang = useParams<{ lang: string }>()?.lang

  useEffect(() => {
    if (lang) {
      setLanguage(standarizeLanguage(lang))
    }
  }, [lang])

  return (
    <ClubConfigWrapper>
      <section className="content m-4 d-flex flex-column">
        <div className="card">
          <div className="card-body row">
            <div className={`${window.innerWidth > SCREEN_SIZE.md ? 'col-4 pt-5' : 'col-12 pt-2'} text-center d-flex justify-content-center`}>
              <PreinscriptionHeader title={t('members.contact_title')} />
            </div>
            <div className={window.innerWidth > SCREEN_SIZE.md ? 'col-8' : 'col-12'}>
              <section className="content m-4 d-flex flex-column">
                <LanguageSelector url="contact" />
                <div className="row" style={{ marginTop: 50 }}>
                  <ContactForm lang={lang} />
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </ClubConfigWrapper>
  )
}

export default Contact
