import { MemberResponse } from '../api/models/member'
import { AVAILABLE_LANGUAGES } from '../translations'

const languagesToFormat = [AVAILABLE_LANGUAGES.es_ES, AVAILABLE_LANGUAGES.ca_ES, AVAILABLE_LANGUAGES.gl_ES]

export const formatDateString = (date: string) => {
  const delimiter = date.includes('/') ? '/' : '-'
  const splittedDate = date.split(delimiter)

  if (delimiter === '/' && splittedDate[2].length === 4) {
    return splittedDate.reverse().join('-')
  }

  return date
}

export const formatSpanishPostalCode = (postalCode: string) => {
  if (postalCode.length === 5) return postalCode
  if (postalCode.length === 4) return `0${postalCode}`
  return postalCode
}

export const formatInternationalPhone = (phone: string) => {
  const phoneWithoutSpaces = phone.trim().replaceAll(' ', '')
  if (phoneWithoutSpaces.startsWith('+')) return phone
  if (phoneWithoutSpaces.startsWith('34')) return `+${phoneWithoutSpaces}`
  return `+34${phoneWithoutSpaces}`
}

export const formatMember: (member: any, lang: AVAILABLE_LANGUAGES | string) => Partial<MemberResponse> = (
  member,
  lang = AVAILABLE_LANGUAGES.es_ES
) => {
  if (!languagesToFormat.includes(lang as AVAILABLE_LANGUAGES)) return member
  return {
    ...member,
    phone: formatInternationalPhone(member.phone),
    phone2: member.phone2 ? formatInternationalPhone(member.phone2) : undefined,
    postalCode: formatSpanishPostalCode(member.postalCode),
    birthdate: formatDateString(member.birthdate),
    createdAt: member.createdAt ? formatDateString(member.createdAt) : undefined,
    suspendedAt: member.suspendedAt ? formatDateString(member.suspendedAt) : undefined
  }
}
