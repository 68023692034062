import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { MemberPrivacyRequest, PrivacyFormConfigElement } from '../../../api/models/formsConfig'

interface AcceptPrivacyItemProps {
  field: PrivacyFormConfigElement
  setObject: Dispatch<SetStateAction<MemberPrivacyRequest>>
}

export const AcceptPrivacyItem = ({ field, setObject }: AcceptPrivacyItemProps) => {
  const { t } = useTranslation()
  return (
    <>
      {field.showInForm ? (
        <div className={`d-flex row justify-content-start mb-2 ${!field.documentPath && 'align-items-center'}`}>
          <div className="icheck-primary d-inline">
            <input
              type="checkbox"
              id={field.name}
              onChange={() => setObject((prev: MemberPrivacyRequest) => ({ ...prev, [field.name]: !prev[field.name] }))}
            />
            <label htmlFor={field.name}></label>
          </div>
          <div className="d-flex flex-column flex-1">
            <p className="d-flex row media-body ml-2 mb-0" style={{ whiteSpace: 'pre-wrap' }}>
              {`${t(`accept_privacy.member.${field.name}`)} `}
              <p className="font-weight-bold mb-0">{`${t(`accept_privacy.title.${field.name}`)}`}</p>
              {!field.required && (
                <p style={{ color: 'gray', fontSize: 12, display: 'inline-block', marginBottom: 0, marginLeft: 4 }}>{`  (${t('app.optional')})`}</p>
              )}
            </p>
            {field.documentPath && (
              <a
                className="document-link text-sm ml-2"
                href={`${process.env.REACT_APP_LOGO_BASE_URL}${field.documentPath}`}
                target="_blank"
                rel="noreferrer"
              >
                {t('accept_privacy.document_link')}
              </a>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default AcceptPrivacyItem
