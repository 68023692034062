import './style.css'

import { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Module } from '../../../api/models/permission'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'

interface SidebarMenuItemProps extends Partial<Omit<Module, 'orderModule' | 'id' | 'subModules'>> {
  label: string
  openNewPage?: boolean
  subModules?: Array<Omit<Module, 'orderModule'>>
  isSubModule?: boolean
  onClickAction?: () => void
  subMenuOpen?: string | null
  setSubMenuOpen?: Dispatch<SetStateAction<string | null>>
  customStyle?: CSSProperties
}

export const SidebarMenuItem = (props: SidebarMenuItemProps) => {
  const { icon, name, label, path, openNewPage, subModules, isSubModule, onClickAction, subMenuOpen, setSubMenuOpen, customStyle } = props
  const { t } = useTranslation()
  const { clubCode } = useClubConfigContext()

  const [menuOpen, setMenuOpen] = useState(false)

  const active = localStorage.getItem('menuSelected') === name
  const hasSubModulesActive = subModules?.some((module) => localStorage.getItem('menuSelected') === module.name) || false

  useEffect(() => {
    setMenuOpen(hasSubModulesActive)
  }, [hasSubModulesActive])

  return (
    <li className={`nav-item w-100${hasSubModulesActive || subMenuOpen === name ? ' menu-is-opening menu-open' : ''}`} style={customStyle}>
      <a
        href={!subModules?.length && path ? `/${clubCode}${path}` : '#'}
        target={openNewPage ? '_blank' : ''}
        rel="noreferrer"
        className={`link ${active ? 'active' : ''} ${isSubModule && 'pl-4'}`}
        onClick={() => {
          if (onClickAction) return onClickAction()
          if (!subModules?.length) localStorage.setItem('menuSelected', name || '')
          setMenuOpen(!menuOpen)

          if (subMenuOpen !== name) {
            setSubMenuOpen?.(name || '')
          } else {
            setSubMenuOpen?.(null)
          }
        }}
      >
        <div className="row align-items-center">
          <i className={`nav-icon ${icon ? `fas fa ${icon}` : 'far fa-circle'} ${isSubModule && 'text-sm'}`}></i>
          <p className={isSubModule ? 'text-sm' : ''} style={{ flex: 1 }}>
            {label}
          </p>
        </div>
        {subModules?.length ? <i className="right fas fa-angle-left"></i> : <></>}
      </a>
      {!!subModules?.length && (
        <ul className="nav nav-treeview d-block">
          {subModules.map((module) => (
            <SidebarMenuItem key={module.id} label={t(`menu.${module.name.toLowerCase()}`)} isSubModule {...module} />
          ))}
        </ul>
      )}
    </li>
  )
}

export default SidebarMenuItem
