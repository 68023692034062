import { DocumentationType, PaymentMethod, TutorType } from './common'
import { MemberPrivacyRequest, PrivacyElement } from './formsConfig'
import { GroupResponse } from './group'

export enum Gender {
  MALE = 'M',
  FEMALE = 'W'
  // OTHER = 'O'
}

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive', // maintenance
  SUSPENDED = 'suspended',
  PENDING = 'pending', // pending approval
  REJECTED = 'rejected', // rejected before approval
  INTERESTED = 'interested', // still interested in future
  RIGHTS_PENDING = 'rights_pending' // pending rights approval
}

export const PREREGISTERED_STATUS = [Status.PENDING, Status.REJECTED, Status.INTERESTED, Status.RIGHTS_PENDING]
export const ACTIVE_STATUS = [Status.ACTIVE, Status.INACTIVE, Status.SUSPENDED]

export interface PaymentData {
  paymentMethod?: PaymentMethod
  titular?: string
  iban?: string
  bic?: string
}

export interface Tutor {
  name?: string
  lastname?: string
  email?: string
  phone?: string
  document?: string
  documentType?: DocumentationType
  type?: TutorType
}

export enum MemberAcceptanceRegistrationStatus {
  SENT = 1,
  OPENED = 2,
  SIGNED = 3,
  RESENT = 4
}

export interface MemberAcceptanceRegistration {
  status: MemberAcceptanceRegistrationStatus
  origin?: string
  token?: string
  updatedAt: string
}

export interface MemberDocument {
  id: number
  type?: string
  document: string
  name: string
}

export interface MemberResponse {
  id?: number
  number?: number
  name: string
  lastname: string
  nationality: string
  email: string
  email2?: string
  gender: Gender
  documentType: DocumentationType
  documentValue: string
  status: Status
  suspendedAt?: Date
  suspendedReason?: number
  suspededReasonLabel?: string
  birthdate: Date
  photo?: string
  laterality?: string
  address: string
  town: string
  postalCode: string
  country: string
  phone: string
  phone2?: string
  comments?: string
  weapon?: string
  type?: number
  typeLabel?: string
  lang?: string
  origin?: number
  originLabel?: string
  federationId?: string
  groups?: Array<GroupResponse>
  paymentData?: PaymentData
  tutor?: Tutor // @deprecated
  tutors?: Tutor[]
  category?: string
  age: number
  createdAt?: Date
  acceptances?: { [key in PrivacyElement]?: boolean }
  acceptanceRegistration: MemberAcceptanceRegistration
  documents?: MemberDocument[]
}

export interface MemberQueryRequest {
  preregistered?: boolean
  nameLastname?: string
  gender?: string
  ageFrom?: string
  ageTo?: string
  weapon?: string[]
  yearOfBirth?: string
  category?: string[]
  group?: string[]
  status?: Status[]
  orderBy?: {
    field: keyof MemberResponse
    direction: 'ASC' | 'DESC'
  }
  paginate?: number
  export?: boolean
}

export interface MemberPreinscriptionRequest {
  member: MemberResponse
  clubCode: string
  recaptchaToken: string
}

export interface ImportMembersWithErrors {
  errors?: { unique?: Array<string> }
}

export interface ImportMembersDataResponse {
  totalMembers: number
}

export type MemberAcceptPrivacyRequest = {
  clubCode: string
  token: string
} & { [key in PrivacyElement]?: boolean }

export interface MemberAcceptanceRequest {
  acceptances: MemberPrivacyRequest
  token?: string
}
