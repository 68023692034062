import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PaginatedData } from '../../api/models/common'
import { PermissionGroup } from '../../api/models/role'
import { UserResponse } from '../../api/models/user'
import { getUsers } from '../../api/user'
import CardHeader from '../../components/common/CardHeader'
import Layout from '../../components/common/Layout'
import UserList from '../../components/User/UserList'

export const ListUsers = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<PaginatedData<UserResponse>>()

  useEffect(() => {
    getUsers().then((response) => {
      setPagination(response)
    })
  }, [])

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid mt-5">
          <div className="card">
            <CardHeader title={t('users.title')} group={PermissionGroup.USER} />
            <UserList {...pagination!} setPagination={setPagination} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ListUsers
