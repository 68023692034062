import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { GroupResponse } from './models/group'

const GET_GROUPS = 'groups'
const GROUP_URL = 'group'

export const getGroups: () => Promise<Array<GroupResponse>> = () => {
  return axios.get<Array<GroupResponse>>(GET_GROUPS).then((response: AxiosResponse) => response?.data)
}

export const getGroup: (id: string) => Promise<GroupResponse> = (id) => {
  return axios.get<GroupResponse>(`${GROUP_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const createGroup: (group: GroupResponse) => Promise<SuccessResponse<{}>> = (group) => {
  return axios.post<GroupResponse>(GROUP_URL, group).then((response: AxiosResponse) => response?.data)
}

export const updateGroup: (group: GroupResponse) => Promise<SuccessResponse<GroupResponse>> = (group) => {
  return axios.patch<GroupResponse>(`${GROUP_URL}/${group.id}`, group).then((response: AxiosResponse) => response?.data)
}

export const deleteGroup: (id: string) => Promise<SuccessResponse<{}>> = (id) => {
  return axios.delete(`${GROUP_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}
