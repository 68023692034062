// @ts-nocheck
import './styles.css'

import { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Field } from '../../../../api/models/club'
import { FieldType, FormErrors, FormSectionElement } from '../../../../api/models/common'
import AcceptPrivacyFormSection from '../../../Privacy/AcceptPrivacyFormSection'
import FormSectionHeader from '../FormSectionHeader'
import Input from '../Input'
import InputColor from '../InputColor'
import InputDate from '../InputDate'
import InputFile from '../InputFile'
import InputGroup from '../InputGroup'
import InputImage from '../InputImage'
import InputPassword from '../InputPassword'
import MultiSelect from '../MultiSelect'
import PhoneInput from '../PhoneInput'
import Select from '../Select'
import SwitchCheckbox from '../SwitchCheckbox'
import Textarea from '../Textarea'

export interface FormSectionProps<T extends any> extends FormSectionElement<T> {
  name?: string
  firstElement?: boolean
  withoutBorder?: boolean
  formObject?: Partial<T>
  setFormObject: Dispatch<SetStateAction<Partial<T>>>
  errors: FormErrors<T>
  setErrors?: Dispatch<SetStateAction<FormErrors<T>>>
}

export const FormSection = ({
  name,
  title,
  firstElement,
  fields = [],
  formObject = {},
  setFormObject,
  errors = {},
  setErrors,
  startCollapsed,
  withoutBorder = false,
  conditionalHide = false
}: FormSectionProps<any>) => {
  const { t } = useTranslation()

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, { name, objectKey }: Field) => {
    const value = e.target.value.length ? e.target.value : null
    if (objectKey) {
      setFormObject((prev: FormSectionProps<'formObject'>) => ({ ...prev, [objectKey]: { ...prev[objectKey], [name]: value } }))
    } else {
      setFormObject((prev: FormSectionProps<'formObject'>) => ({ ...prev, [name]: value }))
    }
  }

  const removeError = (name: string) => {
    if (setErrors) {
      setErrors((prev: FormSectionProps<'errors'>) => {
        const newErrors = { ...prev }
        delete newErrors[name]
        return newErrors
      })
    }
  }

  const errorKeys = Object.keys(errors)
  const fieldKeys = fields.map((field) => (field.objectKey ? `${field.objectKey}.${field.name}` : field.name))
  const haveErrors = errorKeys.some((errorKey) => fieldKeys.includes(errorKey))

  const fieldRender: (field: Field) => { [key in FieldType]?: ReactNode } = (field) => {
    const fieldValue = (fieldName?: string) =>
      field.objectKey
        ? formObject[field.objectKey] && formObject[field.objectKey] !== null
          ? formObject[field.objectKey][fieldName]
          : null
        : formObject[fieldName]?.toString()

    // TODO extract this to helper
    const error =
      field.objectKey && `${field.objectKey}.${field.name}` in errors
        ? errors[`${field.objectKey}.${field.name}`]
        : field.name in errors
          ? errors[field.name]
          : undefined

    const groupError =
      field.objectKey && (`${field.objectKey}.${field.name}` in errors || `${field.objectKey}.${field.nameSelect!}` in errors)
        ? errors[`${field.objectKey}.${field.name}`] || errors[`${field.objectKey}.${field.nameSelect!}`]
        : field.name in errors || field.nameSelect! in errors
          ? errors[field.name] || errors[field.nameSelect!]
          : undefined

    return {
      [FieldType.TEXT]: (
        <Input
          key={field.name}
          label={t(field.label)}
          name={field.objectKey ? `${field.objectKey}.${field.name}` : field.name}
          required={field.required}
          disabled={field.disabled}
          value={fieldValue(field.name)}
          onChange={(e) => onChange(e, field)}
          error={error}
          columnSize={field.columnSize}
          placeholder={field.placeholder}
          removeError={() => removeError(field.objectKey ? `${field.objectKey}.${field.name}` : field.name)}
        />
      ),
      [FieldType.SELECT]: (
        <Select
          key={field.name}
          label={t(field.label)}
          name={field.name}
          options={field.options!}
          required={field.required}
          disabled={field.disabled}
          value={fieldValue(field.name)}
          onChange={(e) => onChange(e, field)}
          error={field.name in errors ? errors[field.name] : undefined}
          columnSize={field.columnSize}
          removeError={() => removeError(field.name)}
        />
      ),
      [FieldType.INPUT_GROUP]: (
        <InputGroup
          key={field.name}
          label={t(field.label)}
          nameSelect={field.nameSelect!}
          name={field.name}
          options={field.options!}
          required={field.required}
          disabled={field.disabled}
          value={fieldValue(field.name)}
          valueSelect={fieldValue(field.nameSelect!)}
          onChange={(e) => onChange(e, field)}
          onChangeSelect={(e) => onChange(e, { name: field.nameSelect!, objectKey: field.objectKey })}
          error={groupError}
          columnSize={field.columnSize}
          removeError={() => removeError(field.name)}
        />
      ),
      [FieldType.DATE]: (
        <InputDate
          key={field.name}
          label={t(field.label)}
          name={field.name}
          onChange={(date) => setFormObject((prev) => ({ ...prev, [field.name]: date }))}
          required={field.required}
          disabled={field.disabled}
          value={typeof formObject[field.name] === 'string' ? new Date(formObject[field.name] as string) : (formObject[field.name] as Date)}
          error={field.name in errors ? errors[field.name] : undefined}
          columnSize={field.columnSize}
          removeError={() => removeError(field.name)}
        />
      ),
      [FieldType.CHECKBOX]: (
        <SwitchCheckbox
          key={field.name}
          label={t(field.label)}
          name={field.name}
          required={field.required}
          disabled={field.disabled}
          value={fieldValue(field.name) as boolean}
          onChange={() => setFormObject((prev) => ({ ...prev, [field.name]: !prev[field.name] }))}
          error={field.name in errors ? errors[field.name] : undefined}
          columnSize={field.columnSize}
        />
      ),
      [FieldType.TEXTAREA]: (
        <Textarea
          key={field.name}
          label={t(field.label)}
          name={field.name}
          required={field.required}
          disabled={field.disabled}
          value={formObject[field.name] as string}
          onChange={(e) => setFormObject((prev) => ({ ...prev, [field.name]: e.target.value }))}
          error={field.name in errors ? errors[field.name] : undefined}
        />
      ),
      [FieldType.COLOR]: (
        <InputColor
          key={field.name}
          label={t(field.label)}
          name={field.name}
          required={field.required}
          disabled={field.disabled}
          value={formObject[field.name] as string}
          onChange={(e) => setFormObject((prev) => ({ ...prev, [field.name]: e.target.value }))}
          error={field.name in errors ? errors[field.name] : undefined}
        />
      ),
      [FieldType.PASSWORD]: (
        <InputPassword
          key={field.name}
          label={t(field.label)}
          name={field.name}
          required={field.required}
          value={formObject[field.name] as string}
          onChange={(e) => onChange(e, field)}
          error={field.name in errors ? errors[field.name] : undefined}
          placeholder={field.placeholder}
        />
      ),
      [FieldType.FILE]: (
        <InputFile
          key={field.name}
          label={t(field.label)}
          name={field.name}
          required={field.required}
          disabled={field.disabled}
          value={''}
          onChange={(e) => setFormObject((prev) => ({ ...prev, [field.name]: e.target.files[0] }))}
          error={field.name in errors ? errors[field.name] : undefined}
        />
      ),
      [FieldType.IMAGE]: (
        <InputImage
          columnSize={4}
          key={field.name}
          label={t(field.label)}
          name={field.name}
          required={field.required}
          disabled={field.disabled}
          value={formObject[field.name]}
          valuePreview={formObject[`${field.name}Path`]}
          onChange={(image) => setFormObject((prev) => ({ ...prev, [field.name]: image }))}
          onChangePreview={(imagePath) => setFormObject((prev) => ({ ...prev, [`${field.name}Path`]: imagePath }))}
          error={field.name in errors ? errors[field.name] : undefined}
          id={formObject.id}
        />
      ),
      [FieldType.MULTI_SELECT]: (
        <MultiSelect
          key={field.name}
          label={t(field.label)}
          name={field.name}
          options={field.options!}
          required={field.required}
          disabled={field.disabled}
          value={field.options?.filter((option) => (Array.isArray(formObject[field.name]) ? formObject[field.name].includes(option.value) : ''))}
          // @ts-ignore
          onChange={(optionSelected: Option[]) => {
            setFormObject((prev: FormSectionProps<'formObject'>) => ({
              ...prev,
              [field.name]: optionSelected.map((option) => option.value as string)
            }))
          }}
          error={field.name in errors ? errors[field.name] : undefined}
          columnSize={field.columnSize}
          size="medium"
        />
      ),
      [FieldType.PHONE]: (
        <PhoneInput
          key={field.name}
          label={t(field.label)}
          name={field.objectKey ? `${field.objectKey}.${field.name}` : field.name}
          required={field.required}
          disabled={field.disabled}
          value={fieldValue(field.name)}
          onChange={(value) => {
            if (field.objectKey) {
              setFormObject((prev: FormSectionProps<'formObject'>) => ({
                ...prev,
                [field.objectKey]: { ...prev[field.objectKey], [field.name]: value }
              }))
            } else {
              setFormObject((prev: FormSectionProps<'formObject'>) => ({ ...prev, [field.name]: value }))
            }
          }}
          error={error}
          columnSize={field.columnSize}
          placeholder={field.placeholder}
          removeError={() => removeError(field.objectKey ? `${field.objectKey}.${field.name}` : field.name)}
        />
      )
    }
  }

  if (conditionalHide) return null

  return (
    <div className={`${!withoutBorder && 'card'} ${startCollapsed && !haveErrors && 'collapsed-card'}`}>
      {title && <FormSectionHeader titleKey={name} title={title} startCollapsed={startCollapsed && !haveErrors} objectId={formObject?.id} />}
      <div className="card-body">
        {name === 'members.basic_information' && firstElement && (
          <div className="row">
            <Input
              label={t('members.number_member')}
              name={'number'}
              value={formObject.number}
              onChange={(e) => setFormObject((prev) => ({ ...prev, number: Number(e.target.value) }))}
              columnSize={3}
            />
          </div>
        )}
        {name === 'members.authorization_info' && formObject?.id && (
          <AcceptPrivacyFormSection id={formObject?.id} registration={formObject?.acceptanceRegistration} />
        )}
        <div className="row">{fields.map((field: Field<any>) => fieldRender(field)[field.type])}</div>
      </div>
    </div>
  )
}

export default FormSection
