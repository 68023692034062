import { useTranslation } from 'react-i18next'

import { logout } from '../../../api/auth'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import useCleanContexts from '../../../hooks/useCleanContexts'
import Notifications from '../Notifications'

export const NavBar = () => {
  const { t } = useTranslation()
  const { clubCode } = useClubConfigContext()
  const cleanContexts = useCleanContexts()

  const onClickLogout = () => {
    logout().then(() => {
      localStorage.clear()
      cleanContexts()
      window.location.href = `/${clubCode}/login`
    })
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars"></i>
          </a>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <Notifications />
        <li className="nav-item">
          <a className="nav-link" onClick={onClickLogout}>
            {t('app.logout')} <i className="fas fa-arrow-alt-circle-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
