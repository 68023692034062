import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { PrivacyFormConfigGroups, PrivacyFormConfigRequest } from './models/formsConfig'

export const PRIVACY_CONFIG = 'privacy-config'

export const getPrivacyConfig: () => Promise<PrivacyFormConfigGroups> = () => {
  return axios.get<PrivacyFormConfigGroups>(PRIVACY_CONFIG).then((response: AxiosResponse) => response?.data)
}

export const createPrivacyConfig: (privacyConfig: PrivacyFormConfigRequest) => Promise<SuccessResponse<{}>> = (privacyConfig) => {
  return axios.post<SuccessResponse<{}>>(PRIVACY_CONFIG, privacyConfig).then((response: AxiosResponse) => response?.data)
}
