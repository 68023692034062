import { Changelog } from '../../../api/models/changelog'

export const ChangelogItem = ({ title, description, version, date }: Changelog) => {
  return (
    <div className="py-2" style={{ borderTop: '1px solid lightgray' }}>
      <div className="d-flex justify-content-between align-items-top flex-column">
        <div className="d-flex flex-fill px-1">
          <p className="mb-0 w-fit d-flex flex-fill px-1 text-right" style={{ minWidth: 80 }}>
            {new Date(date).toLocaleDateString('es-ES')}
          </p>
          <p className="mb-0 w-90 d-flex flex-fill px-1 direct-chat-name">{title}</p>
        </div>
        <p className="mb-0 w-100 d-flex flex-fill px-2 text-sm">{description}</p>
      </div>
    </div>
  )
}

export default ChangelogItem
