export interface ContactRequest {
  reason: string
  email: string
  observations: string
  clubCode?: string
}

export enum InfoRequestOrigin {
  EMAIL = 'E',
  PHONE = 'P',
  FORM = 'F',
  SITE = 'S', // Presencial
  CHAT = 'C'
}

export enum InfoRequestStatus {
  OPENED = 1,
  ANSWERED = 2,
  CLOSED = 3,
  PREINSCRIPTION = 4
}

export interface InfoRequestComment {
  id?: number
  comment: string
  author?: string
  createdAt: string
}

export interface InfoRequestResponse {
  id?: number
  name: string
  email: string
  phone?: string
  message: string
  origin: InfoRequestOrigin
  status: InfoRequestStatus
  createdAt?: string
  comments: InfoRequestComment[]
}

export interface ContactInfoRequest extends InfoRequestResponse {
  clubCode: string
  recaptchaToken: string
  lang?: string
}
