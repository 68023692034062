import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useNotifications from '../../../hooks/useNotifications'
import NotificationFragment from '../NotificationFragment'

export const Notifications = () => {
  const { t } = useTranslation()
  const { mainUnReadNotifications, unReadNotificationsCount } = useNotifications()
  const clubCode = useParams<{ clubCode: string }>().clubCode

  return (
    <li className="nav-item dropdown show">
      <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
        <i className="far fa-bell"></i>
        <span className="badge badge-warning navbar-badge">{unReadNotificationsCount}</span>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        {unReadNotificationsCount === 0 && <span className="dropdown-item dropdown-header">{t('show_notifications.no_notifications')}</span>}
        {unReadNotificationsCount > 0 && (
          <span className="dropdown-item dropdown-header">
            {unReadNotificationsCount === 1
              ? t('show_notifications.title_single')
              : t('show_notifications.title', { number: unReadNotificationsCount })}
          </span>
        )}
        <div className="dropdown-divider"></div>
        {mainUnReadNotifications?.map((notification) => <NotificationFragment key={notification.id} {...notification} />)}
        <div className="dropdown-divider"></div>
        <a href={`/${clubCode}/profile/notifications`} className="dropdown-item dropdown-footer">
          {t('show_notifications.see_all')}
        </a>
      </div>
    </li>
  )
}

export default Notifications
