// @ts-nocheck
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'

import { ModalProps } from '../ContactModal'

interface DeleteModalProps extends ModalProps {
  onRemove: () => void
  textKey?: string
}

export const DeleteModal = ({ isOpen, closeModal, onRemove, textKey = 'notifications.really_delete' }: DeleteModalProps) => {
  const { t } = useTranslation()

  return (
    <ReactModal isOpen={isOpen} setAppElement={'#app-body'} overlayClassName={'overlay'} className="modal-dialog modal-element modal-content">
      <div className="modal-body">
        <p>{t(textKey)}</p>
      </div>
      <div className="modal-footer">
        <button onClick={closeModal} type="button" className="btn btn-default " data-bs-dismiss="modal">
          {t('app.cancel')}
        </button>
        <button onClick={onRemove} id="btnRemoveModal" className="btn btn-danger">
          {t('app.delete')}
        </button>
      </div>
    </ReactModal>
  )
}
export default DeleteModal
