import { addMonths, subYears } from 'date-fns'
import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { MemberRegistrationResponse } from '../../../api/models/reports'
import { getMembersRegistrationData } from '../../../api/reports'
import Layout from '../../../components/common/Layout'
import MemberRegistrationReport from '../../../components/reports/MemberRegistrationReport'

export const MemberRegistration = () => {
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState(subYears(new Date(), 1))
  const [endDate, setEndDate] = useState(new Date())
  const [reportData, setReportData] = useState<Array<MemberRegistrationResponse>>([])

  const handleChange = ([newStartDate, newEndDate]: any) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const onSubmitSearch = () => {
    getMembersRegistrationData({ startDate, endDate }).then((data) => {
      setReportData(data)
    })
  }

  useEffect(() => {
    getMembersRegistrationData({}).then((data) => {
      setReportData(data)
    })
  }, [])

  return (
    <Layout>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <h1 className="m-0 text-dark">{t('reports.title', { type: t('reports.member.title') })}</h1>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid pt-5">
          <div className="row justify-content-end">
            <div className="card card-custom card-outline">
              <div className="card-body d-flex justify-content-end align-items-end">
                <div className="col">
                  <label className="text-sm">{t('reports.member.dates_range')}</label>
                  <ReactDatePicker
                    selected={new Date()}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleChange}
                    maxDate={addMonths(new Date(), 1)}
                    dateFormat="MM/yyyy"
                    placeholderText="Select a month other than the disabled months"
                    showMonthYearPicker
                    selectsRange
                    wrapperClassName="flex-none"
                  />
                </div>
                <button className="btn btn-primary btn-sm ml-3" style={{ maxHeight: 40 }} onClick={onSubmitSearch}>
                  {t('filter.search')}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card card-custom card-outline col-12">
              <div className="card-body">
                <MemberRegistrationReport reportData={reportData} height={450} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MemberRegistration
