import { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { PermissionGroup } from '../../api/models/role'
import useAuthContext from '../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'

export const PrivateRouteWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { clubCode: contextClubCode } = useClubConfigContext()
  const { permissions: contextPermission } = useAuthContext()

  const url = useLocation()
  const isListUrl = url.pathname.slice(-1) === 's'
  const urlArray = url.pathname.split('/')
  const model = (isListUrl && urlArray.length === 4) || urlArray.length > 4 ? urlArray[3] : urlArray[2]
  const action = url.pathname.split('/')[4] || url.pathname.split('/')[3]

  const token = localStorage.getItem('token')
  const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions') || '') : contextPermission
  const clubCode = localStorage.getItem('clubCode') || contextClubCode

  if (!token) {
    return <Navigate to={`/${clubCode}/login`} />
  }

  if (Object.values(PermissionGroup).includes(model as PermissionGroup)) {
    if (!permissions?.includes(`${model}.list`) && !permissions?.includes(`${model}.show`)) {
      return <Navigate to={`/${clubCode}/unauthorized`} />
    }
  } else if (Object.values(PermissionGroup).includes(`${model}s` as PermissionGroup)) {
    if (action && !permissions?.includes(`${model}s.${action}`)) {
      return <Navigate to={`/${clubCode}/unauthorized`} />
    }
  }

  return <>{children}</>
}

export default PrivateRouteWrapper
