import { useEffect, useState } from 'react'

import { getAttendanceLabels } from '../../../api/attendance'
import { AttendanceLabelResponse } from '../../../api/models/attendance'
import AttendanceLabelCardInput from './components/AttendanceLabelCardInput'
import AttendanceLabelCardItem from './components/AttendanceLabelCardItem'

interface ParamCardProps {
  title: string
}

export const AttendanceLabelCard = ({ title }: ParamCardProps) => {
  const [editAvailable, setEditAvailable] = useState(false)
  const [elements, setElements] = useState<Array<AttendanceLabelResponse>>([])

  useEffect(() => {
    getAttendanceLabels().then((response) => {
      setElements(response)
    })
  }, [])

  return (
    <div className="col-lg-6 col-md-6 col-sm-12 px-1">
      <div className="card card-custom card-outline">
        <div className="card-header">
          <h5 className="card-title text-bold">{title}</h5>
          <div className="card-tools">
            <a onClick={() => setEditAvailable(!editAvailable)} className="btn btn-tool">
              <i className={`fas ${editAvailable ? 'fa-check' : 'fa-pen'}`}></i>
            </a>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-sm">
            <tbody>
              {elements.map((element: AttendanceLabelResponse) => (
                <AttendanceLabelCardItem key={element.id} {...element} editAvailable={editAvailable} setElements={setElements} />
              ))}
              {editAvailable && <AttendanceLabelCardInput setElements={setElements} />}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AttendanceLabelCard
