export const Footer = () => {
  return (
    <footer className="main-footer">
      <strong>Copyright &copy; 2023 Skermo Club.</strong>
      All rights reserved.
    </footer>
  )
}

export default Footer
