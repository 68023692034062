import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { getSkermoKeys, saveSkermoKeys } from '../../../api/integration'
import { SkermoIntegration } from '../../../api/models/integration'
import CustomCallable from '../../common/CustomCallable'
import CustomToast from '../../common/CustomToast'
import IntegrationInput from './components/IntegrationInput'

export const IntegrationCard = () => {
  const { t } = useTranslation()

  const [editAvailable, setEditAvailable] = useState(false)
  const [integrationKeys, setIntegrationKeys] = useState<SkermoIntegration>({} as SkermoIntegration)

  useEffect(() => {
    getSkermoKeys().then((response) => {
      setIntegrationKeys(response)
    })
  }, [])

  const hideKey = (key: string) => {
    if (!key || !key.length) return ''
    const first = key.slice(0, 4)
    const last = key.slice(-4)
    return `${first}*****************${last}`
  }

  const onSave = () => {
    saveSkermoKeys(integrationKeys)
      .then((response) => {
        if (response.success) {
          toast.custom(<CustomToast text={t('notifications.integration.saved')} />)
          setEditAvailable(false)
        }
      })
      .catch((e) => {
        const error = e?.response?.data

        switch (error?.code) {
          case 40001:
            toast.custom(<CustomToast type="danger" text={t('notifications.integration.not_valid')} />)
            break
        }
      })
  }

  return (
    <div className="card card-custom card-outline col-12">
      <div className="card-header">
        <h5 className="card-title">{t('app.federations')}</h5>
        <div className="card-tools">
          <a onClick={() => setEditAvailable(!editAvailable)} className="btn btn-tool">
            <i className={`fas ${editAvailable ? 'fa-check' : 'fa-pen'}`}></i>
          </a>
        </div>
      </div>
      <div className="card-body">
        <CustomCallable text={t('integration.help')} />
        <div className="d-flex"></div>

        <IntegrationInput
          label={t('integration.public_key')}
          value={editAvailable ? integrationKeys?.publicKey || '' : hideKey(integrationKeys?.publicKey)}
          onChange={(e) => setIntegrationKeys((prev) => ({ ...prev, publicKey: e.target.value }))}
          editAvailable={editAvailable}
        />
        <IntegrationInput
          label={t('integration.secret_key')}
          value={editAvailable ? integrationKeys?.secretKey || '' : hideKey(integrationKeys?.secretKey)}
          onChange={(e) => setIntegrationKeys((prev) => ({ ...prev, secretKey: e.target.value }))}
          editAvailable={editAvailable}
        />
        <div className="row col-12 justify-content-end">
          <button className="btn btn-primary btn-sm" disabled={!editAvailable} onClick={onSave}>
            {t('app.save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default IntegrationCard
