import { Navigate } from 'react-router-dom'

import Layout from '../../components/common/Layout'
import PrivacyFormConfigCard from '../../components/FormsConfiguration/PrivacyFormConfigCard'
import useNotGranted from '../../hooks/useNotGranted'

export const FormsConfiguration = () => {
  const clubCode = useNotGranted('config.forms')

  if (clubCode) {
    return <Navigate to={`/${clubCode}/unauthorized`} />
  }

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid pt-5">
          <PrivacyFormConfigCard />
        </div>
      </section>
    </Layout>
  )
}

export default FormsConfiguration
