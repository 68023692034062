import './styles.css'

import { FormFieldProps } from '../FormControl'

export interface CheckboxProps extends Omit<FormFieldProps, 'value' | 'label'> {
  value?: boolean
}

export const Checkbox = ({ name, disabled = false, value, onChange }: CheckboxProps) => {
  return (
    <div className="icheck-primary d-inline">
      <input type="checkbox" id={name as string} checked={value} onChange={onChange} disabled={disabled} />
      <label htmlFor={name as string}></label>
    </div>
  )
}

export default Checkbox
