import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import useClubConfigContext from '../contexts/hooks/useClubConfigContext'
import ClubConfigWrapper from '../wrappers/ClubConfigWrapper'

export const NotAllowed = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const urlParams = useParams()
  const { logo, basicInfo } = useClubConfigContext()

  return (
    <ClubConfigWrapper>
      <div className="row justify-content-center mt-5 mb-5">
        {logo && <img src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`} alt="logo" className="mb-4" style={{ maxWidth: 250, maxHeight: 75 }} />}
        <div className="d-flex flex-column align-items-center ml-3">
          <p className="mb-6 mb-sm-3 text-lg">{t('accept_privacy.card_title')}</p>
          <p className="mb-4 h4">{basicInfo?.name}</p>
        </div>
      </div>
      <div className="text-center">
        <i className="fa fa-exclamation-circle color-danger text-xl text-center mb-2"></i>
        <h3 className="text-center">{t('accept_privacy.error')}</h3>
        <p>{t('accept_privacy.error_description')}</p>
      </div>
    </ClubConfigWrapper>
  )
}

export default NotAllowed
