import { z } from 'zod'

import { DocumentationType } from '../../api/models/common'
import { Gender, Status } from '../../api/models/member'
import i18n from '../../translations'
import {
  customErrorMap,
  zCountries,
  zLaterality,
  zSpanishDocumentValidation,
  zStringWithoutNumbers,
  zTownCharacters,
  zTutorValidation,
  zValidPhoneNumber
} from './common'
import { zTutorObject } from './member'

z.setErrorMap(customErrorMap)

export const memberSchema = z
  .object({
    number: z.string().optional(),
    name: zStringWithoutNumbers,
    lastname: zStringWithoutNumbers,
    nationality: zCountries,
    documentType: z.nativeEnum(DocumentationType),
    documentValue: z.string().toUpperCase(),
    gender: z.nativeEnum(Gender),
    birthdate: z.date(),
    email: z.string().email(),
    phone: z.string(),
    address: z.string().min(7),
    town: zTownCharacters,
    postalCode: z.string().regex(/^[0-5][0-9]{4}$/),
    laterality: zLaterality,
    country: zCountries,
    status: z.string().refine((value) => value === Status.PENDING, { message: i18n.t('validation.status') }),
    origin: z.coerce.number().optional().nullable(),
    comments: z.string().optional(),
    tutor: z.union([zTutorObject, z.undefined()]),
    tutor2: z.union([zTutorObject, z.undefined()]).nullable().optional(),
    lang: z.string().optional().nullable()
  })
  .superRefine(zValidPhoneNumber)
  .superRefine(zSpanishDocumentValidation)
  .superRefine(zTutorValidation)

export type Member = z.infer<typeof memberSchema>
