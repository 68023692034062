import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { ZodError } from 'zod'

import { createInfoRequest, getInfoRequest, updateInfoRequest } from '../../api/contact'
import { FormErrors } from '../../api/models/common'
import { InfoRequestResponse, InfoRequestStatus } from '../../api/models/contact'
import CustomToast from '../../components/common/CustomToast'
import Layout from '../../components/common/Layout'
import InfoRequestForm from '../../components/forms/InfoRequestForm'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'
import { infoRequestSchema } from '../../helpers/validations/infoRequest'
import { handleValidationErrors } from '../../helpers/validations/validationErrors'
import { getRoute } from '../../router/routes'
import { InfoRequestRouterPaths } from '../../router/routes/infoRequest'
import { InfoRequestFormType } from '../../types'

export const CreateInfoRequest = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clubCode } = useClubConfigContext()

  const urlParams = useParams()
  const infoRequestId = urlParams?.id || false

  const [infoRequest, setInfoRequest] = useState<InfoRequestFormType>({})
  const [errors, setErrors] = useState<FormErrors<InfoRequestResponse>>({})
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    try {
      const validInfoRequest = infoRequestSchema.parse(infoRequest)

      const action = infoRequestId ? updateInfoRequest : createInfoRequest

      action(validInfoRequest as InfoRequestResponse)
        .then((response) => {
          if (response.success) {
            toast.custom(
              <CustomToast
                type="success"
                text={t('notifications.info_request.success', { action: t(`notifications.actions.${infoRequestId ? 'updated' : 'created'}`) })}
              />
            )
            navigate(getRoute(InfoRequestRouterPaths.LIST_INFO_REQUESTS, clubCode))
          }
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error.errors) {
            setErrors(error.errors)
          }
        })
    } catch (error) {
      toast.custom(<CustomToast type="danger" text={t('notifications.form_with_errors')} />)
      const issues = (error as ZodError).issues
      console.log(issues)
      setErrors(handleValidationErrors(issues))
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    if (infoRequestId) {
      setLoading(true)
      getInfoRequest(infoRequestId).then((response) => {
        setInfoRequest(response)
        setLoading(false)
      })
    } else {
      setInfoRequest({
        status: InfoRequestStatus.OPENED
      })
    }
  }, [infoRequestId])

  return (
    <Layout>
      <section className="content">
        <div className="col-12 d-flex justify-content-between align-items-center pt-3">
          <h1 className="pt-3">{infoRequestId ? t('info_request.update') : t('info_request.create')}</h1>
        </div>
        <InfoRequestForm
          infoRequest={infoRequest}
          errors={errors}
          setInfoRequest={setInfoRequest}
          handleSubmit={handleSubmit}
          // setErrors={setErrors}
        />
      </section>
    </Layout>
  )
}

export default CreateInfoRequest
