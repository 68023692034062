import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import { TranslationsRSIProps } from 'react-spreadsheet-import/types/translationsRSIProps'
import { Result, RsiProps } from 'react-spreadsheet-import/types/types'

import { importMemberList } from '../../../api/member'
import { MemberResponse, PaymentData } from '../../../api/models/member'
import CustomToast from '../../../components/common/CustomToast'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import useUIContext from '../../../contexts/hooks/useUIContext'
import { formatInternationalPhone, formatMember } from '../../../helpers/format'
import fields from '../../../helpers/importFields'
import { validateMember } from '../../../helpers/validations/importMember'
import { DEFAULT_LANGUAGE } from '../../../translations'
import es_ES from '../../../translations/react-spreadsheet-import/es_ES'
import styles from './styles'

const translations: Record<string, TranslationsRSIProps> = {
  es_ES
}

export type TutorImportFields = {
  tutorName?: string
  tutorLastname?: string
  tutorDocumentType?: string
  tutorDocumentValue?: string
  tutorEmail?: string
  tutorPhone?: string
  tutorType?: string
}

export const ImportMembersModal = ({ isOpen, onClose }: Partial<RsiProps<string>>) => {
  const { t } = useTranslation()
  const { showErrorModal, showSuccessModal } = useUIContext()
  const {
    basicInfo: { country }
  } = useClubConfigContext()

  const lang = localStorage.getItem('lang') || DEFAULT_LANGUAGE

  const onSubmit = (data: Result<keyof Partial<MemberResponse & TutorImportFields & PaymentData>>, file: File) => {
    // @ts-ignore
    const members: Array<MemberResponse> = data.validData.map((member) => {
      /* eslint-disable */
      const tutor = (member.tutorDocumentValue as string)?.length
        ? {
            name: member.tutorName,
            lastname: member.tutorLastname,
            documentType: member.tutorDocumentType,
            document: member.tutorDocumentValue,
            email: member.tutorEmail,
            phone: formatInternationalPhone(member.tutorPhone as string),
            type: member.tutorType
          }
        : undefined
      /* eslint-enable */

      return {
        ...formatMember(member, lang),
        nationality: member?.nationality || country,
        country: member?.country || country,
        tutor,
        paymentData: {
          iban: member.iban
        }
      }
    })

    importMemberList(members)
      .then((response) => {
        if (response.success && response?.errors?.unique?.length) {
          showErrorModal(
            <div>
              <p>{t('members.import_error')}</p>
              <ul className="list-unstyled">
                {members
                  .filter((member) => response?.errors?.unique.includes(member.documentValue))
                  .map((member) => (
                    <li key={member.documentValue}>{`${member.name} ${member.lastname} (${member.documentValue})`}</li>
                  ))}
              </ul>
            </div>
          )
        }
        if (response.success && response?.data?.totalMembers! > 0) {
          showSuccessModal(
            <div className="d-flex row align-items-center">
              <i className="icon fa fa-check-circle text-success text-xl mr-2"></i>
              <p>{t('members.import_success', { number: response?.data?.totalMembers })}</p>
            </div>
          )
        }
        toast.custom(<CustomToast text={t('notifications.created')} />)
      })
      .catch((error) => {
        console.log(error)
        toast.custom(<CustomToast text={t('notifications.import_error')} type="danger" />)
      })
  }

  return (
    <ReactSpreadsheetImport
      isOpen={isOpen || false}
      onClose={onClose || (() => {})}
      onSubmit={onSubmit}
      fields={fields}
      customTheme={styles}
      translations={translations[lang]}
      isNavigationEnabled
      autoMapSelectValues
      rowHook={validateMember}
    />
  )
}

export default ImportMembersModal
