export interface CustomToastProps {
  type?: 'success' | 'danger' | 'warning' | 'info' | 'orange'
  text: string
}

export const CustomToast = ({ type = 'success', text }: CustomToastProps) => {
  return (
    <div className={`toast bg-${type} fade show`} role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-body">{text}</div>
    </div>
  )
}

export default CustomToast
