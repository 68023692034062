import { MemberResponse } from '../api/models/member'
import i18n from '../translations'

export const mapFields = (member: MemberResponse) => {
  const data: Record<string, string | number | undefined> = {
    number: member.number,
    name: member.name,
    lastname: member.lastname,
    documentValue: member.documentValue,
    documentType: member.documentType,
    gender: i18n.t(`members.${member.gender}`),
    address: member.address,
    town: member.town,
    postalCode: member.postalCode,
    country: i18n.t(`common.countries.${member.country}`),
    email: member.email,
    phone: member.phone,
    email2: member.email2,
    phone2: member.phone2,
    nationality: i18n.t(`common.countries.${member.nationality}`),
    birthdate: new Date(member.birthdate).toLocaleDateString(),
    weapon: member.weapon ? i18n.t(`common.weapon.${member.weapon}`) : '',
    laterality: member.laterality ? i18n.t(`common.laterality.${member.laterality}`) : '',
    status: i18n.t(`members.status.${member.status}`),
    createdAt: member.createdAt ? new Date(member.createdAt).toLocaleDateString() : '',
    suspendedAt: member.suspendedAt ? new Date(member.suspendedAt).toLocaleDateString() : '',
    suspendedReason: member.suspededReasonLabel || '',
    type: member.typeLabel || '',
    'paymentData.iban': member.paymentData?.iban,
    comments: member.comments,
    federationId: member.federationId,
    lang: member.lang,
    origin: member.originLabel || '',
    groups: member.groups?.map((group) => group.name).join(', ')
  }

  if (member.tutors?.length) {
    const tutor = member.tutors[0]
    const tutor2 = member.tutors[1]

    data['tutor.name'] = tutor.name
    data['tutor.lastname'] = tutor.lastname
    data['tutor.email'] = tutor.email
    data['tutor.phone'] = tutor.phone
    data['tutor.document'] = tutor.document
    data['tutor.documentType'] = tutor.documentType
    data['tutor.type'] = i18n.t(`members.tutors_type.${tutor.type}`)

    if (tutor2) {
      data['tutor2.name'] = tutor2.name
      data['tutor2.lastname'] = tutor2.lastname
      data['tutor2.email'] = tutor2.email
      data['tutor2.phone'] = tutor2.phone
      data['tutor2.document'] = tutor2.document
      data['tutor2.documentType'] = tutor2.documentType
      data['tutor2.type'] = i18n.t(`members.tutors_type.${tutor2.type}`)
    }
  }

  return data
}
