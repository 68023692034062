import { Option } from '../../api/models/club'
import { Country, DocumentationType, FieldType, FormSectionElement, Laterality, TutorType, Weapon } from '../../api/models/common'
import { Gender, MemberResponse, PaymentData, Status, Tutor } from '../../api/models/member'
import { AVAILABLE_LANGUAGES } from '../../translations'

export interface MemberFormConfig {
  originOptions?: Option[]
  suspendedReasonOptions?: Option[]
  memberTypeOptions?: Option[]
  status?: Status
  age?: number
  showBankAccount?: boolean
  disabled?: boolean
}

export const sectionConfig: (_: MemberFormConfig) => FormSectionElement<MemberResponse & PaymentData & Tutor>[] = ({
  originOptions = [],
  suspendedReasonOptions = [],
  memberTypeOptions = [],
  status,
  age = 0,
  showBankAccount,
  disabled = false
}) => {
  return [
    {
      title: 'members.basic_information',
      includeNumber: true,
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          required: true,
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname',
          required: true,
          disabled
        },
        {
          type: FieldType.INPUT_GROUP,
          name: 'documentValue',
          nameSelect: 'documentType',
          label: 'label.document',
          options: Object.values(DocumentationType).map((documentType) => ({ value: documentType, label: 'register.' + documentType.toLowerCase() })),
          required: true,
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'gender',
          label: 'label.gender',
          options: Object.values(Gender).map((gender) => ({ value: gender, label: 'members.' + gender })),
          required: true,
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          required: true,
          disabled
        },
        {
          type: FieldType.PHONE,
          name: 'phone',
          label: 'label.phone',
          required: true,
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'email2',
          label: 'label.email_2',
          disabled
        },
        {
          type: FieldType.PHONE,
          name: 'phone2',
          label: 'label.phone_2',
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'nationality',
          label: 'members.nationality',
          options: Object.keys(Country).map((country) => ({ value: country, label: 'common.countries.' + country })),
          disabled
        },
        {
          type: FieldType.DATE,
          name: 'birthdate',
          label: 'label.birthdate',
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'weapon',
          label: 'label.weapon',
          options: Object.keys(Weapon).map((weapon) => ({ value: weapon, label: 'common.weapon.' + weapon })),
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'laterality',
          label: 'members.laterality',
          options: Object.keys(Laterality).map((laterality) => ({ value: laterality, label: 'common.laterality.' + laterality })),
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'status',
          label: 'label.status',
          options: Object.values(Status).map((status) => ({ value: status, label: 'members.status.' + status })),
          required: true,
          disabled
        },
        {
          type: FieldType.DATE,
          name: 'createdAt',
          label: 'label.created_at',
          disabled
        },
        {
          type: FieldType.DATE,
          name: 'suspendedAt',
          label: 'label.suspended_date',
          disabled: status !== Status.SUSPENDED && disabled
        },
        {
          type: FieldType.SELECT,
          name: 'suspendedReason',
          label: 'label.suspended_reason',
          options: suspendedReasonOptions,
          disabled: status !== Status.SUSPENDED && disabled
        },
        {
          type: FieldType.SELECT,
          name: 'type',
          label: 'members.type',
          options: memberTypeOptions,
          disabled
        },
        {
          type: FieldType.IMAGE,
          name: 'photo',
          label: 'label.photo',
          disabled
        }
      ]
    },
    {
      title: 'members.address_info',
      startCollapsed: true,
      fields: [
        {
          type: FieldType.TEXT,
          name: 'address',
          label: 'label.address',
          required: true,
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'town',
          label: 'label.town',
          required: true,
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'postalCode',
          label: 'label.postal_code',
          required: true,
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'country',
          label: 'label.country',
          options: Object.keys(Country).map((country) => ({ value: country, label: 'common.countries.' + country })),
          required: true,
          disabled
        }
      ]
    },
    {
      title: 'members.tutors_info',
      startCollapsed: true,
      conditionalHide: age >= 18,
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          objectKey: 'tutor',
          disabled
        },

        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname',
          objectKey: 'tutor',
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          objectKey: 'tutor',
          disabled
        },
        {
          type: FieldType.INPUT_GROUP,
          name: 'document',
          nameSelect: 'documentType',
          label: 'label.document',
          options: Object.values(DocumentationType).map((documentType) => ({ value: documentType, label: 'register.' + documentType.toLowerCase() })),
          objectKey: 'tutor',
          disabled
        },
        {
          type: FieldType.PHONE,
          name: 'phone',
          label: 'label.phone',
          objectKey: 'tutor',
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'type',
          label: 'members.tutors.type',
          options: Object.values(TutorType).map((tutorType) => ({ value: tutorType, label: 'members.tutors_type.' + tutorType })),
          objectKey: 'tutor',
          disabled
        }
      ]
    },
    {
      title: 'members.tutors_info_2',
      startCollapsed: true,
      conditionalHide: age >= 18,
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          objectKey: 'tutor2',
          disabled
        },

        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname',
          objectKey: 'tutor2',
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          objectKey: 'tutor2',
          disabled
        },
        {
          type: FieldType.INPUT_GROUP,
          name: 'document',
          nameSelect: 'documentType',
          label: 'label.document',
          options: Object.values(DocumentationType).map((documentType) => ({ value: documentType, label: 'register.' + documentType.toLowerCase() })),
          objectKey: 'tutor2',
          disabled
        },
        {
          type: FieldType.PHONE,
          name: 'phone',
          label: 'label.phone',
          objectKey: 'tutor2',
          disabled
        },
        {
          type: FieldType.SELECT,
          name: 'type',
          label: 'members.tutors.type',
          options: Object.values(TutorType).map((tutorType) => ({ value: tutorType, label: 'members.tutors_type.' + tutorType })),
          objectKey: 'tutor2',
          disabled
        }
      ]
    },
    {
      title: 'members.payment_info',
      startCollapsed: true,
      conditionalHide: !showBankAccount,
      fields: [
        //     {
        //       type: FieldType.SELECT,
        //       name: 'paymentMethod',
        //       label: 'members.payment_method',
        //       options: Object.keys(PaymentMethod).map((method) => ({ value: method, label: 'common.payment_method.' + method })),
        //       objectKey: 'paymentData'
        //     },
        //     {
        //       type: FieldType.TEXT,
        //       name: 'titular',
        //       label: 'members.titular',
        //       objectKey: 'paymentData'
        //     },
        {
          type: FieldType.TEXT,
          name: 'iban',
          label: 'members.iban',
          objectKey: 'paymentData',
          disabled
        }
        //     {
        //       type: FieldType.TEXT,
        //       name: 'bic',
        //       label: 'members.bic',
        //       objectKey: 'paymentData'
        //     }
      ]
    },
    {
      title: 'members.extra_info',
      startCollapsed: true,
      fields: [
        {
          type: FieldType.SELECT,
          name: 'origin',
          label: 'members.origin',
          options: originOptions,
          disabled
        },
        {
          type: FieldType.TEXT,
          name: 'federationId',
          label: 'members.federation_id',
          disabled: true
        },
        {
          type: FieldType.SELECT,
          name: 'lang',
          label: 'members.language',
          options: Object.keys(AVAILABLE_LANGUAGES).map((language) => ({ value: language, label: 'common.languages.' + language })),
          disabled
        },
        {
          type: FieldType.TEXTAREA,
          name: 'comments',
          label: 'label.observations',
          disabled
        }
      ]
    }
  ]
}
