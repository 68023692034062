import { isValidPhoneNumber } from 'react-phone-number-input'
import { RefinementCtx, z } from 'zod'

import { Country, DocumentationType, Laterality, TutorType, Weapon } from '../../api/models/common'
import { Status } from '../../api/models/member'
import i18n from '../../translations'
import { isValidIBANNumber } from './ibanValidator'
import { DNIValidation, NIEValidation } from './locale/spanishDocuments'

const tutorFields = ['name', 'lastname', 'documentType', 'document', 'email', 'phone']

export function getZodEnumFromObjectKeys<TI extends Record<string, any>, R extends string = TI extends Record<infer R, any> ? R : never>(
  input: TI
): z.ZodEnum<[R, ...R[]]> {
  const [firstKey, ...otherKeys] = Object.keys(input) as [R, ...R[]]
  return z.enum([firstKey, ...otherKeys])
}

export const zTutorString = z.string({
  required_error: i18n.t('validation.required_tutor'),
  invalid_type_error: i18n.t('validation.required_tutor')
})

export const zTutorStringNames = zTutorString.regex(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s'.-]*$/, { message: i18n.t('validation.no_numbers') })

export const zStringWithoutNumbers = z.string().regex(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s'.-]*$/, { message: i18n.t('validation.no_numbers') })
export const zTownCharacters = z.string().regex(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s'.()-]*$/, { message: i18n.t('validation.no_numbers') })

export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type && issue.received === 'undefined') {
    return { message: i18n.t('validation.required') }
  }
  if (issue.code === z.ZodIssueCode.invalid_string && issue.validation === 'email') {
    return { message: i18n.t('validation.email') }
  }
  if (issue.code === z.ZodIssueCode.invalid_string && issue.validation === 'regex') {
    return { message: i18n.t('validation.invalid_format') }
  }
  if (issue.code === z.ZodIssueCode.too_small) {
    return { message: i18n.t('validation.gte.string', { value: issue.minimum }) }
  }

  return { message: ctx.defaultError }
}

export const zCountries = getZodEnumFromObjectKeys(Country)
export const zLaterality = getZodEnumFromObjectKeys(Laterality)
export const zWeapon = getZodEnumFromObjectKeys(Weapon)
export const zStatus = z.nativeEnum(Status)
export const zTutorType = z.nativeEnum(TutorType)

export const zValidPhoneNumber = (data: any, ctx: RefinementCtx) => {
  if (!isValidPhoneNumber(data.phone)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t('validation.invalid_format'),
      path: ['phone']
    })
  }
  if (data.phone2 && !isValidPhoneNumber(data.phone2)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t('validation.invalid_format'),
      path: ['phone2']
    })
  }
  if (data.tutor?.phone && !isValidPhoneNumber(data.tutor.phone)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t('validation.invalid_format'),
      path: ['tutor', 'phone']
    })
  }

  if (data.tutor2?.phone && !isValidPhoneNumber(data.tutor2.phone)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t('validation.invalid_format'),
      path: ['tutor2', 'phone']
    })
  }
}

export const zIBANValidation = async (data: any, ctx: RefinementCtx) => {
  if (data.paymentData?.iban) {
    const validIBAN = await isValidIBANNumber(data.paymentData.iban)
    if (!validIBAN.valid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t('validation.invalid_iban'),
        path: ['paymentData', 'iban']
      })
    }
  }
}

export const zTutorValidation = (data: any, ctx: RefinementCtx) => {
  const validation =
    data.birthdate.getFullYear() + 18 < new Date().getFullYear() ||
    (data.birthdate.getFullYear() + 18 > new Date().getFullYear() && data.tutor !== undefined && data.tutor !== null)
  if (!validation) {
    tutorFields.forEach((field) => {
      if (!data.tutor || (data.tutor && !data.tutor[field as keyof typeof data.tutor])) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t('validation.required_tutor'),
          path: ['tutor', field]
        })
      }
    })
  }
}

export const zSpanishDocumentValidation = (data: any, ctx: RefinementCtx) => {
  if (data.nationality !== 'ES' && ![DocumentationType.NIE, DocumentationType.PASSPORT].includes(data.documentType)) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t('validation.foreign_document_type'),
      path: ['documentValue']
    })
  }
  if (
    (data.documentType === DocumentationType.DNI && !DNIValidation(data.documentValue)) ||
    (data.documentType === DocumentationType.NIE && !NIEValidation(data.documentValue))
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t('validation.dni'),
      path: ['documentValue']
    })
  }
}
