import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { InfoRequestResponse } from '../../../api/models/contact'
import { getRoute } from '../../../router/routes'
import { InfoRequestRouterPaths } from '../../../router/routes/infoRequest'
import { icon, statusBackgroundColor } from '../../../utils/infoRequest'

export const InfoRequestFragment = ({ id, name, origin, status, message }: InfoRequestResponse) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { clubCode } = useParams<{ clubCode: string }>()

  return (
    <a
      onClick={() => {
        navigate(getRoute(InfoRequestRouterPaths.EDIT_INFO_REQUEST.replace(':id', id?.toString()!), clubCode))
      }}
      className="dropdown-item cursor-pointer"
    >
      <div className="row justify-content-between align-items-center">
        <div className="d-flex pr-3 text-break text-wrap flex-1 text-sm align-items-center justify-content-between">
          <div className="d-flex flex-column">
            <p className="text-bold mb-0">{name}</p>
            <p className="text-muted mb-0">{`${message.substring(0, 100)}${message.length > 100 ? '...' : ''}`}</p>
          </div>
          <div className="row align-items-center justify-content-between" style={{ width: 170, maxWidth: 170, minWidth: 170 }}>
            <i
              className={`fas ${icon[origin] || 'fa-envelope'} mx-3 text-xl`}
              style={{ color: 'silver' }}
              data-tooltip-id={`tooltip-origin-${id}`}
            ></i>
            <Tooltip id={`tooltip-origin-${id}`} place="bottom" className="p-1 rounded bg-dark" style={{ zIndex: 999 }} clickable noArrow>
              {t(`info_request.origin.${origin}`)}
            </Tooltip>
            <div className={`badge ${statusBackgroundColor[status]} `} style={{ width: 100, maxWidth: 100 }}>
              {t(`info_request.status.${status}`)}
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default InfoRequestFragment
