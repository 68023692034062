export const EditRound = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 122.88 122.88" width={16} height={16}>
        <path
          d="M61.44 0c33.93 0 61.44 27.51 61.44 61.44 0 33.93-27.51 61.44-61.44 61.44S0 95.37 0 61.44C0 27.51 27.51 0 61.44 0zm-8.88 84c-1.82.61-3.68 1.17-5.5 1.77-1.82.61-3.64 1.21-5.5 1.82-4.34 1.4-6.71 2.19-7.23 2.33-.51.14-.19-1.86.89-6.06l3.45-13.19 26.01-27.04 13.85 13.33L52.56 84zm25.92-50.16c-.65-.61-1.4-.93-2.24-.89-.84 0-1.59.33-2.19.98l-4.94 5.13 13.85 13.38 4.99-5.22c.61-.61.84-1.4.84-2.24 0-.84-.33-1.63-.93-2.19l-9.38-8.95z"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd'
          }}
          fill="#FFA500"
        />
      </svg>
    </>
  )
}
