import { MemberAttendanceFrangment } from '@skermo/api/models/memberAttendance'
import { useTranslation } from 'react-i18next'

import { isDevice } from '../../../helpers/layout'

export const MemberAttendanceItem = ({ actualMonth, actualSeason, previousMonth, previousSeason, name, color }: MemberAttendanceFrangment) => {
  const { t } = useTranslation()

  return (
    <div className="border w-100 d-flex flex-column">
      <div className={`border py-1 px-3 font-weight-bolder opacity-80 bg-${color}`}>{name}</div>
      <div className={`d-flex ${isDevice ? 'flex-column' : 'flex-row'}`}>
        <div className={`${isDevice ? 'col-12' : 'col-3'} border d-flex flex-row justify-content-between py-1 px-3`}>
          <p className="mb-0">{t('member_attendance.actual_season')}</p>
          <p className="mb-0 pr-1 font-weight-bold">{actualSeason}</p>
        </div>
        <div className={`${isDevice ? 'col-12' : 'col-3'} border d-flex flex-row justify-content-between py-1 px-3`}>
          <p className="mb-0">{t('member_attendance.actual_month')}</p>
          <p className="mb-0 pr-1 font-weight-bold">{actualMonth}</p>
        </div>
        <div className={`${isDevice ? 'col-12' : 'col-3'} border d-flex flex-row justify-content-between py-1 px-3`}>
          <p className="mb-0">{t('member_attendance.previous_season')}</p>
          <p className="mb-0 pr-1 font-weight-bold">{previousSeason}</p>
        </div>
        <div className={`${isDevice ? 'col-12' : 'col-3'} border d-flex flex-row justify-content-between py-1 px-3`}>
          <p className="mb-0">{t('member_attendance.previous_month')}</p>
          <p className="mb-0 pr-1 font-weight-bold">{previousMonth}</p>
        </div>
      </div>
    </div>
  )
}

export default MemberAttendanceItem
