import { createContext, ReactNode, useEffect, useReducer, useState } from 'react'

import { getSkermoKeys } from '../api/integration'
import { AuthResponse } from '../api/models/auth'
import { Module } from '../api/models/permission'
import { Notification, UserResponse } from '../api/models/user'
import { getUserInfo } from '../api/user'
import authReducer, { AuthActionType } from '../reducers/auth'
import { setLanguage } from '../translations'
import { NO_CLUB_CODE } from './ClubConfigContext'

interface IAuthContext {
  token?: string
  user?: UserResponse
  modules?: Module[]
  isMasterUser?: boolean
  permissions?: string[]
  showFederationLink?: boolean
  notifications?: Notification[]
  setToken: (_: string) => void
  saveUserInfo: (_: AuthResponse) => void
  setNotifications: (_: Notification[]) => void
  cleanAuth: () => void
}

export const AuthContext = createContext<IAuthContext>({
  setToken: () => {},
  saveUserInfo: () => {},
  cleanAuth: () => {},
  setNotifications: () => {}
})

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const authInitialState = {}
  const [authState, dispatch] = useReducer(authReducer, authInitialState)

  const [showFederationLink, setShowFederationLink] = useState<boolean>(false)

  const saveUserInfo = ({ token }: AuthResponse) => {
    if (!token) return
    localStorage.setItem('token', token)
    dispatch({ type: AuthActionType.SET_TOKEN, payload: token })
    getUserInfo().then((user) => {
      if (user?.lang && !window.location.pathname.includes('preinscription')) setLanguage(user.lang) // not translate when user is logging but visiting preinscription page
      if (user?.modules) localStorage.setItem('userModules', JSON.stringify(user?.modules))
      localStorage.setItem('userInfo', JSON.stringify({ name: user.name, lastname: user.lastname }))
      if (user?.permissions) localStorage.setItem('permissions', JSON.stringify(user.permissions))
      dispatch({ type: AuthActionType.SET_USER, payload: user })
    })
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    saveUserInfo({ token: token! })

    if (token) {
      getSkermoKeys().then((response) => {
        if (response?.publicKey?.length && response?.secretKey?.length) setShowFederationLink(true)
      })
    }
  }, [])

  const modules = localStorage.getItem('userModules') ? JSON.parse(localStorage.getItem('userModules') || '') : authState.user?.modules || []

  return (
    <AuthContext.Provider
      value={{
        token: authState.token,
        user: authState.user,
        notifications: authState.user?.notifications,
        modules,
        showFederationLink,
        permissions: authState.user?.permissions,
        isMasterUser: authState.user?.clubCode === NO_CLUB_CODE,
        setToken: (token: string) => {
          dispatch({ type: AuthActionType.SET_TOKEN, payload: token })
        },
        saveUserInfo,
        cleanAuth: () => {
          dispatch({ type: AuthActionType.CLEAN_AUTH })
        },
        setNotifications: (notifications: Notification[]) => {
          dispatch({ type: AuthActionType.SET_NOTIFICATIONS, payload: notifications })
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
