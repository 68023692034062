import { useState } from 'react'

export const useShowModal = () => {
  const [isModalShown, setShowModal] = useState<boolean>(false)

  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)
  const toggleShowModal = () => setShowModal(!isModalShown)

  return {
    isModalShown,
    setShowModal,
    closeModal,
    openModal,
    toggleShowModal
  }
}

export default useShowModal
