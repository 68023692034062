import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import { isDevice } from '../../../helpers/layout'

interface PreinscriptionHeaderProps {
  title?: string
}

export const PreinscriptionHeader = ({ title }: PreinscriptionHeaderProps) => {
  const { t } = useTranslation()
  const { logo, basicInfo } = useClubConfigContext()

  const ref = useRef<HTMLDivElement>(null)
  const [maxWidth, setMaxWidth] = useState<number | string>('80%')

  useEffect(() => {
    setMaxWidth(ref?.current && ref.current.offsetWidth * 0.8 > 250 ? 250 : '80%')
  }, [ref.current])

  return (
    <div ref={ref}>
      {logo && (
        <img
          src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`}
          alt="logo"
          className="mb-4"
          style={{ maxWidth, maxHeight: isDevice ? 75 : 'none' }}
        />
      )}
      <p className="mb-6 mb-sm-3 text-lg">{title}</p>
      <p className={`mb-4 ${isDevice ? 'h4' : 'h2'}`}>{basicInfo?.name}</p>
      <div className="mb-4">
        <p className="lead mb-sm-1">{`NIF: ${basicInfo.nif}`}</p>
        <p className="lead mb-sm-1">{t('clubs.address_social')}:</p>
        {basicInfo.address && <p className="lead mb-sm-1">{basicInfo.address}</p>}
        {basicInfo.town && <p className="lead mb-sm-1">{basicInfo.town}</p>}
        <a href={`mailto:${basicInfo?.email}`} className="lead mb-sm-1 preinscription-link">
          {basicInfo?.email}
        </a>
        <p className="lead mb-sm-1">{basicInfo?.phone}</p>
        {basicInfo?.webUrl && (
          <a href={basicInfo.webUrl} target="_blank" className="lead m-sm-1 mt-lg-2 preinscription-link" rel="noreferrer">
            {basicInfo?.webUrl}
          </a>
        )}
      </div>
    </div>
  )
}

export default PreinscriptionHeader
