import { Option } from '../../api/models/club'
import { FieldType, FormSectionElement } from '../../api/models/common'
import { UserResponse } from '../../api/models/user'
import { AVAILABLE_LANGUAGES } from '../../translations'

type UserFormConfig = {
  roleOptions: Option[]
  clubOptions: Option[]
  isMasterUser?: boolean
}

export const addUserForm: (_: UserFormConfig) => FormSectionElement<UserResponse & { password?: string; club?: number }>[] = ({
  roleOptions,
  clubOptions,
  isMasterUser = false
}) => {
  return [
    {
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name'
        },
        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname'
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'phone',
          label: 'label.phone'
        },
        {
          type: FieldType.SELECT,
          name: 'club',
          label: 'label.club',
          options: clubOptions,
          required: true,
          disabled: !isMasterUser
        },
        {
          type: FieldType.SELECT,
          name: 'role',
          label: 'label.role',
          options: roleOptions,
          required: true
        },
        {
          type: FieldType.SELECT,
          name: 'lang',
          label: 'profile.language',
          options: Object.keys(AVAILABLE_LANGUAGES).map((language) => ({ value: language, label: 'common.languages.' + language })),
          required: true
        },
        {
          type: FieldType.PASSWORD,
          name: 'password',
          label: 'label.password',
          required: true,
          placeholder: '********'
        }
      ]
    }
  ]
}
