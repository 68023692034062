import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MemberResponse } from '../../../api/models/member'
import { PermissionGroup } from '../../../api/models/role'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { getRoute } from '../../../router/routes'
import { MemberRouterPaths } from '../../../router/routes/member'

export const MemberListItem = (member: MemberResponse) => {
  const { id, name, lastname, status, birthdate, category, age, weapon, createdAt } = member
  const { t } = useTranslation()

  const { permissions } = useAuthContext()
  const clubCode = useParams<{ clubCode: string }>().clubCode

  return (
    <tr>
      <td>{name}</td>
      <td className="fit">{lastname}</td>
      <td className="text-right">{new Date(birthdate).toLocaleDateString()}</td>
      <td className="text-center">{age}</td>
      <td className="text-center">{category}</td>
      <td>{weapon ? t(`common.weapon.${weapon}`) : ''}</td>
      <td>{t(`members.status.${status}`)}</td>
      <td className="text-right">{new Date(createdAt!).toLocaleDateString()}</td>
      <td className="fit">
        {permissions?.includes(`${PermissionGroup.MEMBER}.edit`) && (
          <a href={getRoute(MemberRouterPaths.EDIT_MEMBER, clubCode).replace(':id', id?.toString()!)} className="btn btn-primary btn-xs mr-1">
            <span className="fas fa-edit"></span> {t('app.edit')}
          </a>
        )}
        {permissions?.includes(`${PermissionGroup.MEMBER}.list`) && !permissions?.includes(`${PermissionGroup.MEMBER}.edit`) && (
          <a href={getRoute(MemberRouterPaths.SHOW_MEMBER, clubCode).replace(':id', id?.toString()!)} className="btn btn-primary btn-xs mr-1">
            <span className="fas fa-eye"></span> {t('app.show')}
          </a>
        )}
      </td>
    </tr>
  )
}

export default MemberListItem
