import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { components, OptionProps, SingleValueProps } from 'react-select'

import { Option } from '../../../../api/models/club'
import { InfoRequestOrigin } from '../../../../api/models/contact'
import { InfoRequestFormType } from '../../../../types'
import { icon } from '../../../../utils/infoRequest'
import FormControl from '../../components/FormControl'
import { originCustomStyles } from './styles'

export type IconOption = Option & { icon: string }

export interface StatusSelectProps {
  infoRequest: InfoRequestFormType
  setInfoRequest: Dispatch<SetStateAction<Partial<InfoRequestFormType>>>
}

export const OriginSelect = ({ infoRequest, setInfoRequest }: StatusSelectProps) => {
  const { t } = useTranslation()

  const options = Object.values(InfoRequestOrigin).map((origin) => ({
    value: origin,
    label: t(`info_request.origin.${origin}`),
    icon: icon[origin as InfoRequestOrigin]
  }))

  const Option = ({ children, ...props }: OptionProps<IconOption>) => {
    return (
      <components.Option {...props}>
        <div className={'row w-100 p-2 align-items-center'}>
          <i className={`fa ${props.data.icon}`} style={{ color: 'gray', margin: 5, fontSize: 18 }}></i>
          <div>{children}</div>
        </div>
      </components.Option>
    )
  }

  const SingleValue = ({ children, ...props }: SingleValueProps<IconOption>) => (
    <components.SingleValue {...props}>
      <i className={`fa ${props.data.icon}`} style={{ color: 'gray', margin: 5, fontSize: 18 }}></i>
      {children}
    </components.SingleValue>
  )
  return (
    <FormControl
      label={t('label.origin')}
      columnSize={6}
      required={true}
      // error={error}
    >
      <ReactSelect
        menuPortalTarget={document.body}
        value={infoRequest.origin ? options.find((option) => option.value === infoRequest.origin) : null}
        options={options}
        components={{ Option, SingleValue }}
        styles={originCustomStyles}
        onChange={(selected) => {
          // @ts-ignore
          setInfoRequest((prev) => ({ ...prev, origin: selected?.value }))
        }}
      />
    </FormControl>
  )
}

export default OriginSelect
