import { useTranslation } from 'react-i18next'

import Layout from '../components/common/Layout'
import NotificationFragment from '../components/menu/NotificationFragment'
import useNotifications from '../hooks/useNotifications'

export const Notifications = () => {
  const { t } = useTranslation()
  const { notifications } = useNotifications()

  return (
    <Layout>
      <section className="content">
        <div className="px-4">
          <h1 className="pt-3">{t('menu.notifications')}</h1>
        </div>
        <div className="container-fluid pt-5">
          <div className="row">
            {notifications?.map((notification) => (
              <div
                key={notification.id}
                className={`row border rounded m-2 p-2 w-100 bg-white ${notification.readAt === null ? 'border-primary bg-unread shadow-sm' : ''}`}
              >
                <NotificationFragment {...notification} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Notifications
