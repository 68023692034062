import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelect, Option } from 'react-multi-select-component'

import { getGroups } from '../../../api/group'
import { GroupResponse } from '../../../api/models/group'
import { MemberResponse } from '../../../api/models/member'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import { getRoute, RouterPaths } from '../../../router/routes'
import translations from '../../../translations/es_ES.json'
import FormSectionHeader from '../../forms/components/FormSectionHeader'

export interface MemberGroupsListProps {
  groups: GroupResponse[]
  setMember: Dispatch<SetStateAction<Partial<MemberResponse>>>
}

export const MemberGroupsList = ({ groups, setMember }: MemberGroupsListProps) => {
  const { t } = useTranslation()
  const { clubCode } = useClubConfigContext()

  const [allGroups, setAllGroups] = useState<Array<GroupResponse>>([])

  const groupOptions = allGroups?.map((group) => ({ value: group.id?.toString() || '', label: group.name }))

  const selectedGroups = groupOptions.filter((group) => groups.map((gr) => gr.id?.toString()).includes(group.value))

  useEffect(() => {
    getGroups().then((response) => setAllGroups(response))
  }, [])

  return (
    <div className="card">
      <FormSectionHeader title={`${t('menu.groups')} (${groups?.length || 0})`} />
      <div className="col-6 p-3">
        <MultiSelect
          labelledBy="Select"
          options={groupOptions}
          overrideStrings={translations.forms.multiselect}
          onChange={(groupsSelected: Option[]) => {
            setMember((prev: Partial<MemberResponse>) => ({
              ...prev,
              groups: allGroups.filter((group) => groupsSelected.map((group) => parseInt(group.value)).includes(group?.id || 0))
            }))
          }}
          value={selectedGroups}
        />
      </div>
      <div className="card-body">
        {groups?.map((group, key) => (
          <a
            href={`/${clubCode}/${getRoute(RouterPaths.EDIT_GROUP.replace(':id', group.id?.toString() || ''))}`}
            className="badge badge-info mr-2"
            key={key}
          >
            {group.name}
          </a>
        ))}
      </div>
    </div>
  )
}

export default MemberGroupsList
