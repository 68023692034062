import { z } from 'zod'

import { InfoRequestOrigin, InfoRequestStatus } from '../../api/models/contact'
import { zStringWithoutNumbers } from './common'

export const infoRequestSchema = z.object({
  id: z.number().optional(),
  name: zStringWithoutNumbers,
  email: z.string().email(),
  phone: z
    .string()
    .regex(/^\d{9}$/)
    .optional()
    .nullable(),
  message: z.string().min(7),
  origin: z.nativeEnum(InfoRequestOrigin),
  status: z.nativeEnum(InfoRequestStatus),
  newComment: z
    .object({
      comment: z.string().min(7),
      author: z.string().optional()
    })
    .optional()
})

export type InfoRequest = z.infer<typeof infoRequestSchema>
