import './styles.css'

import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MemberQueryRequest } from '../../../api/models/member'
import { PermissionGroup } from '../../../api/models/role'
import { ExportMembersModal } from '../../../components/modals/ExportMembersModal'
import ImportMembersModal from '../../../components/modals/ImportMembersModal'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { isDevice } from '../../../helpers/layout'
import useShowModal from '../../../hooks/useShowModal'
import { getRoute, RouterPaths } from '../../../router/routes'
import { MemberRouterPaths } from '../../../router/routes/member'

type PermissionGroupsButtons = Exclude<PermissionGroup, PermissionGroup.ACTIVITY | PermissionGroup.REPORT | PermissionGroup.COMMERCIAL_MANAGEMENT>

interface CardHeaderProps {
  title: string
  group?: PermissionGroupsButtons
  filter?: MemberQueryRequest
  hideDeviceActionButton?: boolean
  setShowFilter?: Dispatch<SetStateAction<boolean>>
}

export const CardHeader = ({ title, group, filter, hideDeviceActionButton = false, setShowFilter }: CardHeaderProps) => {
  const { t } = useTranslation()
  const { permissions } = useAuthContext()
  const { isModalShown, closeModal, openModal } = useShowModal()
  const { isModalShown: isExportModalShown, closeModal: closeExportModal, openModal: openExportModal } = useShowModal()

  const clubCode = useParams<{ clubCode: string }>().clubCode

  const [showButtons, setShowButtons] = useState(!isDevice)

  const buttons: Record<PermissionGroupsButtons, { text: string; link: string; icon?: string }> = {
    [PermissionGroup.CLUB]: {
      text: 'clubs.add_club',
      link: getRoute(RouterPaths.ADD_CLUB)
    },
    [PermissionGroup.ROLE]: {
      text: 'roles.add_role',
      link: getRoute(RouterPaths.ADD_ROLE)
    },
    [PermissionGroup.USER]: {
      icon: 'fa-user-lock',
      text: 'users.add_user',
      link: getRoute(RouterPaths.ADD_USER)
    },
    [PermissionGroup.GROUP]: {
      text: 'groups.add_group',
      link: getRoute(RouterPaths.ADD_GROUP)
    },
    [PermissionGroup.MEMBER]: {
      icon: 'fa-user-plus',
      text: 'members.add_member',
      link: getRoute(MemberRouterPaths.ADD_MEMBER, clubCode)
    }
  }

  const getButton = (group?: PermissionGroupsButtons) => {
    return group ? buttons[group] : null
  }

  const button = getButton(group)
  return (
    <div className="card-header">
      <h3 className="card-title">{title}</h3>

      {isDevice && !hideDeviceActionButton && (
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center float-right ml-2"
          style={{ width: 40, height: 40 }}
          onClick={() => setShowButtons(!showButtons)}
        >
          <i className="fa fa-ellipsis-h text-lg"></i>
        </button>
      )}
      {isDevice && setShowFilter && (
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center float-right"
          style={{ width: 40, height: 40 }}
          onClick={() => setShowFilter(true)}
        >
          <i className="fa fa-filter text-lg"></i>
        </button>
      )}
      <div className={isDevice ? `row w-100 dropdown-menu px-2 ${showButtons ? 'd-flex' : ''}` : ''}>
        {group === PermissionGroup.MEMBER && (
          <>
            <button className="btn btn-primary float-right ml-2" onClick={openExportModal}>
              <i className="fa fa-file-export mr-2"></i>
              {t('files.export')}
            </button>
            <button className="btn btn-primary float-right ml-2" onClick={openModal}>
              <i className="fa fa-file-excel mr-2"></i>
              {t('members.import')}
            </button>
          </>
        )}
        {button && permissions?.includes(`${group}.create`) && (
          <a href={button.link} className="float-right btn btn-primary ml-2">
            {button.icon && <i className={`fa ${button.icon} mr-2`}></i>}
            {t(button.text)}
          </a>
        )}
      </div>
      {group === PermissionGroup.MEMBER && <ExportMembersModal isOpen={isExportModalShown} closeModal={closeExportModal} filter={filter} />}
      <ImportMembersModal isOpen={isModalShown} onClose={closeModal} />
    </div>
  )
}

export default CardHeader
