export enum AuthError {
  NOT_VALIDATE = 'ERR-AUTH-001', // Error validating credentials
  CLUB_DONT_MATCH = 'ERR-AUTH-002' // User club does not match with the club code
}

export enum MemberErrors {
  UNIQUE = 'ERR-MEMBER-001', // Member already exists documet type id
  NUMBER_UNIQUE = 'ERR-MEMBER-002' // Member number already in use
}

export enum PrivacyError {
  REQUIRED = 'ERR-PRV-001' // Required privacy options are missing
}

export enum RecaptchaError {
  INVALID = 'ERR-RECAPTCHA-001', // Invalid recaptcha token
  REQUIRED = 'ERR-RECAPTCHA-002' // Recaptcha token is required
}
