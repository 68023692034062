import './styles.css'

import FormControl, { FormFieldProps } from '../FormControl'

export const InputColor = ({ name, label, required = false, disabled = false, value, columnSize = 4, onChange, error }: FormFieldProps) => {
  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error} labelClassName="label-color">
      <input
        type="color"
        className="col-3 input-color"
        value={typeof value !== 'object' ? value : undefined}
        name="primaryColor"
        onChange={onChange}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default InputColor
