import axios, { AxiosResponse } from 'axios'

import { PaginatedData, SuccessResponse } from './models/common'
import { UserGrantedResponse, UserResponse } from './models/user'

const GET_USER = 'user'
const GET_USERS = 'users'

export const getUserInfo: () => Promise<UserGrantedResponse> = () => {
  return axios.get<UserGrantedResponse>(GET_USER).then((response: AxiosResponse) => response?.data)
}

export const getUsers: () => Promise<PaginatedData<UserResponse>> = () => {
  return axios.get<PaginatedData<UserResponse>>(GET_USERS).then((response: AxiosResponse) => response?.data)
}

export const getUser: (id: string | number) => Promise<UserResponse> = (id) => {
  return axios.get<UserResponse>(`${GET_USER}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const createUser: (user: UserResponse) => Promise<SuccessResponse<{}>> = (user: UserResponse) => {
  return axios.post<SuccessResponse<{}>>(GET_USER, user).then((response: AxiosResponse) => response?.data)
}

export const updateUser: (user: UserResponse) => Promise<SuccessResponse<{}>> = (user: UserResponse) => {
  return axios.patch<SuccessResponse<{}>>(`${GET_USER}/${user.id}`, user).then((response: AxiosResponse) => response?.data)
}
