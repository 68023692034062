import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { PaginatedData } from '../../../api/models/common'
import { MemberQueryRequest, MemberResponse } from '../../../api/models/member'
import ListTitle from '../../common/ListTitle'
import Pagination from '../../common/Pagination'
import TableLoader from '../../loaders/TableLoader'
import MemberListItem from '../MemberListItem'

interface MemberListProps extends Partial<PaginatedData<MemberResponse>> {
  setPagination: Dispatch<SetStateAction<PaginatedData<MemberResponse>>>
  loading?: boolean
  filter: MemberQueryRequest
}

const fields = ['name', 'lastname', 'birthdate_short', 'age', 'category_short', 'weapon', 'status', 'created_at_short']

export const MemberList = (props: MemberListProps) => {
  const [members, setMembers] = useState<Array<MemberResponse>>([])

  useEffect(() => {
    setMembers(props.data!)
  }, [props])

  return (
    <div className="card-body">
      <div className="table-responsive overflow-auto">
        {'loading' in props && props.loading ? (
          <TableLoader width={'100%'} />
        ) : (
          <table id="tableMembers" className="table table-bordered table-striped table-sm text-sm">
            <ListTitle fields={fields} setPagination={props.setPagination} filter={props.filter} showOrderOptions />
            <tbody>{members?.map((member) => <MemberListItem key={member.id} {...member} />)}</tbody>
          </table>
        )}
      </div>
      <div className="card-body">
        {'links' in props && <Pagination {...(props as PaginatedData<MemberResponse>)} setPagination={props.setPagination!} filter={props.filter} />}
      </div>
    </div>
  )
}

export default MemberList
