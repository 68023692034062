import ca from 'date-fns/locale/ca'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import gl from 'date-fns/locale/gl'
import it from 'date-fns/locale/it'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const DEFAULT_LANGUAGE = 'es_ES'

const translation = (language: object) => ({ translation: language })

export enum AVAILABLE_LANGUAGES {
  ca_ES = 'ca_ES',
  es_ES = 'es_ES',
  en_GB = 'en_GB',
  gl_ES = 'gl_ES',
  it_IT = 'it_IT'
}

export enum AVAILABLE_LANGUAGES_LABELS {
  ca_ES = 'Català',
  es_ES = 'Español',
  en_GB = 'English',
  gl_ES = 'Galego',
  it_IT = 'Italiano'
}

const resources = {
  [AVAILABLE_LANGUAGES.ca_ES]: translation(require('./ca_ES.json')),
  [AVAILABLE_LANGUAGES.es_ES]: translation(require('./es_ES.json')),
  [AVAILABLE_LANGUAGES.en_GB]: translation(require('./en_GB.json')),
  [AVAILABLE_LANGUAGES.gl_ES]: translation(require('./gl_ES.json')),
  [AVAILABLE_LANGUAGES.it_IT]: translation(require('./it_IT.json'))
}

const storageLanguage = localStorage.getItem('language')

i18n.use(initReactI18next).init({
  resources,
  lng: storageLanguage || DEFAULT_LANGUAGE,
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false
  }
})

export const setLanguage = (lang?: string) => {
  if (lang) {
    const language = Object.keys(resources).includes(lang) ? lang : DEFAULT_LANGUAGE

    i18n.changeLanguage(language)
    localStorage.setItem('language', language)
  }
}

export const languageForLocaleDate = localStorage.getItem('language')?.replace('_', '-') || 'es-ES'

export const getLanguageLocale = () => {
  const language = localStorage.getItem('language')

  switch (language) {
    case AVAILABLE_LANGUAGES.en_GB:
      return en
    case AVAILABLE_LANGUAGES.ca_ES:
      return ca
    case AVAILABLE_LANGUAGES.gl_ES:
      return gl
    case AVAILABLE_LANGUAGES.it_IT:
      return it
    default:
      return es
  }
}

export default i18n
