import './styles.css'

import { AttendanceSheet } from '../../../../api/models/attendance'
import { languageForLocaleDate } from '../../../../translations'
import FormSectionHeader from '../../../forms/components/FormSectionHeader'
import MonthReportTable from './components/MonthReportTable'

export interface ReportProps {
  attendanceSheets: AttendanceSheet[]
}

export const MonthReport = ({ attendanceSheets }: ReportProps) => {
  return (
    <>
      {attendanceSheets.map((sheet) => (
        <div key={sheet.id} className="card">
          <FormSectionHeader
            title={`${sheet.name} - ${new Date().toLocaleDateString(languageForLocaleDate, { month: 'long', year: 'numeric' })}  `}
            startCollapsed={false}
          />
          <div className="card-body">
            <div className="row">
              <MonthReportTable id={sheet.id!} />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default MonthReport
