import useAuthContext from '../contexts/hooks/useAuthContext'

export const useNotifications = () => {
  const { notifications } = useAuthContext()

  const unReadNotifications = notifications?.filter((notification) => !notification.readAt)
  const mainUnReadNotifications = unReadNotifications?.slice(0, 3)
  const unReadNotificationsCount = unReadNotifications?.length || 0

  return { notifications, mainUnReadNotifications, unReadNotificationsCount }
}

export default useNotifications
