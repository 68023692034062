import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createPrivacyConfig, getPrivacyConfig } from '../../../api/formsConfig'
import { PrivacyFormConfigRequest, PrivacyOptionType } from '../../../api/models/formsConfig'
import { uploadPrivacyFile } from '../../../api/uploadFiles'
import PrivacyFormConfigCardInput from './components/PrivacyFormConfigCardInput'

export const PrivacyFormConfigCard = () => {
  const { t } = useTranslation()
  const [editAvailable, setEditAvailable] = useState(false)
  const [privacyConfig, setPrivacyConfig] = useState<PrivacyFormConfigRequest>({})

  useEffect(() => {
    getPrivacyConfig().then((response) => {
      const initialPrivacyConfig = Object.values(response)
        .flatMap((groups) => groups)
        .reduce((acc, item) => {
          acc[item.name] = item
          return acc
        }, {} as PrivacyFormConfigRequest)

      setPrivacyConfig(initialPrivacyConfig)
    })
  }, [])

  const onSave = () => {
    createPrivacyConfig(privacyConfig).then((response) => {
      setEditAvailable(false)
      // Upload custom files
      if (response.success) {
        Object.values(privacyConfig).forEach((option) => {
          if (option.customDoc) {
            const formData = new FormData()
            formData.append('file', option.customDoc)
            formData.append('optionId', option.id!)
            uploadPrivacyFile(formData).then(() => {
              // toast.custom(<CustomToast text={t('notifications.updated')} />)
            })
          }
        })
      }
    })
  }

  return (
    <div className="card card-custom card-outline col-12">
      <div className="card-header">
        <h5 className="card-title">{t('accept_privacy.card_title')}</h5>
        <div className="card-tools">
          <a onClick={() => setEditAvailable(!editAvailable)} className="btn btn-tool">
            <i className={`fas ${editAvailable ? 'fa-check' : 'fa-pen'}`}></i>
          </a>
        </div>
      </div>
      <div className="card-body">
        <div className="row col-12">
          <table className="table table-sm">
            <tbody>
              {Object.values(privacyConfig).map((option, index) => (
                <Fragment key={index}>
                  {((index > 0 && option.type !== Object.values(privacyConfig)[index - 1].type) || index === 0) && (
                    <tr className="text-sm text-bold">
                      <td className="align-bottom fit">
                        <p className="mb-1 mt-3 text-md">{t(`accept_privacy.${option.type}`)}</p>
                      </td>
                      <td className="fixed align-bottom">{t('accept_privacy.labels.show_in_form')}</td>
                      <td className="fixed align-bottom">{t('accept_privacy.labels.required')}</td>
                      {option.type !== PrivacyOptionType.FIELD &&
                        (editAvailable ? (
                          <>
                            <td className="fixed align-bottom">{t('accept_privacy.labels.default_doc')}</td>
                            <td className="align-bottom">Archivo</td>
                          </>
                        ) : (
                          <td className="align-bottom">{t('members.documents')}</td>
                        ))}
                    </tr>
                  )}
                  <PrivacyFormConfigCardInput key={option.id} {...option} setPrivacyConfig={setPrivacyConfig} disabled={!editAvailable} />
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="row col-12 justify-content-end">
          <button className="btn btn-primary btn-sm" disabled={!editAvailable} onClick={onSave}>
            {t('app.save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PrivacyFormConfigCard
