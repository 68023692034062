import './style.css'

interface CustomCallableProps {
  text: string
}

export const CustomCallable = ({ text }: CustomCallableProps) => {
  return (
    <div className="row col-12">
      <div className="callout callout-info callout-custom mx-4">
        <div className="info-icon">
          <i className="icon fas fa-info"></i>
        </div>
        <p className="text-sm">{text}</p>
      </div>
    </div>
  )
}

export default CustomCallable
