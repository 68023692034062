import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'

import { ModalProps } from '../ContactModal'

export const ErrorModal = ({ isOpen, closeModal, children }: PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation()

  return (
    <ReactModal
      isOpen={isOpen}
      // @ts-ignore
      setAppElement={'#app-body'}
      overlayClassName={'overlay'}
      className="modal-dialog modal-element modal-content callout callout-danger"
    >
      <div className="row d-flex justify-content-between px-3">
        <h5>
          <i className="icon fas fa-exclamation-triangle text-danger mx-2 mt-4"></i>
          {t('modal.alert')}
        </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
    </ReactModal>
  )
}
export default ErrorModal
