// @ts-nocheck
import './styles.css'

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'

import { createGroup, updateGroup } from '../../../api/group'
import { getAllMembers } from '../../../api/member'
import { GroupResponse } from '../../../api/models/group'
import { MemberResponse } from '../../../api/models/member'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import useUIContext from '../../../contexts/hooks/useUIContext'
import CustomToast from '../../common/CustomToast'
import { ModalProps } from '../ContactModal'

interface AddUsersModalProps extends ModalProps {
  group: Partial<GroupResponse>
  action: 'create' | 'update'
}

export const AddUsersModal = ({ isOpen, closeModal, group, action }: AddUsersModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { clubCode } = useClubConfigContext()
  const { setUpdateAfterCloseModal } = useUIContext()

  const [members, setMembers] = useState<MemberResponse[]>([])
  const [newMembers, setNewMembers] = useState<number[]>(group?.members ? group.members?.map((member) => member.id) : [])
  const [filteredMembers, setFilteredMembers] = useState<MemberResponse[]>(members)
  const [searchInput, setSearchInput] = useState<string>('')

  const onChange = (id: number) => {
    if (!newMembers?.includes(id)) {
      setNewMembers([...newMembers, id])
    } else {
      setNewMembers(newMembers.filter((member) => member !== id))
    }
  }

  const onClickSave = () => {
    if (action === 'create') {
      createGroup({
        ...group,
        members: members?.filter((member) => newMembers.includes(member.id))
      })
      closeModal()
      toast.custom(<CustomToast text={t('notifications.created')} />)
      navigate(`/${clubCode}/groups`)
    } else {
      updateGroup({
        ...group,
        members: members?.filter((member) => newMembers.includes(member.id))
      })
      setUpdateAfterCloseModal(true)
      closeModal()

      toast.custom(<CustomToast text={t('notifications.updated')} />)
    }
  }

  useEffect(() => {
    getAllMembers().then((response) => setMembers(response))
  }, [])

  useEffect(() => {
    if (!group?.members) return
    setNewMembers(group?.members?.map((member) => member.id))
  }, [group])

  useEffect(() => {
    setFilteredMembers(
      members.filter(
        (member) =>
          member.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          member.lastname.toLowerCase().includes(searchInput.toLowerCase()) ||
          member.email.toLowerCase().includes(searchInput.toLowerCase())
      )
    )
  }, [searchInput, members])

  Modal.setAppElement(document.getElementById('app-body'))

  Modal.defaultStyles.overlay = {
    zIndex: 1000
  }

  return (
    <Modal isOpen={isOpen} setAppElement={'#app-body'} overlayClassName={'overlay'} className="modal-dialog modal-element modal-content modal-size">
      <div className="modal-header">
        <h5 className="modal-title">{t('groups.add_members')}</h5>
      </div>
      <div className="modal-body d-flex flex-column">
        <div className="row col-6 pb-2 d-flex align-self-end">
          <div className="input-group input-group-sm">
            <input
              type="search"
              className="form-control form-control-sm"
              placeholder={`${t('filter.search')}...`}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="row table-container">
          <table className="table table-bordered">
            <thead className="table-sticky-header">
              <tr>
                <th style={{ width: 10 }}>#</th>
                <th>{t('label.name')}</th>
                <th>{t('label.email')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredMembers?.map((member, key) => (
                <tr key={member.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={newMembers?.includes(member.id)}
                      onChange={() => onChange(member.id)}
                      style={{ width: 18, height: 18, color: 'white' }}
                    />
                  </td>
                  <td>{`${member.name} ${member.lastname}`}</td>
                  <td>{member.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={closeModal} className="close-modal btn btn-default" data-bs-dismiss="modal">
          {t('app.close')}
        </button>
        <button onClick={onClickSave} className="btn btn-primary">
          {t('app.send')}
        </button>
      </div>
    </Modal>
  )
}

export default AddUsersModal
