import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_COLUMN_SIZE } from '../../../../helpers/layout'
import FormControl, { FormFieldProps } from '../FormControl'

interface InputGroupProps extends FormFieldProps {
  valueSelect?: string
  onChangeSelect?: (event: ChangeEvent<HTMLSelectElement>) => void
}

export const InputGroup = ({
  nameSelect,
  name,
  required = false,
  disabled = false,
  label,
  options = [],
  valueSelect,
  value,
  columnSize = DEFAULT_COLUMN_SIZE,
  onChangeSelect,
  onChange,
  error,
  removeError
}: InputGroupProps) => {
  const { t } = useTranslation()

  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error}>
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
          <select name={nameSelect as string | undefined} onChange={onChangeSelect} disabled={disabled} onFocus={removeError} value={valueSelect}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.value ? t(option.label!) : option.value}
              </option>
            ))}
          </select>
        </span>
        <input
          type="text"
          value={typeof value !== 'object' ? value : undefined}
          className="form-control"
          name={name as string | undefined}
          onChange={onChange}
          disabled={disabled}
          onFocus={removeError}
        />
      </div>
    </FormControl>
  )
}

export default InputGroup
