import './styles.css'

import { languageForLocaleDate } from '../../../../translations'
import FormSectionHeader from '../../../forms/components/FormSectionHeader'
import { ReportProps } from '../MonthReport'
import AnnualReportTable from './components/AnnualReportTable'

export const AnnualReport = ({ attendanceSheets }: ReportProps) => {
  return (
    <>
      {attendanceSheets.map((sheet) => (
        <div key={sheet.id} className="card">
          <FormSectionHeader
            title={`${sheet.name} - ${new Date().toLocaleDateString(languageForLocaleDate, { year: 'numeric' })}  `}
            startCollapsed={false}
          />
          <div className="card-body">
            <div className="row">
              <AnnualReportTable id={sheet.id!} />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default AnnualReport
