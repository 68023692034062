import './styles.css'

import { Dispatch, SetStateAction, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { createChangelog, getChangelog } from '../../../api/changelog'
import { Changelog } from '../../../api/models/changelog'
import CustomToast from '../../common/CustomToast'

interface ChangelogInputProps {
  setChangelog: Dispatch<SetStateAction<Changelog[]>>
}

export const ChangelogInput = ({ setChangelog }: ChangelogInputProps) => {
  const { t } = useTranslation()
  const [log, setLog] = useState<Partial<Changelog>>()

  const onSave = () => {
    createChangelog({ ...(log as Changelog), date: new Date() }).then(() => {
      getChangelog().then((response) => setChangelog(response))
      toast.custom(<CustomToast text={t('notifications.created')} />)
    })
  }

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className="d-flex flex-row w-100 mb-2 align-items-center">
            <p className="mb-0">{new Date().toLocaleDateString('es-ES')}</p>
            <input
              type="text"
              className="custom-input w-100"
              value={log?.title}
              onChange={(e) => setLog((prev) => ({ ...prev, title: e.target.value }))}
              placeholder="Título"
            />
            <a onClick={onSave}>
              <i className="fa fa-check-circle color-success text-xl"></i>
            </a>
          </div>
          <textarea
            className="custom-input"
            value={log?.description}
            onChange={(e) => setLog((prev) => ({ ...prev, description: e.target.value }))}
            placeholder="Descripción"
          />
        </div>
      </td>
    </tr>
  )
}

export default ChangelogInput
