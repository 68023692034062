import { ChangeEvent } from 'react'

import FormControl, { FormFieldProps } from '../FormControl'

export interface TextareaProps extends Omit<FormFieldProps, 'onChange'> {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

export const Textarea = ({ label, required = false, disabled = false, value, columnSize = 12, onChange }: TextareaProps) => {
  return (
    <FormControl label={label} columnSize={columnSize} required={required}>
      <textarea value={typeof value !== 'object' ? value : undefined} className="form-control" onChange={onChange} disabled={disabled}></textarea>
    </FormControl>
  )
}

export default Textarea
