import ContentLoader from 'react-content-loader'

export const ProfileInfoLoader = (props: any) => (
  <ContentLoader speed={2} width={250} height={40} viewBox="0 0 250 40" backgroundColor="#f3f3f3" foregroundColor="#c2c7d0" {...props}>
    <rect x="48" y="10" rx="3" ry="3" width="75" height="14" />
    <rect x="130" y="10" rx="3" ry="3" width="90" height="14" />
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
)

export default ProfileInfoLoader
