import './styles.css'

import { useTranslation } from 'react-i18next'

import { PermissionGroup } from '../../../api/models/role'
import { UserGrantedResponse } from '../../../api/models/user'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { getRoute, RouterPaths } from '../../../router/routes'

export const UserListItem = (user: Omit<UserGrantedResponse, 'modules'>) => {
  const { id, name, lastname, email, phone, roles, clubCode } = user

  const { t } = useTranslation()

  const { isMasterUser, permissions } = useAuthContext()

  return (
    <tr>
      <td>{name}</td>
      <td>{lastname}</td>
      <td>{email}</td>
      <td>{phone}</td>
      {isMasterUser && <td>{clubCode}</td>}
      <td>
        <div className="badge badge-info badge-list">{roles ? roles[0] : ''}</div>
      </td>
      <td>
        {permissions?.includes(`${PermissionGroup.USER}.edit`) && (
          <a href={`${getRoute(RouterPaths.EDIT_USER).replace(':id', id?.toString() || '1')}`} className="btn btn-primary btn-xs">
            <span className="fas fa-edit"></span> {t('app.edit')}
          </a>
        )}
      </td>
    </tr>
  )
}

export default UserListItem
