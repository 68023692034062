import './styles.css'

import { useTranslation } from 'react-i18next'

import { getMembers } from '../../../api/member'
import { Option } from '../../../api/models/club'
import { PaginatedData } from '../../../api/models/common'
import { Gender, MemberQueryRequest, MemberResponse, PREREGISTERED_STATUS, Status } from '../../../api/models/member'
import MultiSelect from '../../../components/forms/components/MultiSelect'
import { isDevice } from '../../../helpers/layout'
import Input from '../../forms/components/Input'
import InputRangeBetween from '../../forms/components/InputRangeBetween'
import Select from '../../forms/components/Select'
import { FilterProps } from '../Filter'

export const PreregisteredFilter = ({ filter, setFilter, setList, setShowFilter }: FilterProps) => {
  const { t } = useTranslation()

  const statusOptions = PREREGISTERED_STATUS.map((status) => ({
    value: status,
    label: t('members.status.' + status)
  }))

  const onClickSearch = () => {
    getMembers(filter).then((response) => {
      setList(response as PaginatedData<MemberResponse>)
      if (setShowFilter) setShowFilter(false)
    })
  }

  return (
    <div className="box-body">
      <div className="filter-members ">
        <div className="row">
          <Input
            label={t('filter.name_lastname')}
            name="nameLastname"
            value={filter.nameLastname}
            columnSize={isDevice ? 12 : 3}
            onChange={(e) => setFilter((prev) => ({ ...prev, nameLastname: e.target.value }))}
            size="small"
          />
          <Select
            label={t('label.gender')}
            name="gender"
            options={Object.values(Gender).map((gender) => ({ value: gender, label: 'members.' + gender }))}
            value={filter.gender}
            columnSize={isDevice ? 12 : 3}
            onChange={(e) => setFilter((prev) => ({ ...prev, gender: e.target.value }))}
            size="small"
          />
          <InputRangeBetween
            label={t('filter.age_between')}
            nameMin="ageFrom"
            nameMax="ageTo"
            valueMin={filter.ageFrom}
            valueMax={filter.ageTo}
            columnSize={isDevice ? 12 : 3}
            onChangeMin={(e) => setFilter((prev) => ({ ...prev, ageFrom: e.target.value }))}
            onChangeMax={(e) => setFilter((prev) => ({ ...prev, ageTo: e.target.value }))}
          />
          <MultiSelect
            label={t('label.status')}
            options={statusOptions}
            columnSize={isDevice ? 12 : 3}
            onChange={(statusSelected: Option[]) => {
              setFilter((prev: MemberQueryRequest) => ({ ...prev, status: statusSelected.map((status) => status.value as Status) }))
            }}
            value={statusOptions.filter((status) => filter.status?.includes(status.value))}
          />
        </div>
        <div className="row ml-2">
          <button onClick={onClickSearch} className="btn btn-primary btn-sm">
            <span className="fa fa-search"></span> {t('filter.search')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PreregisteredFilter
