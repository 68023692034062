import './styles.css'

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { Option } from '../../../api/models/club'
import { FormErrors } from '../../../api/models/common'
import { MemberResponse } from '../../../api/models/member'
import { getOriginsByClubCode } from '../../../api/params'
import CustomToast from '../../../components/common/CustomToast'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import { preinscriptionForm } from '../../../helpers/forms/preinscription'
import FormSection from '../components/FormSection'
import FormSectionHeader from '../components/FormSectionHeader'
import Textarea from '../components/Textarea'

export interface PreinscriptionFormProps {
  member: Partial<MemberResponse & { comments: string }>
  setMember: Dispatch<SetStateAction<Partial<MemberResponse & { comments: string }>>>
  errors: FormErrors<MemberResponse>
  setErrors?: Dispatch<SetStateAction<FormErrors<MemberResponse>>>
  handleSubmit: (_: string) => void
}

export const PreinscriptionForm = ({ member, setMember, errors, setErrors, handleSubmit }: PreinscriptionFormProps) => {
  const { t } = useTranslation()
  const { basicInfo } = useClubConfigContext()

  const [originOptions, setOriginOptions] = useState<Array<Option>>([])
  const [terms, setTerms] = useState(false)
  const captchaRef = useRef<ReCAPTCHA>(null)

  useEffect(() => {
    getOriginsByClubCode().then((response) => {
      setOriginOptions(response.map((origin) => ({ value: origin.id, label: origin.value })))
    })
  }, [])

  const preinscription = preinscriptionForm({ originOptions })

  const handleAcceptTerms = () => {
    toast.custom(<CustomToast text={t('notifications.accept_terms')} type="orange" />)
  }

  const handlePreinscription = () => {
    if (captchaRef.current) {
      const token = captchaRef.current?.getValue()
      captchaRef.current?.reset()

      handleSubmit(token!)
    }
  }

  return (
    <div className="card">
      <FormSection
        withoutBorder
        firstElement
        fields={preinscription[0].fields}
        formObject={member}
        setFormObject={setMember}
        errors={errors}
        setErrors={setErrors}
      />
      <FormSectionHeader
        title={t('members.preinscription_tutor_advice')}
        customClassName="mx-4 pl-1 card-header-custom"
        includeCollapseButton={false}
      />
      <FormSection
        withoutBorder
        firstElement
        fields={preinscription[1].fields}
        formObject={member}
        setFormObject={setMember}
        errors={errors}
        setErrors={setErrors}
      />

      <FormSectionHeader
        title={t('members.preinscription_tutor_advice_2')}
        customClassName="mx-4 pl-1 card-header-custom"
        includeCollapseButton={false}
      />
      <FormSection
        withoutBorder
        firstElement
        fields={preinscription[2].fields}
        formObject={member}
        setFormObject={setMember}
        errors={errors}
        setErrors={setErrors}
      />

      <div className="mb-4 mx-4 top-border-custom row">
        <Textarea
          label={t('label.observations')}
          value={member.comments}
          // @ts-ignore
          onChange={(e) => setMember((prev) => ({ ...prev, comments: e.target.value }))}
        />

        <div className="custom-control custom-checkbox col-12 ml-1">
          <input className="custom-control-input custom-control-input" type="checkbox" onChange={() => setTerms(!terms)} checked={terms} />
          <label className="custom-control-label" onClick={() => setTerms(!terms)}>
            {t('forms.accept_terms')}{' '}
            <a href={basicInfo?.privacyUrl} target="_blank" rel="noreferrer" className="preinscription-link">
              {t('forms.privacy_policy')}
            </a>
          </label>
        </div>
        <div className="my-3 col-12">
          <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} ref={captchaRef} />
        </div>
      </div>
      <div className="mb-4 mx-4 ">
        <button type="submit" className="float-right btn btn-primary" onClick={terms ? handlePreinscription : handleAcceptTerms}>
          {t('app.save')}
        </button>
      </div>
    </div>
  )
}

export default PreinscriptionForm
