import { useContext } from 'react'

import { UIContext } from '../UIContext'

export const useUIContext = () => {
  const context = useContext(UIContext)

  return context
}

export default useUIContext
