import axios, { AxiosResponse } from 'axios'

import { AuthRequest, AuthResponse } from './models/auth'
import { SuccessResponse } from './models/common'

export const AUTH_URL = 'auth'

export const login: (_: AuthRequest) => Promise<AuthResponse> = ({ email, password, clubCode }) => {
  return axios.post<AuthResponse>(AUTH_URL, { email, password, clubCode }, {}).then((response: AxiosResponse) => response?.data)
}

export const logout: () => Promise<SuccessResponse<{}>> = () => {
  return axios.delete(AUTH_URL, {}).then((response: AxiosResponse) => response?.data)
}
