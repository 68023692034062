import { AttendanceSheet as AttendanceSheetModel } from '../../../api/models/attendance'
import { languageForLocaleDate } from '../../../translations'
import FormSectionHeader from '../../forms/components/FormSectionHeader'
import AttendanceTable, { MultiValueOption } from '../AttendanceTable'

export interface AttendanceSheetProps extends AttendanceSheetModel {
  labelOptions: Array<MultiValueOption>
  selectedDate: Date
  loading: boolean
}

export const AttendanceSheet = (props: AttendanceSheetProps) => {
  const { name, selectedDate } = props

  return (
    <div className={`card collapsed-card`}>
      <FormSectionHeader title={`${name} - ${selectedDate.toLocaleDateString(languageForLocaleDate)}`} startCollapsed={true} />
      <div className="card-body">
        <div className="row">
          <AttendanceTable {...props} />
        </div>
      </div>
    </div>
  )
}

export default AttendanceSheet
