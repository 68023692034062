export default {
  uploadStep: {
    title: 'Subir archivo',
    manifestTitle: 'Estos son los datos que esperamos:',
    manifestDescription: '(Después de subir el archivo podrás renombrar o eliminar datos en el siguiente paso)',
    maxRecordsExceeded: (maxRecords: string) => `Demasiados registros. Se permiten un máximo de ${maxRecords} registros.`,
    dropzone: {
      title: 'Sube un archivo .xlsx, .xls or .csv',
      errorToastDescription: 'subida rechazada',
      activeDropzoneTitle: 'Arrastra el archivo aquí...',
      buttonTitle: 'Selecciona un archivo',
      loadingTitle: 'Procesando...'
    },
    selectSheet: {
      title: 'Selecciona una hoja',
      nextButtonTitle: 'Siguiente',
      backButtonTitle: 'Anterior'
    }
  },
  selectHeaderStep: {
    title: 'Selecciona la fila de encabezados',
    nextButtonTitle: 'Siguiente',
    backButtonTitle: 'Anterior'
  },
  matchColumnsStep: {
    title: 'Haz coincidir las columnas',
    nextButtonTitle: 'Siguiente',
    backButtonTitle: 'Anterior',
    userTableTitle: 'Tu tabla',
    templateTitle: 'Se convertirá',
    selectPlaceholder: 'Selecciona la columna...',
    ignoredColumnText: 'Columna no incluida',
    subSelectPlaceholder: 'Selecciona...',
    matchDropdownTitle: 'Coincide con',
    unmatched: 'No emparejada',
    duplicateColumnWarningTitle: 'Otra columna ya está emparejada con esta',
    duplicateColumnWarningDescription: 'Las columnas no pueden estar duplicadas'
  },
  validationStep: {
    title: 'Validar datos',
    nextButtonTitle: 'Confirmar',
    backButtonTitle: 'Atrás',
    noRowsMessage: 'No se han encontrado datos',
    noRowsMessageWhenFiltered: 'No se han encontrado errores',
    discardButtonTitle: 'Descartar columnas seleccionadas',
    filterSwitchTitle: 'Mostrar solo las filas con errores (las filas que contentan errores serán ignoradas durante la importación)'
  },
  alerts: {
    confirmClose: {
      headerTitle: 'Salir del flujo de importación',
      bodyText: '¿Estás seguro? La información se perderá y no será guardada.',
      cancelButtonTitle: 'Cancelar',
      exitButtonTitle: 'Salir'
    },
    submitIncomplete: {
      headerTitle: 'Se han encontrado errores',
      bodyText: 'Algunas filas siguen conteniendo errores. Las filas con errores serán ignoradas cuando se haga la importación.',
      bodyTextSubmitForbidden: 'Sigue habiendo filas que contienen errores.',
      cancelButtonTitle: 'Cancelar',
      finishButtonTitle: 'Enviar'
    },
    submitError: {
      title: 'Error',
      defaultMessage: 'Se ha producido un error durante la importación'
    },
    unmatchedRequiredFields: {
      headerTitle: 'No se han hecho coincidir todas las columnas',
      bodyText: 'Algunas de las columnas obligatorias no han sido emparejadas o han sido ignoradas. ¿Seguro que deseas continuar?',
      listTitle: 'Las columnas no emparejadas son:',
      cancelButtonTitle: 'Cancelar',
      continueButtonTitle: 'Continuar'
    },
    toast: {
      error: 'Error'
    }
  }
}
