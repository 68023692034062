// eslint-disable-next-line simple-import-sort/imports
import './styles.css'

import { Dispatch, SetStateAction, useState } from 'react'

import { createAttendanceLabel, getAttendanceLabels } from '../../../../../api/attendance'
import { AttendanceLabelResponse } from '../../../../../api/models/attendance'
import { Option } from '../../../../../api/models/club'
import ColorPicker from '../../../../forms/components/ColorPicker'

interface AttendanceLabelCardInputProps {
  setElements: Dispatch<SetStateAction<Array<AttendanceLabelResponse>>>
}

export const AttendanceLabelCardInput = ({ setElements }: AttendanceLabelCardInputProps) => {
  const [param, setParam] = useState<string>()
  const [color, setColor] = useState<Option>()

  const onSave = () => {
    if (!param || !param.length) return
    createAttendanceLabel({ name: param, colorId: Number(color?.value) }).then((response) => {
      setElements((prev) => [...prev, { id: 999, name: param, color: color?.label } as AttendanceLabelResponse])
      setParam('')
      setColor(undefined)
      getAttendanceLabels().then((response) => {
        setElements(response)
      })
    })
  }

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          <ColorPicker onChange={setColor} value={color} />
          <input type="text" className="param-input ml-2" value={param} onChange={(e) => setParam(e.target.value)} />

          <a onClick={onSave}>
            <i className={`fa fa-check-circle color-success`}></i>
          </a>
        </div>
      </td>
    </tr>
  )
}

export default AttendanceLabelCardInput
