import { GroupBase, StylesConfig } from 'react-select'

import { IconOption } from './OriginSelect'
import { ColouredOption } from './StatusSelect'

export const originCustomStyles: StylesConfig<IconOption, true, GroupBase<IconOption>> = {
  option: () => ({
    padding: 0
  })
}

export const statusCustomStyles: StylesConfig<ColouredOption, true, GroupBase<ColouredOption>> = {
  option: () => ({
    padding: 0
  })
}
