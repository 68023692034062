import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { RemoveFileRequest } from './models/files'
import { MemberDocument } from './models/member'

export const UPLOAD_IMAGE = 'image'
export const UPLOAD_FILE = 'file'
export const UPLOAD_PRIVACY_FILE = 'privacy-file'

export const uploadImage: (_: FormData) => Promise<SuccessResponse<{}>> = (request) => {
  return axios.post<SuccessResponse<{}>>(UPLOAD_IMAGE, request).then((response: AxiosResponse) => response?.data)
}

export const uploadFile: (_: FormData) => Promise<SuccessResponse<Array<MemberDocument>>> = (request) => {
  return axios.post<SuccessResponse<Array<MemberDocument>>>(UPLOAD_FILE, request).then((response: AxiosResponse) => response?.data)
}

export const uploadPrivacyFile: (_: FormData) => Promise<SuccessResponse<{}>> = (request) => {
  return axios.post<SuccessResponse<{}>>(UPLOAD_PRIVACY_FILE, request).then((response: AxiosResponse) => response?.data)
}

export const deleteImage: (_: RemoveFileRequest) => Promise<SuccessResponse<{}>> = ({ type, id }) => {
  return axios.delete<SuccessResponse<{}>>(`${UPLOAD_IMAGE}/${type}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const deleteFile: (_: RemoveFileRequest) => Promise<SuccessResponse<Array<MemberDocument>>> = ({ type, id }) => {
  return axios.delete<SuccessResponse<Array<MemberDocument>>>(`${UPLOAD_FILE}/${type}/${id}`).then((response: AxiosResponse) => response?.data)
}
