import { RouteObject } from 'react-router-dom'

import CreateMember from '../../pages/Member/CreateMember'
import ListMembers from '../../pages/Member/ListMembers'
import ListPreregistered from '../../pages/Member/ListPreregistered'

export enum MemberRouterPaths {
  LIST_MEMBERS = ':clubCode/members',
  LIST_PREREGISTERED_MEMBERS = ':clubCode/members/preregistered',
  ADD_MEMBER = ':clubCode/member/create',
  EDIT_MEMBER = ':clubCode/member/:id/edit',
  SHOW_MEMBER = ':clubCode/member/:id/show'
}

export const memberRoutes: RouteObject[] = [
  {
    path: MemberRouterPaths.LIST_MEMBERS,
    Component: ListMembers
  },
  {
    path: MemberRouterPaths.LIST_PREREGISTERED_MEMBERS,
    Component: ListPreregistered
  },
  {
    path: MemberRouterPaths.ADD_MEMBER,
    Component: CreateMember
  },
  {
    path: MemberRouterPaths.EDIT_MEMBER,
    Component: CreateMember
  },
  {
    path: MemberRouterPaths.SHOW_MEMBER,
    Component: CreateMember
  }
]
