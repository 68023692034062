import { InternalAxiosRequestConfig } from 'axios'

import { AUTH_URL } from '../api/auth'
import { GET_CLUB_CONFIG } from '../api/club'

export enum AxiosMethod {
  GET = 'get',
  POST = 'post'
}

const PUBLIC_METHODS = [
  {
    method: AxiosMethod.POST,
    url: AUTH_URL
  },
  {
    method: AxiosMethod.GET,
    url: GET_CLUB_CONFIG('')
  }
]

export const transformUrltoRegex = (url: string) => {
  const varsInUrl = url.split('/').find((elem) => elem.match(/^\{[a-zA-Z]*\}$/))

  if (varsInUrl) {
    return new RegExp(url.replace(varsInUrl, '[a-z]*'))
  }

  return new RegExp(url)
}

export const isPublicMethod = (config: Partial<InternalAxiosRequestConfig>) => {
  return PUBLIC_METHODS.some((publicMethod) => {
    return publicMethod.method === config?.method && config.url?.match(transformUrltoRegex(publicMethod.url))
  })
}
