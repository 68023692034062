import './styles.css'

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getGroups } from '../../../api/group'
import { getMembers } from '../../../api/member'
import { Option } from '../../../api/models/club'
import { Category, PaginatedData, Weapon } from '../../../api/models/common'
import { GroupResponse } from '../../../api/models/group'
import { ACTIVE_STATUS, Gender, MemberQueryRequest, MemberResponse, Status } from '../../../api/models/member'
import MultiSelect from '../../../components/forms/components/MultiSelect'
import { isDevice } from '../../../helpers/layout'
import Input from '../../forms/components/Input'
import InputRangeBetween from '../../forms/components/InputRangeBetween'
import Select from '../../forms/components/Select'

export interface FilterProps {
  filter: MemberQueryRequest
  setFilter: Dispatch<SetStateAction<MemberQueryRequest>>
  setList: (_: PaginatedData<MemberResponse>) => void
  setShowFilter: Dispatch<SetStateAction<boolean>>
}

export const Filter = ({ filter, setFilter, setList, setShowFilter }: FilterProps) => {
  const { t } = useTranslation()

  const [groups, setGroups] = useState<GroupResponse[]>([])

  const weaponOptions = Object.keys(Weapon).map((weapon) => ({ value: weapon, label: t('common.weapon.' + weapon) }))
  const categoryOptions = Object.keys(Category).map((category) => ({ value: category, label: category }))
  const groupOptions = groups.map((group) => ({ value: group.id?.toString() || '', label: group.name }))
  const statusOptions = ACTIVE_STATUS.map((status) => ({
    value: status,
    label: t('members.status.' + status)
  }))

  const onClickSearch = () => {
    getMembers(filter).then((response) => setList(response as PaginatedData<MemberResponse>))
    localStorage.setItem('memberFilter', JSON.stringify(filter))
    if (isDevice) setShowFilter(false)
  }

  useEffect(() => {
    getGroups().then((response) => setGroups(response))
  }, [])

  return (
    <div className="box-body">
      <div className="filter-members">
        <div className="row">
          <Input
            label={t('filter.name_lastname')}
            name="nameLastname"
            value={filter.nameLastname}
            columnSize={isDevice ? 12 : 3}
            onChange={(e) => setFilter((prev) => ({ ...prev, nameLastname: e.target.value }))}
            size="small"
          />
          <Select
            label={t('label.gender')}
            name="gender"
            options={Object.values(Gender).map((gender) => ({ value: gender, label: 'members.' + gender }))}
            value={filter.gender}
            columnSize={isDevice ? 12 : 3}
            onChange={(e) => setFilter((prev) => ({ ...prev, gender: e.target.value }))}
            size="small"
          />
          <InputRangeBetween
            label={t('filter.age_between')}
            nameMin="ageFrom"
            nameMax="ageTo"
            valueMin={filter.ageFrom}
            valueMax={filter.ageTo}
            columnSize={isDevice ? 12 : 3}
            onChangeMin={(e) => setFilter((prev) => ({ ...prev, ageFrom: e.target.value }))}
            onChangeMax={(e) => setFilter((prev) => ({ ...prev, ageTo: e.target.value }))}
          />
          <MultiSelect
            label={t('label.weapon')}
            options={weaponOptions}
            columnSize={isDevice ? 12 : 3}
            onChange={(weaponsSelected: Option[]) => {
              setFilter((prev: MemberQueryRequest) => ({ ...prev, weapon: weaponsSelected.map((weapon) => weapon.value as string) }))
            }}
            value={weaponOptions.filter((weapon) => filter.weapon?.includes(weapon.value))}
          />
          <Input
            label={t('label.yearOfBirth')}
            name="yearOfBirth"
            value={filter.yearOfBirth}
            columnSize={isDevice ? 12 : 3}
            onChange={(e) => setFilter((prev) => ({ ...prev, yearOfBirth: e.target.value }))}
            size="small"
          />
          <MultiSelect
            label={t('label.category')}
            name="category"
            options={categoryOptions}
            columnSize={isDevice ? 12 : 3}
            onChange={(categorySelected: Option[]) => {
              setFilter((prev) => ({ ...prev, category: categorySelected.map((category) => category.value as string) }))
            }}
            value={categoryOptions.filter((category) => filter.category?.includes(category.value))}
          />
          <MultiSelect
            label={t('label.group')}
            name="group"
            columnSize={isDevice ? 12 : 3}
            options={groupOptions}
            onChange={(groupSelected: Option[]) => {
              setFilter((prev) => ({ ...prev, group: groupSelected.map((group) => group.value as string) }))
            }}
            value={groupOptions.filter((group) => filter.group?.includes(group.value))}
          />
          <MultiSelect
            label={t('label.status')}
            options={statusOptions}
            columnSize={isDevice ? 12 : 3}
            onChange={(statusSelected: Option[]) => {
              setFilter((prev: MemberQueryRequest) => ({ ...prev, status: statusSelected.map((status) => status.value as Status) }))
            }}
            value={statusOptions.filter((status) => filter.status?.includes(status.value))}
          />
        </div>
        <div className="row ml-2">
          <button onClick={onClickSearch} className="btn btn-primary btn-sm">
            <span className="fa fa-search"></span> {t('filter.search')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Filter
