import { useTranslation } from 'react-i18next'

import { PermissionGroup } from '../../api/models/role'
import CardHeader from '../../components/common/CardHeader'
import Layout from '../../components/common/Layout'
import GroupList from '../../components/Group/GroupList'

export const ListGroups = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid mt-5">
          <div className="card">
            <CardHeader title={t('groups.title')} group={PermissionGroup.GROUP} />
            <GroupList />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ListGroups
