import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'

import { UPLOAD_FILE, UPLOAD_IMAGE, UPLOAD_PRIVACY_FILE } from '../api/uploadFiles'
import { isPublicMethod } from './routes'

export const setupAxios = () => {
  configBasicRequest()
  includeAxiosAuthHeaders()
}

export const configBasicRequest = () => {
  axios?.interceptors?.request.use((config: InternalAxiosRequestConfig) => {
    const { url } = config

    config.headers['Content-Type'] = [UPLOAD_IMAGE, UPLOAD_PRIVACY_FILE, UPLOAD_FILE].includes(url!) ? 'multipart/form-data' : 'application/json'

    if (!Object.keys(config).includes('baseURL')) {
      config.baseURL = process.env.REACT_APP_API_BASE_URL
    }

    return config
  })
}

const hasAuthHeader: (_: AxiosRequestHeaders) => boolean = (headers) => {
  return Object.keys(headers).includes('authorization')
}

export const includeAxiosAuthHeaders = () => {
  axios?.interceptors?.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!isPublicMethod(config)) {
      if (!hasAuthHeader(config.headers)) {
        const storageToken = localStorage.getItem('token')

        config.headers.authorization = storageToken
        return config
      }
    }

    return config
  })
}
