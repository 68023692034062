import { useTranslation } from 'react-i18next'

export interface FormSectionHeaderProps {
  title: string
  objectId?: string
  startCollapsed?: boolean
  includeAddButton?: boolean
  includeCollapseButton?: boolean
  addButtonAction?: () => void
  customClassName?: string
}

export const FormSectionHeader = ({
  title,
  startCollapsed,
  customClassName,
  includeAddButton = false,
  includeCollapseButton = true,
  addButtonAction = () => {}
}: FormSectionHeaderProps) => {
  const { t } = useTranslation()

  return (
    <div className={`card-header d-flex justify-content-between ${customClassName}`}>
      <h3 className="card-title">{title}</h3>
      <div className="card-tools d-flex flex-row flex-grow-1 justify-content-end">
        {includeAddButton && (
          <button title={t('groups.add_members')} type="button" className="btn btn-primary btn-sm" onClick={addButtonAction}>
            <i className="fa fa-user-edit"></i>
          </button>
        )}
        {includeCollapseButton && (
          <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
            <i className={`fas ${startCollapsed ? 'fa-plus' : 'fa-minus'}`}></i>
          </button>
        )}
      </div>
    </div>
  )
}

export default FormSectionHeader
