import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getClubSettings, updateClubSettings } from '../../../api/club'
import { languageForLocaleDate } from '../../../translations'

export const GlobalSettingsCard = () => {
  const { t } = useTranslation()
  const [editAvailable, setEditAvailable] = useState(false)
  const [paginateNumber, setPaginateNumber] = useState<number>(50)
  const [monthSeason, setMonthSeason] = useState<number>(8)
  const [daySeason, setDaySeason] = useState<number>(31)

  const clubCode = useParams<{ clubCode: string }>().clubCode

  const onSave = () => {
    updateClubSettings({ clubCode: clubCode!, paginateNumber, daySeason, monthSeason }).then((response) => {
      setEditAvailable(false)
      if (response.success) {
        setPaginateNumber(paginateNumber)
      }
    })
  }

  useEffect(() => {
    getClubSettings({ clubCode: clubCode! }).then((response) => {
      setPaginateNumber(response?.paginateNumber)
      setMonthSeason(response?.monthSeason)
      setDaySeason(response?.daySeason)
    })
  }, [])

  return (
    <div className="col-lg-6 col-md-6 col-sm-12 px-1">
      <div className="card card-custom card-outline">
        <div className="card-header">
          <h5 className="card-title text-bold">{t('menu.parameters')}</h5>
          <div className="card-tools">
            <a onClick={() => setEditAvailable(!editAvailable)} className="btn btn-tool">
              <i className={`fas ${editAvailable ? 'fa-check' : 'fa-pen'}`}></i>
            </a>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-sm">
            <tbody>
              <tr>
                <td className="fit">
                  {t('settings.member_paginate')}
                  <span className="text-xs"> (Máx. 500)</span>
                </td>
                <td className="text-right" style={{ width: 100 }}>
                  <input
                    type="number"
                    className="param-input mr-2 text-right"
                    style={{ maxWidth: 60 }}
                    value={paginateNumber}
                    max={500}
                    min={5}
                    disabled={!editAvailable}
                    onChange={(e) => {
                      if (parseInt(e.target.value) > 500) {
                        return setPaginateNumber(500)
                      }
                      setPaginateNumber(parseInt(e.target.value))
                    }}
                  />
                  {editAvailable && (
                    <a onClick={onSave}>
                      <i className="fa fa-check-circle color-success"></i>
                    </a>
                  )}
                </td>
              </tr>
              <tr className="w-100">
                <td colSpan={2}>{t('settings.season_date')}</td>
              </tr>
              <tr className="w-100">
                <td colSpan={2} className="text-right w-100 border-0">
                  <input
                    type="number"
                    className="param-input mr-2 text-right"
                    style={{ maxWidth: 60 }}
                    value={daySeason}
                    max={31}
                    min={1}
                    disabled={!editAvailable}
                    onChange={(e) => {
                      if (parseInt(e.target.value) > 31) {
                        return setDaySeason(31)
                      }
                      setDaySeason(parseInt(e.target.value))
                    }}
                  />
                  <select
                    className="param-input mr-2 text-right"
                    style={{ maxWidth: 200, height: 28 }}
                    value={monthSeason}
                    disabled={!editAvailable}
                    onChange={(e) => {
                      setMonthSeason(parseInt(e.target.value))
                    }}
                  >
                    <option value="6">{new Date(`2000-06-01`).toLocaleDateString(languageForLocaleDate, { month: 'long' })}</option>
                    <option value="7">{new Date(`2000-07-01`).toLocaleDateString(languageForLocaleDate, { month: 'long' })}</option>
                    <option value="8">{new Date(`2000-08-01`).toLocaleDateString(languageForLocaleDate, { month: 'long' })}</option>
                    <option value="9">{new Date(`2000-09-01`).toLocaleDateString(languageForLocaleDate, { month: 'long' })}</option>
                  </select>
                  {editAvailable && (
                    <a onClick={onSave}>
                      <i className="fa fa-check-circle color-success"></i>
                    </a>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default GlobalSettingsCard
