import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ZodError } from 'zod'

import { createContactInfoRequest } from '../../../api/contact'
import { InfoRequestOrigin, InfoRequestResponse, InfoRequestStatus } from '../../../api/models/contact'
import { infoRequestSchema } from '../../../helpers/validations/infoRequest'
import { handleValidationErrors } from '../../../helpers/validations/validationErrors'
import CustomToast from '../../common/CustomToast'
import Input from '../components/Input'
import Textarea from '../components/Textarea'

interface ContactFormProps {
  lang?: string
}

export const ContactForm = ({ lang }: ContactFormProps) => {
  const { t } = useTranslation()
  const { clubCode } = useParams<{ clubCode: string }>()

  const captchaRef = useRef<ReCAPTCHA>(null)

  const infoRequestInitialState: Partial<InfoRequestResponse> = {
    status: InfoRequestStatus.OPENED,
    origin: InfoRequestOrigin.FORM
  }

  const [infoRequest, setInfoRequest] = useState<Partial<InfoRequestResponse & { lang?: string }>>(infoRequestInitialState)
  const [errors, setErrors] = useState<Partial<InfoRequestResponse>>()

  const handleForm = () => {
    if (captchaRef.current) {
      const token = captchaRef.current?.getValue()
      captchaRef.current?.reset()

      sendForm(token!)
    }
  }

  const sendForm = (recaptchaToken: string) => {
    try {
      const validInfoRequest = infoRequestSchema.parse(infoRequest)

      createContactInfoRequest({
        recaptchaToken,
        clubCode: clubCode as string,
        lang: infoRequest.lang,
        ...(validInfoRequest as InfoRequestResponse)
      }).then(() => {
        toast.custom(<CustomToast text={t('notifications.info_request.success', { action: t('notifications.actions.created') })} />)
        setInfoRequest(infoRequestInitialState)
      })
    } catch (error) {
      toast.custom(<CustomToast type="danger" text={t('notifications.form_with_errors')} />)
      const issues = (error as ZodError).issues
      console.log(issues)
      setErrors(handleValidationErrors(issues))
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className="row">
      <Input
        label={t('contact.name')}
        required={true}
        columnSize={12}
        value={infoRequest?.name}
        onChange={(e) => setInfoRequest((prev) => ({ ...prev, name: e.target.value }))}
        error={errors?.name}
      />
      <Input
        label={t('label.email')}
        required={true}
        columnSize={12}
        value={infoRequest?.email}
        onChange={(e) => setInfoRequest((prev) => ({ ...prev, email: e.target.value }))}
        error={errors?.email}
      />
      <Input
        label={t('label.phone')}
        columnSize={12}
        value={infoRequest?.phone}
        onChange={(e) => setInfoRequest((prev) => (e.target.value.length ? { ...prev, phone: e.target.value } : { ...prev, phone: undefined }))}
        error={errors?.phone}
        removeError={() => setErrors((prev) => ({ ...prev, phone: undefined }))}
      />
      <Textarea
        label={t('label.observations')}
        required={true}
        columnSize={12}
        value={infoRequest?.message}
        onChange={(e) => setInfoRequest((prev) => ({ ...prev, message: e.target.value }))}
        error={errors?.message}
      />
      <div className="my-3 col-12">
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} ref={captchaRef} />
      </div>
      <div className="row w-100 justify-content-end">
        <button onClick={handleForm} className="btn btn-primary">
          {t('app.send')}
        </button>
      </div>
    </div>
  )
}
