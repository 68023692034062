import '../styles.css'

import { Dispatch, SetStateAction } from 'react'

import useAuthContext from '../../../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../../../contexts/hooks/useClubConfigContext'
import { languageForLocaleDate } from '../../../../translations'
import { InfoRequestFormType } from '../../../../types'

interface ChatMessagesProps {
  isCreateForm?: boolean
  infoRequest?: InfoRequestFormType
  setInforequest: Dispatch<SetStateAction<InfoRequestFormType>>
}

export const ChatMessages = ({ isCreateForm = true, infoRequest, setInforequest }: ChatMessagesProps) => {
  const { logo } = useClubConfigContext()
  const { user } = useAuthContext()

  return (
    <>
      {isCreateForm ? (
        <div className="p-3">
          <div className="direct-chat-msg">
            <div className="direct-chat-infos clearfix">
              <span className="direct-chat-name float-left">{infoRequest?.name}</span>
              <span className="direct-chat-timestamp float-right">{new Date(infoRequest?.createdAt!).toLocaleString(languageForLocaleDate)}</span>
            </div>

            <div className="rounded-circle user-avatar">
              <i className="fa fa-user text-lg" />
            </div>

            <div className="direct-chat-text">{infoRequest?.message}</div>
          </div>
          {infoRequest?.comments?.map((comment) => (
            <div className="direct-chat-msg right" key={comment.id}>
              <div className="direct-chat-infos clearfix">
                <span className="direct-chat-name float-right">{comment.author}</span>
                <span className="direct-chat-timestamp float-left">{new Date(comment?.createdAt!).toLocaleString(languageForLocaleDate)}</span>
              </div>

              <img src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`} alt="Club Logo" className="direct-chat-img border bg-white" />
              <div className="direct-chat-text">{comment.comment}</div>
            </div>
          ))}
          <div className="direct-chat-msg right mt-4">
            <img src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`} alt="Club Logo" className="direct-chat-img border bg-white" />
            <div className="direct-chat-text">
              <textarea
                value={infoRequest?.newComment?.comment}
                className="form-control"
                rows={4}
                onChange={(e) =>
                  setInforequest((prev) => ({ ...prev, newComment: { comment: e.target.value, author: `${user?.name} ${user?.lastname}` } }))
                }
              ></textarea>
            </div>
          </div>
        </div>
      ) : (
        <div className="direct-chat-messages" style={{ height: 180 }}>
          <div className="direct-chat-msg">
            <div className="rounded-circle user-avatar">
              <i className="fa fa-user text-lg" />
            </div>

            <div className="direct-chat-text">
              <textarea
                value={infoRequest?.message}
                className="form-control"
                rows={4}
                onChange={(e) => setInforequest((prev) => ({ ...prev, message: e.target.value }))}
              ></textarea>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChatMessages
