import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { Module } from './models/permission'
import { PermissionResponse, RoleResponse } from './models/role'

const GET_PERMISSIONS = 'permissions'
const GET_MODULES = 'modules'
const ROLE_URL = 'role'
const ROLES_URL = 'roles'

export const getPermissions: () => Promise<PermissionResponse> = () => {
  return axios.get<PermissionResponse>(GET_PERMISSIONS).then((response: AxiosResponse) => response?.data)
}

export const getModules: () => Promise<Array<Module>> = () => {
  return axios.get<Array<Module>>(GET_MODULES).then((response: AxiosResponse) => response?.data)
}

export const createRole: (role: RoleResponse) => Promise<SuccessResponse<{}>> = (role) => {
  return axios.post<RoleResponse>(ROLE_URL, role).then((response: AxiosResponse) => response?.data)
}

export const getRoles: () => Promise<RoleResponse[]> = () => {
  return axios.get<RoleResponse[]>(ROLES_URL).then((response: AxiosResponse) => response?.data)
}

export const getRole: (id: number) => Promise<RoleResponse> = (id) => {
  return axios.get<RoleResponse>(`${ROLE_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const updateRole: (_: RoleResponse) => Promise<SuccessResponse<{}>> = (role) => {
  return axios.patch<RoleResponse>(`${ROLE_URL}/${role.id}`, role).then((response: AxiosResponse) => response?.data)
}
