import { createContext, ReactNode, useReducer } from 'react'

import { ClubConfigResponse } from '../api/models/club'
import clubConfigReducer, { ClubConfigActionType } from '../reducers/clubConfig'

export const NO_CLUB_CODE = 'master'

interface IClubConfigContext extends ClubConfigResponse {
  clubCode: string
  clubCountry: string
  clubName: string
  setClubConfig: (_: ClubConfigResponse) => void
}

export const ClubConfigContext = createContext<IClubConfigContext>({
  clubCode: NO_CLUB_CODE,
  clubName: '',
  clubCountry: '',
  setClubConfig: () => {},
  basicInfo: {
    name: '',
    email: '',
    phone: '',
    clubName: ''
  },
  showBankAccount: false,
  season: ''
})

interface ClubConfigProviderProps {
  children: ReactNode
}

export const clubConfigInitialState: ClubConfigResponse = {
  clubCode: NO_CLUB_CODE,
  basicInfo: {
    name: '',
    email: '',
    phone: '',
    clubName: ''
  },
  showBankAccount: false
}

export const ClubConfigProvider = ({ children }: ClubConfigProviderProps) => {
  const [clubConfigState, dispatch] = useReducer(clubConfigReducer, clubConfigInitialState)

  return (
    <ClubConfigContext.Provider
      value={{
        clubName: clubConfigState.basicInfo.clubName || '',
        clubCountry: clubConfigState.basicInfo.country || '',
        ...clubConfigState,
        setClubConfig: (_) => dispatch({ type: ClubConfigActionType.SET_CLUB_CONFIG, payload: _ })
      }}
    >
      {children}
    </ClubConfigContext.Provider>
  )
}
