import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getMembers } from '../../api/member'
import { PaginatedData } from '../../api/models/common'
import { MemberQueryRequest, MemberResponse, Status } from '../../api/models/member'
import { PermissionGroup } from '../../api/models/role'
import CardHeader from '../../components/common/CardHeader'
import Layout from '../../components/common/Layout'
import Filter from '../../components/Member/Filter'
import MemberList from '../../components/Member/MemberList'
import { isDevice } from '../../helpers/layout'

export const ListMembers = () => {
  const { t } = useTranslation()

  const initialFilter = localStorage.getItem('memberFilter')
    ? JSON.parse(localStorage.getItem('memberFilter')!)
    : { status: [Status.ACTIVE], weapon: [] }

  const [filter, setFilter] = useState<MemberQueryRequest>(initialFilter)
  const [pagination, setPagination] = useState<PaginatedData<MemberResponse>>()
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(!isDevice)

  useEffect(() => {
    setLoading(true)
    getMembers(filter)
      .then((response) => {
        setPagination(response as PaginatedData<MemberResponse>)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid pt-5">
          <div className="card">
            <CardHeader
              title={t('members.registered')}
              group={PermissionGroup.MEMBER}
              filter={filter}
              setShowFilter={() => setShowFilter(!showFilter)}
            />
            {showFilter && <Filter filter={filter} setFilter={setFilter} setList={setPagination} setShowFilter={setShowFilter} />}
            <MemberList
              {...pagination!}
              setPagination={setPagination as Dispatch<SetStateAction<PaginatedData<MemberResponse>>>}
              loading={loading}
              filter={filter}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ListMembers
