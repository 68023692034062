import { useEffect, useState } from 'react'

import { getGroups } from '../../../api/group'
import { GroupResponse } from '../../../api/models/group'
import ListTitle from '../../common/ListTitle'
import GroupListItem from '../GroupListItem'

const fields = ['name', 'description', 'totalMembers']

export const GroupList = () => {
  const [groups, setGroups] = useState<Array<GroupResponse>>([])

  useEffect(() => {
    getGroups().then((response) => {
      setGroups(response)
    })
  }, [])

  return (
    <div className="card-body">
      <table className="float-right table table-bordered table-striped">
        <ListTitle fields={fields} />
        <tbody>
          {groups.map((group) => (
            <GroupListItem key={group.id} {...group} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default GroupList
