import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getDashboardInfo } from '../../../api/dashboard'

export const TotalMembersCard = () => {
  const { t } = useTranslation()
  const [totalMembers, setTotalMembers] = useState<number>()

  useEffect(() => {
    getDashboardInfo().then((response) => {
      setTotalMembers(response?.totalMembers)
    })
  }, [])

  return (
    <div className="col-lg-4 col-6">
      <div className="small-box bg-info bg-info-custom">
        <div className="inner">
          <h3>{totalMembers !== undefined ? totalMembers : '?'}</h3>
          <p>{t('members.title')}</p>
        </div>
        <div className="icon">
          <i className="ion ion-person"></i>
        </div>
      </div>
    </div>
  )
}

export default TotalMembersCard
