import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { getAllAttendanceSheets, getAttendanceLabels } from '../../api/attendance'
import { AttendanceSheet as AttendanceSheetModel } from '../../api/models/attendance'
import AttendanceSheet from '../../components/Attendance/AttendanceSheet'
import { MultiValueOption } from '../../components/Attendance/AttendanceTable'
import Layout from '../../components/common/Layout'
import useAuthContext from '../../contexts/hooks/useAuthContext'
import { DEFAULT_LANGUAGE } from '../../translations'

export const Attendance = () => {
  const { t } = useTranslation()
  const { user } = useAuthContext()

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [attendanceSheets, setAttendanceSheets] = useState<Array<AttendanceSheetModel>>([])
  const [attendanceLabels, setAttendanceLabels] = useState<Array<MultiValueOption>>([])

  useEffect(() => {
    getAttendanceLabels().then((response) => {
      setAttendanceLabels(response?.map((label) => ({ value: label.id, label: label.name, ...label })))
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    getAllAttendanceSheets({ date: selectedDate })
      .then((sheets) => {
        setAttendanceSheets(sheets)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectedDate])

  const handleChange = (date: Date) => {
    setSelectedDate(date)
  }

  return (
    <Layout>
      <section className="content">
        <div className="row flex justify-content-between w-100 p-4">
          <h1 className="pt-3">{t('menu.attendance')}</h1>
          <ReactDatePicker selected={selectedDate} onChange={handleChange} inline locale={user?.lang! || DEFAULT_LANGUAGE} />
        </div>
        <div className="card-body">
          {attendanceSheets.map((sheet) => (
            <AttendanceSheet key={sheet.id} {...sheet} labelOptions={attendanceLabels} selectedDate={selectedDate} loading={loading} />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default Attendance
