import axios, { AxiosResponse } from 'axios'

import { Changelog } from './models/changelog'

export const CHANGELOG_URL = 'changelog'

export const getChangelog: () => Promise<Changelog[]> = () => {
  return axios.get<Changelog[]>(CHANGELOG_URL).then((response: AxiosResponse) => response?.data)
}

export const createChangelog: (changelog: Changelog) => Promise<Changelog> = (changelog) => {
  return axios.post<Changelog>(CHANGELOG_URL, changelog).then((response: AxiosResponse) => response?.data)
}
