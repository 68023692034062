export const DNI_REGEX = /^(\d{8})([A-Z])$/
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

export const DNIValidation = (dni: string) => {
  if (DNI_REGEX.test(dni)) {
    const dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE'
    const letter = dniLetters.charAt(parseInt(dni, 10) % 23)

    return letter === dni.charAt(8)
  }
  return false
}

export const NIEValidation = (nie: string) => {
  if (NIE_REGEX.test(nie)) {
    const niePrefix = nie.charAt(0)

    const numberTranslator: { [key: string]: string } = {
      X: '0',
      Y: '1',
      Z: '2'
    }

    return DNIValidation(`${numberTranslator[niePrefix]}${nie.substring(1)}`)
  }
  return false
}
