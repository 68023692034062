import { useTranslation } from 'react-i18next'

import { ClubResponse } from '../../../api/models/club'
import { PermissionGroup } from '../../../api/models/role'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { RouterPaths } from '../../../router/routes'

export const ClubListItem = (club: ClubResponse) => {
  const { name, code, documentValue, email } = club
  const { t } = useTranslation()

  const { permissions } = useAuthContext()

  return (
    <tr>
      <td>{name}</td>
      <td>{code}</td>
      <td>{documentValue}</td>
      <td>{email}</td>
      <td>
        {permissions?.includes(`${PermissionGroup.CLUB}.edit`) && (
          <a href={RouterPaths.EDIT_CLUB.replace(':clubCode', code)} className="btn btn-primary btn-xs">
            <span className="fas fa-edit"></span> {t('app.edit')}
          </a>
        )}
      </td>
    </tr>
  )
}

export default ClubListItem
