import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { Module } from '../../api/models/permission'
import { RoleResponse } from '../../api/models/role'
import { createRole, getRole, updateRole } from '../../api/role'
import CustomToast from '../../components/common/CustomToast'
import Layout from '../../components/common/Layout'
import RoleForm from '../../components/forms/RoleForm'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'

export type RoleErrors = Partial<{
  [key in keyof RoleResponse]?: string
}>

export const CreateRole = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clubCode } = useClubConfigContext()

  const urlParams = useParams()
  const roleId = urlParams?.id || false

  const [role, setRole] = useState<Partial<RoleResponse>>({})
  const [errors, setErrors] = useState<RoleErrors>({})
  const [loading, setLoading] = useState(false)
  const [newModules, setNewModules] = useState<Module[]>([])

  const handleSubmit = () => {
    const action = roleId ? updateRole : createRole

    action({ ...(role as RoleResponse), modules: newModules })
      .then(() => {
        toast.custom(<CustomToast text={t(`notifications.${roleId ? 'updated' : 'created'}`)} />)
        navigate(`/${clubCode}/roles`)
      })
      .catch((e) => {
        const error = e?.response?.data
        if (error.errors) {
          setErrors(error.errors)
        }
      })
  }

  useEffect(() => {
    if (roleId) {
      setLoading(true)
      getRole(Number(roleId)).then((response) => {
        setRole(response)
        setLoading(false)
      })
    }
  }, [roleId])

  return (
    <Layout>
      <section className="content">
        <div className="col-12">
          <h1 className="pt-3">{roleId ? t('roles.edit_role') : t('roles.create_new_role')}</h1>
        </div>
        <RoleForm role={role} errors={errors} setRole={setRole} setNewModules={setNewModules} handleSubmit={handleSubmit} />
      </section>
    </Layout>
  )
}

export default CreateRole
