import './styles.css'

import { useTranslation } from 'react-i18next'

import useClubConfigContext from '../../../../contexts/hooks/useClubConfigContext'
import FormSectionHeader from '../../../forms/components/FormSectionHeader'
import { ReportProps } from '../MonthReport'
import SeasonReportTable from './components/SeasonReportTable'

export const SeasonReport = ({ attendanceSheets }: ReportProps) => {
  const { t } = useTranslation()
  const { season } = useClubConfigContext()

  return (
    <>
      {attendanceSheets.map((sheet) => (
        <div key={sheet.id} className="card">
          <FormSectionHeader title={`${sheet.name} - ${t('reports.attendance.season')} ${season} `} startCollapsed={false} />
          <div className="card-body">
            <div className="row">
              <SeasonReportTable id={sheet.id!} />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default SeasonReport
