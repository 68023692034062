import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { isDevice } from '../../../../helpers/layout'
import FormControl from '../FormControl'

interface InputRangeBetweenProps {
  nameMin: string
  nameMax: string
  label: string
  valueMin?: string
  valueMax?: string
  columnSize?: number
  size?: 'small' | 'medium' | 'large'
  onChangeMin?: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeMax?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const InputRangeBetween = ({
  nameMin,
  nameMax,
  label,
  columnSize = 3,
  size,
  valueMin,
  valueMax,
  onChangeMin,
  onChangeMax
}: InputRangeBetweenProps) => {
  const { t } = useTranslation()

  // <div className={`col-${columnSize} mb-2`}>
  //   <label className="col-form-label-sm">{label}</label>
  //   <div className="row">
  //     <input type="text" className="form-control-sm col-5" value={valueMin} name={nameMin} onChange={onChangeMin} />
  //     <span className="ml-1 mr-1">{t('filter.and')}</span>
  //     <input type="text" className="form-control-sm col-5" value={valueMax} name={nameMax} onChange={onChangeMax} />
  //   </div>
  // </div>

  return (
    <FormControl label={label} columnSize={columnSize} labelClassName={`col-form-label-sm ${size === 'small' ? 'col-form-label-sm' : ''}`}>
      <div className={`row ${isDevice ? 'd-flex flex-1 justify-content-around' : ''}`}>
        <input type="text" className="form-control-sm col-5" value={valueMin} name={nameMin} onChange={onChangeMin} />
        <span className="ml-1 mr-1">{t('filter.and')}</span>
        <input type="text" className="form-control-sm col-5" value={valueMax} name={nameMax} onChange={onChangeMax} />
      </div>
    </FormControl>
  )
}

export default InputRangeBetween
