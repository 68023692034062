import { RefObject } from 'react'

import FormControl, { FormFieldProps } from '../FormControl'

interface InputFileProps extends FormFieldProps {
  inputRef: RefObject<HTMLInputElement>
}

export const InputFile = ({
  name,
  label,
  required = false,
  disabled = false,
  value,
  columnSize = 6,
  onChange,
  helpText,
  error,
  inputRef
}: InputFileProps) => {
  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error} helpText={helpText}>
      <input type="file" className="form-control" onChange={onChange} disabled={disabled} ref={inputRef} />
    </FormControl>
  )
}

export default InputFile
