import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MemberRegistrationResponse } from '../../../api/models/reports'
import { getMembersRegistrationData } from '../../../api/reports'
import FormSectionHeader from '../../forms/components/FormSectionHeader'
import MemberRegistrationReport from '../../reports/MemberRegistrationReport'

export const MemberRegistrationReportCard = () => {
  const { t } = useTranslation()

  const [reportData, setReportData] = useState<Array<MemberRegistrationResponse>>([])

  useEffect(() => {
    getMembersRegistrationData({}).then((data) => {
      setReportData(data)
    })
  }, [])

  return (
    <div className="card card-custom card-outline col-12">
      <FormSectionHeader title={t('reports.member.title')} />

      <div className="card-body align-items-center">
        <MemberRegistrationReport reportData={reportData} />
      </div>
    </div>
  )
}

export default MemberRegistrationReportCard
