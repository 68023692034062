import { UserGrantedResponse } from '../api/models/user'

export enum AuthActionType {
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  CLEAN_AUTH = 'CLEAN_AUTH',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
}

export interface AuthState {
  token?: string
  user?: UserGrantedResponse
}

interface AuthAction {
  type: AuthActionType
  payload?: AuthState | any
}

const authReducer: (state: AuthState, action: AuthAction) => AuthState = (state, action) => {
  switch (action.type) {
    case AuthActionType.SET_TOKEN:
      return { ...state, token: action?.payload }
    case AuthActionType.SET_USER:
      return { ...state, user: action?.payload }
    case AuthActionType.CLEAN_AUTH:
      return { token: undefined, user: undefined }
    case AuthActionType.SET_NOTIFICATIONS:
      return { ...state, user: { ...state.user, notifications: action.payload } }
  }
}

export default authReducer
