import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getClubs } from '../../../api/club'
import { Option } from '../../../api/models/club'
import { FormErrors } from '../../../api/models/common'
import { UserResponse } from '../../../api/models/user'
import { getRoles } from '../../../api/role'
import { NO_CLUB_CODE } from '../../../contexts/ClubConfigContext'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { addUserForm } from '../../../helpers/forms/user'
import FormLoader from '../../loaders/FormLoader'
import FormSection from '../components/FormSection'

export interface UserFormProps {
  user: Partial<UserResponse>
  setUser: Dispatch<SetStateAction<Partial<UserResponse>>>
  errors: FormErrors<UserResponse>
  handleSubmit: () => void
  loading?: boolean
}

export const UserForm = ({ user, setUser, errors, handleSubmit, loading }: UserFormProps) => {
  const { t } = useTranslation()
  const { isMasterUser } = useAuthContext()
  const [roleOptions, setRoleOptions] = useState<Array<Option>>([])
  const [clubOptions, setClubOptions] = useState<Array<Option>>([])

  useEffect(() => {
    getClubs().then((response) => {
      setClubOptions([{ value: 1, label: NO_CLUB_CODE.toUpperCase() }, ...response.map((club) => ({ value: club.id!, label: club.name }))])
    })
  }, [isMasterUser])

  useEffect(() => {
    getRoles().then((response) => {
      setRoleOptions(response.map((role) => ({ value: role.id, label: role.name })))
    })
  }, [])

  return (
    <div className="container-fluid mt-3">
      {loading ? (
        <FormLoader
          width={'100%'}
          style={{ background: 'white', padding: '1.25rem', borderRadius: 5, boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)' }}
        />
      ) : (
        addUserForm({ roleOptions, clubOptions, isMasterUser }).map((section, key) => (
          <FormSection
            key={section?.title || key}
            startCollapsed={key !== 0}
            title={section?.title ? t(section.title) : undefined}
            fields={section.fields}
            formObject={user}
            setFormObject={setUser}
            errors={errors}
          />
        ))
      )}
      <div className="col-12 mt-2 card-body">
        <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit}>
          {t('app.save')}
        </button>
      </div>
    </div>
  )
}

export default UserForm
