import { DEFAULT_COLUMN_SIZE } from '../../../../helpers/layout'
import FormControl, { FormFieldProps } from '../FormControl'

export const Input = (props: FormFieldProps) => {
  const {
    name,
    label,
    required = false,
    disabled = false,
    value,
    columnSize = DEFAULT_COLUMN_SIZE,
    onChange,
    error,
    size,
    placeholder,
    removeError
  } = props
  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error} labelClassName={size === 'small' ? 'col-form-label-sm' : ''}>
      <input
        type="text"
        className={size === 'small' ? 'form-control-sm' : 'form-control'}
        value={value as string}
        name={name as string}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={removeError}
      />
    </FormControl>
  )
}

export default Input
