import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const FormLoader = (props: IContentLoaderProps) => (
  <ContentLoader speed={2} width={props.width} height={400} viewBox={`0 0 1000 400`} backgroundColor="#e2e3e4" foregroundColor="#f3f3f3" {...props}>
    <rect x="0" y="5" rx="10" ry="10" width="80" height="16" />
    <rect x="0" y="35" rx="10" ry="10" width="450" height="22" />
    <rect x="500" y="5" rx="10" ry="10" width="80" height="16" />
    <rect x="500" y="35" rx="10" ry="10" width="450" height="22" />

    <rect x="0" y="105" rx="10" ry="10" width="80" height="16" />
    <rect x="0" y="135" rx="10" ry="10" width="450" height="22" />
    <rect x="500" y="105" rx="10" ry="10" width="80" height="16" />
    <rect x="500" y="135" rx="10" ry="10" width="450" height="22" />

    <rect x="0" y="205" rx="10" ry="10" width="80" height="16" />
    <rect x="0" y="235" rx="10" ry="10" width="450" height="22" />
    <rect x="500" y="205" rx="10" ry="10" width="80" height="16" />
    <rect x="500" y="235" rx="10" ry="10" width="450" height="22" />

    <rect x="0" y="305" rx="10" ry="10" width="80" height="16" />
    <rect x="0" y="335" rx="10" ry="10" width="450" height="22" />
    <rect x="500" y="305" rx="10" ry="10" width="80" height="16" />
    <rect x="500" y="335" rx="10" ry="10" width="450" height="22" />
  </ContentLoader>
)

export default FormLoader
