import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { notifyMemberPrivacy } from '../../../api/member'
import { MemberAcceptanceRegistration, MemberAcceptanceRegistrationStatus, MemberResponse } from '../../../api/models/member'
import CustomToast from '../../common/CustomToast'

interface AcceptPrivacyFormSectionProps {
  id: MemberResponse['id']
  registration?: MemberAcceptanceRegistration
}

export const AcceptPrivacyFormSection = ({ id, registration }: AcceptPrivacyFormSectionProps) => {
  const { t } = useTranslation()

  const onClickResendAcceptance = () => {
    notifyMemberPrivacy(id!)
      .then((response) => {
        if (response.success) {
          return toast.custom(<CustomToast text={t('notifications.email_sent')} />)
        }
        return toast.custom(<CustomToast text={t('notifications.email_error')} />)
      })
      .catch(() => {
        return toast.custom(<CustomToast text={t('notifications.email_error')} />)
      })
  }

  return (
    <>
      <div className="row d-flex justify-content-end mb-2">
        <button title={t('accept_privacy.resend')} type="button" className="btn btn-primary btn-xs" onClick={onClickResendAcceptance}>
          <i className="fa fa-paper-plane mr-1"></i>
          {t('app.resend')}
        </button>
      </div>

      {registration && (
        <div className="row">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>{t('acceptance_registration.label.status')}</th>
                <th>{t('acceptance_registration.label.origin')}</th>
                <th>{t('acceptance_registration.label.date')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t(`acceptance_registration.status.${registration?.status}`)}</td>
                <td>
                  {registration.origin && registration.status === MemberAcceptanceRegistrationStatus.SIGNED
                    ? t('acceptance_registration.origin.email', { email: registration.origin })
                    : ''}
                </td>
                <td>{new Date(registration?.updatedAt).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default AcceptPrivacyFormSection
