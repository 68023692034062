import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { ParamType } from '../../api/models/params'
import Layout from '../../components/common/Layout'
import AttendanceLabelCard from '../../components/Params/AttendanceLabelCard'
import GlobalSettingsCard from '../../components/Params/GlobalSettingsCard'
import ParamCard from '../../components/Params/ParamCard'
import useNotGranted from '../../hooks/useNotGranted'

export const Parameters = () => {
  const { t } = useTranslation()

  const clubCode = useNotGranted('config.params')

  if (clubCode) {
    return <Navigate to={`/${clubCode}/unauthorized`} />
  }

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid pt-5">
          <h4 className="pb-3">{t('app.settings_config')}</h4>
          <div className="row">
            <GlobalSettingsCard />
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid pt-5">
          <h4 className="pb-3">{`${t('menu.members')} ${t('filter.and')} ${t('menu.preregistered')}`}</h4>
          <div className="row">
            <ParamCard title={t('members.origin')} type={ParamType.ORIGIN} />
            <ParamCard title={t('label.suspended_reason')} type={ParamType.SUSPENDED_REASON} />
            <ParamCard title={t('members.type')} type={ParamType.MEMBER_TYPE} />
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid pt-5">
          <h4 className="pb-3">{t('menu.attendance')}</h4>
          <div className="row">
            <AttendanceLabelCard title={t('attendance.labels')} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Parameters
