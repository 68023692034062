export const updateCssVariable = (variable: string, value: string) => {
  document.documentElement.style.setProperty(variable, value)
}

export const standarizeLanguage = (language: string) => {
  const lang = language.split('_')

  if (lang.length === 1) {
    return lang[0].toLowerCase()
  }

  return `${lang[0].toLowerCase()}_${lang[1]?.toUpperCase()}`
}
