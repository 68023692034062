import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

import { MemberResponse } from '../../../api/models/member'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import FormSectionHeader from '../../forms/components/FormSectionHeader'

export interface GroupMembersListProps {
  members: Partial<MemberResponse>[]
  openModal: () => void
  groupName?: string
}

export const GroupMembersList = ({ members = [], openModal, groupName }: GroupMembersListProps) => {
  const { t } = useTranslation()
  const { clubCode } = useClubConfigContext()

  const csvData = members?.length ? [['Name', 'Email'], ...members?.map(({ name, lastname, email }) => [`${name} ${lastname}`, email])] : []
  const fileName = `${clubCode}_${groupName}`.replace(/[^a-z0-9]/gi, '_').toLowerCase()

  return (
    <div className="card">
      <FormSectionHeader title={`${t('members.title')} (${members?.length || 0})`} includeAddButton addButtonAction={openModal} />
      <div className="card-body">
        {members.length > 0 && (
          <div className="col-12 d-flex justify-content-end">
            <CSVLink filename={`${fileName}.csv`} data={csvData} className="btn btn-secondary btn-sm mb-3">
              {t('files.export_csv')}
            </CSVLink>
          </div>
        )}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: 10 }}>#</th>
              <th>{t('label.name')}</th>
              <th>{t('label.email')}</th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member, key) => (
              <tr key={member.id}>
                <td>{key + 1}</td>
                <td>{`${member.name} ${member.lastname}`}</td>
                <td>{member.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default GroupMembersList
