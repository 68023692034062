import { z } from 'zod'

import { DocumentationType } from '../../api/models/common'
import { Gender } from '../../api/models/member'
import {
  customErrorMap,
  zCountries,
  zIBANValidation,
  zLaterality,
  zSpanishDocumentValidation,
  zStatus,
  zStringWithoutNumbers,
  zTownCharacters,
  zTutorString,
  zTutorStringNames,
  zTutorType,
  zTutorValidation,
  zValidPhoneNumber,
  zWeapon
} from './common'

export const zTutorObject = z.object({
  name: zTutorStringNames,
  lastname: zTutorStringNames,
  documentType: z.nativeEnum(DocumentationType).nullable().optional(),
  document: zTutorString,
  email: zTutorString.email(),
  phone: zTutorString,
  type: z.union([zTutorType, z.undefined()]).nullable().optional()
})

export const zGroupObject = z.object({
  description: z.string().optional(),
  id: z.number(),
  name: z.string(),
  totalMembers: z.number()
})

export const zPaymentDataObject = z.object({
  iban: z.string().min(15).max(29)
})

z.setErrorMap(customErrorMap)

// TODO as improve separate create and update validations
// FECHA LOGICA
// z.date().min(new Date("1900-01-01"), { message: "Too old" });
// z.date().max(new Date(), { message: "Too young!" });
export const memberSchema = z
  .object({
    id: z.union([z.string(), z.number()]).optional(),
    number: z.number().optional(),
    name: zStringWithoutNumbers,
    lastname: zStringWithoutNumbers,
    nationality: zCountries,
    documentType: z.nativeEnum(DocumentationType),
    documentValue: z.string().toUpperCase(),
    gender: z.nativeEnum(Gender),
    birthdate: z.coerce.date(),
    email: z.string().email(),
    email2: z.string().email().optional().nullable(),
    phone: z.string(),
    phone2: z.string().optional().nullable(),
    laterality: zLaterality.optional().nullable(),
    weapon: z.union([zWeapon, z.undefined()]).optional().nullable(),
    status: zStatus,
    createdAt: z.coerce.date().optional().nullable(),
    suspendedAt: z.coerce.date().optional().nullable(),
    suspendedReason: z.coerce.number().optional().nullable(),
    origin: z.coerce.number().optional().nullable(),
    address: z.string().min(7),
    town: zTownCharacters,
    postalCode: z.string().regex(/^[0-5][0-9]{4}$/),
    country: zCountries,
    tutor: z.union([zTutorObject, z.undefined()]).nullable(),
    tutor2: z.union([zTutorObject, z.undefined()]).nullable(),
    authorizeImage: z.boolean().optional(),
    authorizeCorreo: z.boolean().optional(),
    authorizeMail: z.boolean().optional(),
    comments: z.string().optional().nullable(),
    type: z.coerce.number().optional().nullable(),
    groups: z.array(zGroupObject).optional(),
    paymentData: z.union([zPaymentDataObject, z.undefined()]).nullable()
  })
  .superRefine(zValidPhoneNumber)
  .superRefine(zSpanishDocumentValidation)
  .superRefine(zIBANValidation)
  .superRefine(zTutorValidation)

export type Member = z.infer<typeof memberSchema>
