import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Module } from '../../../../api/models/permission'
import { RoleResponse } from '../../../../api/models/role'
import { getModules } from '../../../../api/role'
import { CheckboxStatus } from '../../../../utils/checkboxStatus'
import CustomCallable from '../../../common/CustomCallable'
import FormSectionHeader from '../../components/FormSectionHeader'
import NestedCheckbox from './NestedCheckbox'

interface MenuConfigurationProps {
  role: Partial<RoleResponse>
  setNewModules: Dispatch<SetStateAction<Module[]>>
}

export const MenuConfiguration = ({ role, setNewModules }: MenuConfigurationProps) => {
  const { t } = useTranslation()

  const prevRoleRef = useRef<Partial<RoleResponse>>(role)
  const [modules, setModules] = useState<Module[]>([])
  const [selectedModules, setSelectedModules] = useState<Record<number, CheckboxStatus>>({})
  const [allModules, setAllModules] = useState<Module[]>([])

  useEffect(() => {
    setNewModules(allModules.filter((module) => [CheckboxStatus.CHECKED, CheckboxStatus.INDETERMINATE].includes(selectedModules[module.id])))
  }, [selectedModules])

  useEffect(() => {
    if (!role?.modules) return
    if (!prevRoleRef.current.modules && role?.modules && allModules.length) {
      setSelectedModules(
        allModules.reduce((acc: Record<number, CheckboxStatus>, module: Module) => {
          acc[module.id] = role.modules?.some((roleModule) => roleModule.id === module.id) ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED
          return acc
        }, {})
      )
      prevRoleRef.current = role
    }
  }, [role, allModules])

  useEffect(() => {
    getModules().then((response) => {
      setModules(response)
      setSelectedModules(
        response.reduce((acc: Record<number, CheckboxStatus>, module: Module) => {
          acc[module.id] = role.modules?.some((roleModule) => roleModule.id === module.id) ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED
          if (module.subModules) {
            module.subModules.forEach((subModule: Module) => {
              acc[subModule.id] = role.modules?.some((roleModule) => roleModule.id === subModule.id)
                ? CheckboxStatus.CHECKED
                : CheckboxStatus.UNCHECKED
            })
          }
          return acc
        }, {})
      )
      setAllModules(response.reduce((acc: Module[], module: Module) => [...acc, module, ...(module.subModules || [])], []))
    })
  }, [])

  const checkParentChecked = (subModule: Module, isChecked: CheckboxStatus) => {
    const module = modules.find((module) => module.subModules?.some((sub) => sub.id === subModule.id))
    const subModulesLength = module?.subModules?.length || 0

    const numberOfCheckedSubModules = module?.subModules?.filter((subModule) => selectedModules[subModule.id]).length || 0

    if (isChecked === CheckboxStatus.CHECKED && numberOfCheckedSubModules + 1 === subModulesLength) {
      return CheckboxStatus.CHECKED
    }
    if (isChecked === CheckboxStatus.UNCHECKED && numberOfCheckedSubModules === 0) {
      return CheckboxStatus.UNCHECKED
    }
    return CheckboxStatus.INDETERMINATE
  }

  return (
    <div className={`card ${role.id ? 'collapsed-card' : ''}`}>
      <FormSectionHeader title={t('roles.menu_options.title')} startCollapsed={!!role.id} />
      <div className="card-body">
        <div className="row">
          <CustomCallable text={t('roles.menu_options.help')} />
          {modules.map((module: Module) => (
            <div key={module.id}>
              <ul>
                <li className="form-check" style={{ minWidth: 250 }}>
                  <NestedCheckbox
                    id={module.id}
                    checked={
                      !!(selectedModules[module.id] ||
                      (module.subModules?.length && module.subModules?.every((subModule) => selectedModules[subModule.id]))
                        ? CheckboxStatus.CHECKED
                        : CheckboxStatus.UNCHECKED)
                    }
                    indeterminate={
                      (module.subModules?.some((subModule) => selectedModules[subModule.id]) &&
                        !module.subModules?.every((subModule) => selectedModules[subModule.id])) ||
                      false
                    }
                    onChange={() => {
                      const newStatus = selectedModules[module.id] ? CheckboxStatus.UNCHECKED : CheckboxStatus.CHECKED
                      setSelectedModules((prev) => ({ ...prev, [module.id]: newStatus }))
                      if (module.subModules) {
                        const newObject = module?.subModules?.reduce((acc: Record<number, CheckboxStatus>, subModule: Module) => {
                          acc[subModule.id] = newStatus
                          return acc
                        }, {})
                        setSelectedModules((prev) => ({ ...prev, ...newObject }))
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor={`module-${module.id}`}>
                    {t(`menu.${module.name.toLowerCase()}`)}
                  </label>
                  {module.subModules && (
                    <ul className="pl-2">
                      {module.subModules.map((subModule: Module) => (
                        <li key={subModule.id} className="form-check">
                          <NestedCheckbox
                            id={subModule.id}
                            checked={!!selectedModules[subModule.id]}
                            indeterminate={false}
                            onChange={() => {
                              const newValue =
                                selectedModules[subModule.id] === CheckboxStatus.CHECKED ? CheckboxStatus.UNCHECKED : CheckboxStatus.CHECKED
                              const parentStatus = checkParentChecked(subModule, newValue)

                              setSelectedModules((prev) => ({ ...prev, [module.id]: parentStatus, [subModule.id]: newValue }))
                            }}
                          />
                          <label className="form-check-label" htmlFor={`module-${subModule.id}`}>
                            {t(`menu.${subModule.name.toLowerCase()}`)}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MenuConfiguration
