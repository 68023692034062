import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

import { subYears } from 'date-fns'
import ca from 'date-fns/locale/ca'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import DatePicker, { registerLocale } from 'react-datepicker'

import { Field } from '../../../../api/models/club'
import useAuthContext from '../../../../contexts/hooks/useAuthContext'
import { DEFAULT_COLUMN_SIZE } from '../../../../helpers/layout'
import { DEFAULT_LANGUAGE } from '../../../../translations'
import FormControl from '../FormControl'

registerLocale('es_ES', es)
registerLocale('ca_ES', ca)
registerLocale('en_GB', en)

interface InputDateProps extends Omit<Field<any>, 'type'> {
  value?: Date
  columnSize?: number
  onChange: (_: Date) => void
  error?: string
  removeError?: () => void
}

export const InputDate = ({
  label,
  required,
  disabled = false,
  value,
  columnSize = DEFAULT_COLUMN_SIZE,
  onChange,
  error,
  removeError
}: InputDateProps) => {
  const { user } = useAuthContext()

  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error}>
      <div className="input-group date" id="birthDate" data-target-input="nearest">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          className="form-control input-sm"
          selected={value}
          onChange={onChange}
          locale={user?.lang! || DEFAULT_LANGUAGE}
          disabled={disabled}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          onFocus={removeError}
          minDate={subYears(new Date(), 100)}
          maxDate={new Date()}
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <i className="fa fa-calendar"></i>
          </div>
        </div>
      </div>
    </FormControl>
  )
}

export default InputDate
