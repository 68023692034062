import { Option } from '../../api/models/club'
import { Country, DocumentationType, FieldType, FormSectionElement, Laterality, TutorType } from '../../api/models/common'
import { Gender, MemberResponse, Tutor } from '../../api/models/member'

interface MemberFormConfig {
  originOptions: Option[]
}

export const preinscriptionForm: (_: MemberFormConfig) => FormSectionElement<MemberResponse & Tutor>[] = ({ originOptions }) => {
  return [
    {
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname',
          required: true
        },
        {
          type: FieldType.SELECT,
          name: 'nationality',
          label: 'members.nationality',
          options: Object.keys(Country).map((country) => ({ value: country, label: 'common.countries.' + country })),
          required: true
        },
        {
          type: FieldType.INPUT_GROUP,
          name: 'documentValue',
          nameSelect: 'documentType',
          label: 'label.document',
          options: Object.values(DocumentationType).map((documentType) => ({ value: documentType, label: 'register.' + documentType.toLowerCase() })),
          required: true
        },

        {
          type: FieldType.SELECT,
          name: 'gender',
          label: 'label.gender',
          options: Object.values(Gender).map((gender) => ({ value: gender, label: 'members.' + gender })),
          required: true
        },
        {
          type: FieldType.DATE,
          name: 'birthdate',
          label: 'label.birthdate',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          required: true
        },
        {
          type: FieldType.PHONE,
          name: 'phone',
          label: 'label.phone',
          required: true
        },

        {
          type: FieldType.TEXT,
          name: 'address',
          label: 'label.address',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'town',
          label: 'label.town',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'postalCode',
          label: 'label.postal_code',
          required: true
        },
        {
          type: FieldType.SELECT,
          name: 'laterality',
          label: 'members.laterality',
          options: Object.keys(Laterality).map((laterality) => ({ value: laterality, label: 'common.laterality.' + laterality })),
          required: true
        },
        {
          type: FieldType.SELECT,
          name: 'origin',
          label: 'members.origin',
          options: originOptions,
          required: true
        }
      ]
    },
    {
      title: 'members.tutors_info',
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          objectKey: 'tutor'
        },

        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname',
          objectKey: 'tutor'
        },
        {
          type: FieldType.SELECT,
          name: 'type',
          label: 'members.tutors.type',
          options: Object.values(TutorType).map((tutorType) => ({ value: tutorType, label: 'members.tutors_type.' + tutorType })),
          objectKey: 'tutor'
        },

        {
          type: FieldType.INPUT_GROUP,
          name: 'document',
          nameSelect: 'documentType',
          label: 'label.document',
          options: Object.values(DocumentationType).map((documentType) => ({ value: documentType, label: 'register.' + documentType.toLowerCase() })),
          objectKey: 'tutor'
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          objectKey: 'tutor'
        },
        {
          type: FieldType.PHONE,
          name: 'phone',
          label: 'label.phone',
          objectKey: 'tutor'
        }
      ]
    },
    {
      title: 'members.tutors_info',
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          objectKey: 'tutor2'
        },

        {
          type: FieldType.TEXT,
          name: 'lastname',
          label: 'label.lastname',
          objectKey: 'tutor2'
        },
        {
          type: FieldType.SELECT,
          name: 'type',
          label: 'members.tutors.type',
          options: Object.values(TutorType).map((tutorType) => ({ value: tutorType, label: 'members.tutors_type.' + tutorType })),
          objectKey: 'tutor2'
        },

        {
          type: FieldType.INPUT_GROUP,
          name: 'document',
          nameSelect: 'documentType',
          label: 'label.document',
          options: Object.keys(DocumentationType).map((documentType) => ({ value: documentType, label: 'register.' + documentType.toLowerCase() })),
          objectKey: 'tutor2'
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          objectKey: 'tutor2'
        },
        {
          type: FieldType.PHONE,
          name: 'phone',
          label: 'label.phone',
          objectKey: 'tutor2'
        }
      ]
    }
  ]
}
