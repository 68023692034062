import { useEffect, useState } from 'react'

import { RoleResponse } from '../../../api/models/role'
import { getRoles } from '../../../api/role'
import ListTitle from '../../common/ListTitle'
import RoleListItem from '../RoleListItem'

const fields = ['name', 'description']

export const RoleList = () => {
  const [roles, setRoles] = useState<Array<RoleResponse>>([])

  useEffect(() => {
    getRoles().then((response) => {
      setRoles(response)
    })
  }, [])

  return (
    <div className="card-body">
      <table className="float-right table table-bordered table-striped">
        <ListTitle fields={fields} />
        <tbody>
          {roles.map((role) => (
            <RoleListItem key={role.id} {...role} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default RoleList
