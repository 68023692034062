import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { ClubResponse } from '../../../api/models/club'
import { FormErrors, FormSectionElement } from '../../../api/models/common'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { mainUserForm } from '../../../helpers/forms/club'
import FormLoader from '../../loaders/FormLoader'
import FormSection from '../components/FormSection'

export interface ClubFormProps {
  club: Partial<ClubResponse>
  setClub: Dispatch<SetStateAction<Partial<ClubResponse>>>
  formConfig: FormSectionElement<ClubResponse>[]
  errors: FormErrors<ClubResponse>
  handleSubmit: () => void
  loading?: boolean
  isCreate?: boolean
}

export const ClubForm = ({ club, setClub, errors, formConfig, handleSubmit, loading, isCreate }: ClubFormProps) => {
  const { t } = useTranslation()
  const { isMasterUser } = useAuthContext()

  return (
    <div className="container-fluid mt-3">
      {loading ? (
        <FormLoader
          width={'100%'}
          style={{ background: 'white', padding: '1.25rem', borderRadius: 5, boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)' }}
        />
      ) : (
        <>
          {formConfig.map((section, key) => (
            <FormSection
              key={section.title}
              firstElement={key === 0}
              startCollapsed={key !== 0}
              title={t(section.title!) || ''}
              fields={section.fields}
              formObject={club}
              setFormObject={setClub}
              errors={errors}
            />
          ))}
          {isMasterUser && isCreate && (
            <FormSection
              startCollapsed={true}
              title={t('users.title_main')}
              fields={mainUserForm}
              formObject={club}
              setFormObject={setClub}
              errors={errors}
            />
          )}
        </>
      )}
      <div className="col-12 mt-2 card-body">
        <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit}>
          {t('app.save')}
        </button>
      </div>
    </div>
  )
}

export default ClubForm
