import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getInfoRequests } from '../../api/contact'
import { InfoRequestResponse, InfoRequestStatus } from '../../api/models/contact'
import InfoRequestFragment from '../../components/CommercialManagement/InfoRequestFragment'
import Layout from '../../components/common/Layout'
import { getRoute } from '../../router/routes'
import { InfoRequestRouterPaths } from '../../router/routes/infoRequest'

export const ListInfoRequest = () => {
  const { t } = useTranslation()

  const { clubCode } = useParams<{ clubCode: string }>()

  const [infoRequests, setInfoRequests] = useState<InfoRequestResponse[]>([])

  useEffect(() => {
    getInfoRequests().then((response) => {
      setInfoRequests(response)
    })
  }, [])

  return (
    <Layout>
      <section className="content">
        <div className="px-4">
          <h1 className="pt-3">{t('info_request.title')}</h1>
          <a href={getRoute(InfoRequestRouterPaths.CREATE_INFO_REQUEST, clubCode)} className="float-right btn btn-primary">
            <i className={`fa fa-plus mr-2`}></i>
            {t('info_request.create')}
          </a>
        </div>
        <div className="container-fluid pt-5">
          <div className="row">
            {infoRequests?.map((infoRequest) => (
              <div
                key={infoRequest.id}
                className={`row border rounded m-2 p-2 w-100 bg-white ${infoRequest.status === InfoRequestStatus.OPENED ? 'border-primary bg-unread shadow-sm' : ''}`}
              >
                <InfoRequestFragment {...infoRequest} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ListInfoRequest
