import axios, { AxiosResponse } from 'axios'

type IBANValidationResponse = {
  valid: boolean
  messages: string[]
  iban: string
  bankData: {
    bankCode: string
    name: string
  }
  checkResults: {
    bankCode: boolean
  }
}

export const IBAN_URL = 'https://openiban.com/validate'

export const isValidIBANNumber: (iban: string) => Promise<IBANValidationResponse> = (iban) => {
  return axios.get<IBANValidationResponse>(`${IBAN_URL}/${iban}`).then((response: AxiosResponse) => response?.data)
}
