// @ts-nocheck
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { getMemberDocuments } from '../../../api/member'
import { FileType } from '../../../api/models/files'
import { MemberDocument } from '../../../api/models/member'
import { deleteFile, uploadFile } from '../../../api/uploadFiles'
import CustomToast from '../../../components/common/CustomToast'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import FormSectionHeader from '../../forms/components/FormSectionHeader'
import Input from '../../forms/components/Input'
import InputFile from '../../forms/components/InputFile'

export interface MemberDocumentationProps {
  memberId: number
}

export const MemberDocumentation = ({ memberId }: MemberDocumentationProps) => {
  const { t } = useTranslation()
  const { clubCode } = useClubConfigContext()

  const inputRef = useRef<HTMLInputElement>()
  const [loading, setLoading] = useState<boolean>(false)
  const [fileDocument, setFileDocument] = useState<File | null>(null)
  const [filename, setFilename] = useState<string>('')
  const [documents, setDocuments] = useState<Array<MemberDocument>>([])

  const handleSubmit = () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', fileDocument!)
    formData.append('fileName', filename)
    formData.append('clubCode', clubCode!)
    formData.append('id', memberId.toString())
    formData.append('type', FileType.MEMBER)

    uploadFile(formData)
      .then((res) => {
        if (res.success) {
          toast.custom(<CustomToast text={t('notifications.member.documentation_created')} />)
          setFileDocument(null)
          setFilename('')
          if (inputRef.current) inputRef.current.value = ''
          setDocuments(res.data)
        }
      })
      .finally(() => setLoading(false))
  }

  const onClickDelete = (documentId: number) => {
    setLoading(true)
    deleteFile({ id: documentId, type: FileType.MEMBER })
      .then((res) => {
        if (res.success) {
          toast.custom(<CustomToast text={t('notifications.member.documentation_removed')} />)
          setDocuments(res.data)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getMemberDocuments(memberId).then((response) => setDocuments(response))
  }, [memberId])

  useEffect(() => {
    if (fileDocument) {
      setFilename(fileDocument.name)
    }
  }, [fileDocument])

  return (
    <div className="card collapsed-card">
      <FormSectionHeader title={t('members.documentation')} startCollapsed />
      <div className="card-body">
        {loading && (
          <div className="text-center loader-container overlay flex-column">
            <i className="fas fa-3x fa-sync-alt mb-2"></i>
            <span>{t('app.loading')}</span>
          </div>
        )}
        <div className="row mb-4">
          {documents?.map((document, key) => (
            <>
              <div className="badge badge-info badge-lg mr-2 mb-2 cursor-pointer" key={key} data-tooltip-id={`document-action-${document.id}`}>
                <i className="fa fa-file-pdf-o mr-1" />
                {document.name}
              </div>
              <Tooltip id={`document-action-${document.id}`} place="bottom" className="p-2 rounded" style={{ zIndex: 999 }} clickable>
                <a
                  className="btn btn-default btn-sm mr-1"
                  href={`${process.env.REACT_APP_LOGO_BASE_URL}${document.document}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-eye" />
                </a>
                <button className="btn btn-default btn-sm" onClick={() => onClickDelete(document.id)}>
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>
            </>
          ))}
        </div>
        <div className="row justify-content-between">
          <div className="d-flex row flex-1 align-items-start">
            {/* @ts-ignore */}
            <InputFile
              label={t('members.upload_document')}
              onChange={(e) => setFileDocument(e.target.files[0])}
              columnSize={5}
              inputRef={inputRef}
              helpText={t('help_text.document', {
                size: '2MB',
                formats: 'pdf, doc, docx, xls, xlsx, odt, ods, txt, rtf, csv, jpg, jpeg, png, svg, gif'
              })}
            />

            <Input
              label={t('label.filename')}
              name={'filename'}
              value={filename}
              onChange={(e) => setFilename(e.target.value)}
              columnSize={6}
              placeholder={t('label.filename')}
            />
          </div>
          <button type="submit" className="float-right btn btn-primary text-sm" style={{ maxHeight: 40, marginTop: 32 }} onClick={handleSubmit}>
            {t('app.save') + ' ' + t('members.documentation')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MemberDocumentation
