import { formatDistanceToNowStrict } from 'date-fns'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Notification, NotificationType } from '../../../api/models/user'
import { markNotificationAsRead } from '../../../api/notification'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import useNotifications from '../../../hooks/useNotifications'
import { getLanguageLocale } from '../../../translations'

export const NotificationFragment = ({ id, data, type, createdAt }: Notification) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setNotifications } = useAuthContext()
  const { notifications } = useNotifications()

  const clubCode = useParams<{ clubCode: string }>().clubCode

  const notificationConfig = {
    [NotificationType.PREREGISTRATION]: {
      icon: 'fa-user',
      message: 'show_notifications.preregistration',
      link: `/${clubCode}/member/${data?.id || 0}/edit`
    },
    [NotificationType.ACCEPT_PRIVACY]: {
      icon: 'fa-user-shield',
      message: 'show_notifications.accept_privacy',
      link: `/${clubCode}/member/${data?.id || 0}/edit`
    },
    [NotificationType.INFO_REQUEST]: {
      icon: 'fa-envelope',
      message: 'show_notifications.info_request',
      link: `/${clubCode}/commercial-management/info-request/${data?.id || 0}/edit`
    }
  }

  return (
    <Fragment>
      <a
        onClick={() => {
          setNotifications(notifications?.filter((notification) => notification.id !== id) || [])
          markNotificationAsRead(id).then((response) => {
            if (response?.success) {
              setNotifications(response.data)
            }
          })
          navigate(notificationConfig[type]?.link || '/')
        }}
        className="dropdown-item"
      >
        <div className="row justify-content-between align-items-center">
          <div className="d-flex pr-3 text-break text-wrap flex-1 text-sm align-items-center">
            <i className={`fas ${notificationConfig[type]?.icon || 'fa-envelope'} mr-2`}></i>
            <span
              dangerouslySetInnerHTML={{
                __html: t(notificationConfig[type]?.message || '', {
                  name: data?.name,
                  lastname: data?.lastname,
                  interpolation: { escapeValue: false }
                })
              }}
            />
          </div>
          <span className="text-muted text-sm">{formatDistanceToNowStrict(new Date(createdAt), { locale: getLanguageLocale() })}</span>
        </div>
      </a>
      <div className="dropdown-divider"></div>
    </Fragment>
  )
}

export default NotificationFragment
