import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const TableLoader = (props: IContentLoaderProps) => (
  <ContentLoader speed={2} width={props.width} height={400} viewBox="0 0 1200 400" backgroundColor="#e2e3e4" foregroundColor="#f3f3f3" {...props}>
    <rect x="27" y="40" rx="4" ry="4" width="20" height="20" />
    <rect x="67" y="40" rx="10" ry="10" width="85" height="19" />
    <rect x="188" y="40" rx="10" ry="10" width="169" height="19" />
    <rect x="402" y="40" rx="10" ry="10" width="85" height="19" />
    <rect x="523" y="40" rx="10" ry="10" width="169" height="19" />
    <rect x="731" y="40" rx="10" ry="10" width="85" height="19" />
    <rect x="852" y="40" rx="10" ry="10" width="85" height="19" />
    <rect x="977" y="40" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="90" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="90" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="90" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="90" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="90" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="90" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="90" rx="10" ry="10" width="85" height="19" />
    <rect x="977" y="90" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="140" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="140" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="140" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="140" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="140" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="140" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="140" rx="10" ry="10" width="85" height="19" />
    <rect x="977" y="140" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="190" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="190" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="190" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="190" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="190" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="190" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="190" rx="10" ry="10" width="85" height="19" />
    <rect x="977" y="190" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="240" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="240" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="240" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="240" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="240" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="240" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="240" rx="10" ry="10" width="85" height="19" />
    <rect x="977" y="240" rx="10" ry="10" width="169" height="19" />
  </ContentLoader>
)

export default TableLoader
