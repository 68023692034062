import './styles.css'

import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'

// TODO include club logo
export const HeaderLogo = () => {
  const { logo, clubName } = useClubConfigContext()
  return (
    <div className="brand-link py-2 d-flex align-items-center">
      {logo ? (
        <img
          src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`}
          alt="Club Logo"
          className="brand-image img-circle elevation-2 p-1"
          style={{ backgroundColor: 'white', marginLeft: 0, marginBottom: 0, width: 45, height: 45, maxWidth: 45, maxHeight: 45 }}
        />
      ) : (
        <img
          src={require('../../../assets/img/skermo_solo_sm_trans_horizontal.png')}
          alt="Skermo Logo"
          className="brand-image img-circle elevation-3 p-1"
          style={{ opacity: 8, backgroundColor: 'white', marginTop: 0, marginBottom: 0 }}
        />
      )}

      <span className="brand-text font-weight-bold text-wrap text-md ">{clubName}</span>
    </div>
  )
}

export default HeaderLogo
