import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getChangelog } from '../../../api/changelog'
import { Changelog } from '../../../api/models/changelog'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import ChangelogInput from '../../Changelog/ChangelogInput'
import ChangelogItem from '../../Changelog/ChangelogItem'
import FormSectionHeader from '../../forms/components/FormSectionHeader'

export const ChangelogCard = () => {
  const { t } = useTranslation()
  const { isMasterUser } = useAuthContext()

  const [changelog, setChangelog] = useState<Array<Changelog>>([])

  useEffect(() => {
    getChangelog().then((response) => {
      setChangelog(response)
    })
  }, [])

  return (
    <div className="card card-custom card-outline col-12">
      <FormSectionHeader title={t('menu.changelog')} includeCollapseButton />

      <div className="card-body">
        <div className="d-flex"></div>
        <table className="table table-sm">
          <tbody>
            {isMasterUser && <ChangelogInput setChangelog={setChangelog} />}
            <div className="pre-scrollable">
              {changelog.map((log, index) => (
                <ChangelogItem key={index} {...log} />
              ))}
            </div>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ChangelogCard
