import { RouteObject } from 'react-router-dom'

import Attendance from '../pages/Attendance/Attendance'
import Changelog from '../pages/Changelog'
import CreateClub from '../pages/Club/CreateClub'
import ListClubs from '../pages/Club/ListClubs'
import Dashboard from '../pages/Dashboard'
import Contact from '../pages/Forms/Contact'
import CreateGroup from '../pages/Group/CreateGroup'
import ListGroups from '../pages/Group/ListGroups'
import Login from '../pages/Login'
import AcceptPrivacy from '../pages/Member/AcceptPrivacy'
import Preinscription from '../pages/Member/Presinscription'
import NotAllowed from '../pages/NotAllowed'
import NotGranted from '../pages/NotGranted'
import Notifications from '../pages/Notifications'
import CreateRole from '../pages/Role/CreateRole'
import ListRoles from '../pages/Role/ListRoles'
import FormsConfiguration from '../pages/Settings/FormsConfiguration'
import Integrations from '../pages/Settings/Integrations'
import Parameters from '../pages/Settings/Parameters'
import CreateUser from '../pages/User/CreateUser'
import ListUsers from '../pages/User/ListUsers'
import { infoRequestRoutes } from './routes/infoRequest'
import { memberRoutes } from './routes/member'
import { reportsRoutes } from './routes/reports'

export enum RouterPaths {
  LOGIN = ':clubCode/login',
  UNAUTHORIZED = ':clubCode/unauthorized',
  NOT_ALLOWED = ':clubCode/not-allowed',
  PREINSCRIPTION_TRANSLATED = ':clubCode/preinscription/:lang',
  PREINSCRIPTION = ':clubCode/preinscription',
  CONTACT_TRANSLATED = ':clubCode/contact/:lang',
  CONTACT = ':clubCode/contact',
  ACCEPT_PRIVACY = ':clubCode/accept-privacy',
  DASHBOARD = ':clubCode/dashboard',

  LIST_CLUBS = '/master/clubs',
  ADD_CLUB = ':clubCode/club/create',
  EDIT_CLUB = '/master/club/:clubCode/edit',
  LIST_USERS = ':clubCode/users',
  ADD_USER = ':clubCode/user/create',
  EDIT_USER = ':clubCode/user/:id/edit',
  EDIT_PROFIE = ':clubCode/profile/edit',
  EDIT_MY_CLUB = ':clubCode/my-club',
  LIST_GROUPS = ':clubCode/groups',
  ADD_GROUP = ':clubCode/group/create',
  EDIT_GROUP = ':clubCode/group/:id/edit',
  LIST_ROLES = ':clubCode/roles',
  ADD_ROLE = ':clubCode/role/create',
  EDIT_ROLE = ':clubCode/role/:id/edit',

  ATTENDANCE_SHEET = ':clubCode/attendance',

  SETTINGS_PARAMS = ':clubCode/settings/params',
  INTEGRATIONS = ':clubCode/settings/integrations',
  FORMS_CONFIGURATION = ':clubCode/settings/forms',

  CHANGELOG = 'master/changelog',

  SHOW_NOTIFICATIONS = ':clubCode/profile/notifications'
}

export const routes: RouteObject[] = [
  {
    path: RouterPaths.LOGIN,
    Component: Login
  },
  {
    path: RouterPaths.UNAUTHORIZED,
    Component: NotGranted
  },
  {
    path: RouterPaths.NOT_ALLOWED,
    Component: NotAllowed
  },
  {
    path: RouterPaths.PREINSCRIPTION_TRANSLATED,
    Component: Preinscription
  },
  {
    path: RouterPaths.PREINSCRIPTION,
    Component: Preinscription
  },
  {
    path: RouterPaths.CONTACT_TRANSLATED,
    Component: Contact
  },
  {
    path: RouterPaths.CONTACT,
    Component: Contact
  },
  {
    path: RouterPaths.ACCEPT_PRIVACY,
    Component: AcceptPrivacy
  },
  {
    path: RouterPaths.DASHBOARD,
    Component: Dashboard
  },
  ...memberRoutes,
  {
    path: RouterPaths.LIST_CLUBS,
    Component: ListClubs
  },
  {
    path: RouterPaths.ADD_CLUB,
    Component: CreateClub
  },
  {
    path: RouterPaths.EDIT_CLUB,
    Component: CreateClub
  },
  {
    path: RouterPaths.EDIT_MY_CLUB,
    Component: CreateClub
  },
  {
    path: RouterPaths.LIST_USERS,
    Component: ListUsers
  },
  {
    path: RouterPaths.ADD_USER,
    Component: CreateUser
  },
  {
    path: RouterPaths.EDIT_USER,
    Component: CreateUser
  },
  {
    path: RouterPaths.EDIT_PROFIE,
    Component: CreateUser
  },
  {
    path: RouterPaths.LIST_GROUPS,
    Component: ListGroups
  },
  {
    path: RouterPaths.EDIT_GROUP,
    Component: CreateGroup
  },
  {
    path: RouterPaths.ADD_GROUP,
    Component: CreateGroup
  },
  {
    path: RouterPaths.LIST_ROLES,
    Component: ListRoles
  },
  {
    path: RouterPaths.EDIT_ROLE,
    Component: CreateRole
  },
  {
    path: RouterPaths.ADD_ROLE,
    Component: CreateRole
  },
  {
    path: RouterPaths.ATTENDANCE_SHEET,
    Component: Attendance
  },
  {
    path: RouterPaths.SETTINGS_PARAMS,
    Component: Parameters
  },
  {
    path: RouterPaths.INTEGRATIONS,
    Component: Integrations
  },
  {
    path: RouterPaths.FORMS_CONFIGURATION,
    Component: FormsConfiguration
  },

  {
    path: RouterPaths.SHOW_NOTIFICATIONS,
    Component: Notifications
  },
  {
    path: RouterPaths.CHANGELOG,
    Component: Changelog
  },
  ...reportsRoutes,
  ...infoRequestRoutes
]

export const getRoute = (path: string, clubCode?: string) => {
  return path.replace(':clubCode/', clubCode ? `/${clubCode}/` : '')
}
