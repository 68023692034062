import './styles.css'

import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { FormErrors } from '../../../api/models/common'
import { InfoRequestResponse } from '../../../api/models/contact'
import { InfoRequestFormType } from '../../../types'
import Input from '../components/Input'
import ChatMessages from './components/ChatMessages'
import OriginSelect from './components/OriginSelect'
import StatusSelect from './components/StatusSelect'

export interface InfoRequestFormProps {
  infoRequest: InfoRequestFormType
  setInfoRequest: Dispatch<SetStateAction<InfoRequestFormType>>
  errors: FormErrors<InfoRequestResponse>
  handleSubmit: () => void
  temporalMembers?: number[]
}

export const InfoRequestForm = ({ infoRequest, setInfoRequest, errors, handleSubmit }: InfoRequestFormProps) => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid mt-3">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <Input
              label={t('label.name')}
              required={true}
              disabled={false}
              value={infoRequest.name}
              onChange={(e) => setInfoRequest((prev) => ({ ...prev, name: e.target.value }))}
              // error={''}
              columnSize={12}
              removeError={() => {}}
            />
            <Input
              label={t('label.email')}
              required={true}
              disabled={false}
              value={infoRequest.email}
              onChange={(e) => setInfoRequest((prev) => ({ ...prev, email: e.target.value }))}
              // error={''}
              removeError={() => {}}
            />
            <Input
              label={t('label.phone')}
              required={false}
              disabled={false}
              value={infoRequest.phone}
              onChange={(e) => setInfoRequest((prev) => ({ ...prev, phone: e.target.value }))}
              // error={''}
              removeError={() => {}}
            />
            <OriginSelect infoRequest={infoRequest} setInfoRequest={setInfoRequest} />
            <StatusSelect infoRequest={infoRequest} setInfoRequest={setInfoRequest} />
          </div>
        </div>
      </div>
      <ChatMessages infoRequest={infoRequest} setInforequest={setInfoRequest} isCreateForm={!!infoRequest.id} />
      <div className="col-12 mt-2 card-body">
        <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit}>
          {t('app.save')}
        </button>
      </div>
    </div>
  )
}

export default InfoRequestForm
