import { ZodIssue } from 'zod'

import { FormErrors } from '../../api/models/common'

export const handleValidationErrors = (issues: Array<ZodIssue>) => {
  let errors: FormErrors<any> = {}
  issues?.forEach((validation) => {
    if ('unionErrors' in validation) {
      const unionIssues: ZodIssue[] = validation.unionErrors[0]?.issues
      const unionErrors = handleValidationErrors(unionIssues)
      errors = { ...errors, ...unionErrors }
    } else {
      errors = { ...errors, ...buildErrorElement(validation) }
    }
  })
  return errors
}

const buildErrorElement: (validation: ZodIssue) => FormErrors<any> = (validation: ZodIssue) => {
  const path = validation.path.length > 1 ? `${validation.path[0]}.${validation.path[1]}` : validation.path[0]
  const message = validation.message
  return { [path]: message }
}
