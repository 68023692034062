/* eslint-disable simple-import-sort/imports */
import { PermissionGroup, RoleResponse } from '../../../api/models/role'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import { RouterPaths, getRoute } from '../../../router/routes'

export const RoleListItem = (role: RoleResponse) => {
  const { id, name, description } = role

  const { permissions } = useAuthContext()

  return (
    <tr>
      <td>{name}</td>
      <td>{description}</td>
      <td>
        {permissions?.includes(`${PermissionGroup.ROLE}.edit`) && (
          <a href={getRoute(RouterPaths.EDIT_ROLE.replace(':id', id?.toString()!))}>
            <i className="fas fa-edit"></i>
          </a>
        )}
      </td>
    </tr>
  )
}

export default RoleListItem
