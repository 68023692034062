import { Navigate } from 'react-router-dom'

import Layout from '../../components/common/Layout'
import { IntegrationCard } from '../../components/Integrations/IntegrationCard'
import useNotGranted from '../../hooks/useNotGranted'

export const Integrations = () => {
  const clubCode = useNotGranted('config.integrations')

  if (clubCode) {
    return <Navigate to={`/${clubCode}/unauthorized`} />
  }

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid pt-5">
          <IntegrationCard />
        </div>
      </section>
    </Layout>
  )
}

export default Integrations
