import '../styles.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { getAttendanceLabels } from '../../../../../api/attendance'
import { AttendanceLabelResponse } from '../../../../../api/models/attendance'
import { AttendanceReportMember, AttendanceReportResponse } from '../../../../../api/models/reports'
import { getAttendanceMonthlyReport } from '../../../../../api/reports'

interface MonthReportTableProps {
  id: number
}

export const MonthReportTable = ({ id }: MonthReportTableProps) => {
  const { t } = useTranslation()

  const [attendanceReport, setAttendanceReport] = useState<AttendanceReportResponse | null>(null)
  const [attendanceLabels, setAttendanceLabels] = useState<AttendanceLabelResponse[]>([])

  useEffect(() => {
    getAttendanceLabels().then((response) => {
      setAttendanceLabels(response)
    })
    getAttendanceMonthlyReport({ id }).then((response) => {
      setAttendanceReport(response)
    })
  }, [])

  const today = new Date()
  const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()

  if (!id || !attendanceReport) {
    return null
  }

  return (
    <div className="overflow-auto">
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th style={{ width: 150 }}>{t('label.name')}</th>
            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => (
              <th key={day} style={{ fontSize: 12, width: 18, textAlign: 'center' }}>
                <p className="month-day">{day}</p>
              </th>
            ))}
            <th style={{ fontSize: 12, width: 18, textAlign: 'center' }}> {t('reports.attendance.total')}</th>
            {attendanceLabels.map((label) => (
              <th
                key={label.id}
                style={{ fontSize: 12, width: 20, textAlign: 'center' }}
                className={`bg-${label.color}`}
                data-tooltip-id={`tooltip-total-label-${label.id}`}
              >
                {t('reports.attendance.total')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {attendanceReport.members?.map((member: AttendanceReportMember) => (
            <tr key={member.name}>
              <td>{`${member.name} ${member.lastname}`}</td>
              {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => {
                const dayString = day < 10 ? `0${day}` : day.toString()
                return (
                  <td
                    key={`${member.name}-${dayString}`}
                    style={{
                      fontSize: 12,
                      minWidth: 25,
                      width: 25,
                      maxWidth: 25,
                      textAlign: 'center',
                      background: Object.keys(member.attendance)?.includes(dayString) ? '#e9eaeb' : 'white',
                      padding: 2
                    }}
                  >
                    {(member.attendance as Record<string, Array<AttendanceLabelResponse>>)[dayString]?.length ? (
                      <div className="d-flex flex-wrap">
                        {(member.attendance as Record<string, Array<AttendanceLabelResponse>>)[dayString]?.map((label) => (
                          <div
                            key={`${member.name}-${dayString}-${label.color}`}
                            className={`bg-${label.color}`}
                            data-tooltip-id={`tooltip-total-label-${label.id}`}
                            style={{
                              width: 8,
                              minWidth: 8,
                              height: 8,
                              borderRadius: '100%',
                              marginRight: 1.5,
                              marginBottom: 1.5,
                              borderWidth: 1,
                              borderColor: 'grey',
                              borderStyle: 'solid',
                              display: 'flex'
                            }}
                          ></div>
                        ))}
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </td>
                )
              })}
              <td style={{ textAlign: 'center' }}>{Object.keys(member.attendance).length}</td>
              {attendanceLabels.map((label) => (
                <td key={label.id} style={{ textAlign: 'center', width: 42 }}>
                  {
                    Object.values(member.attendance)
                      ?.flat()
                      .filter((item) => item.id === label.id).length
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {attendanceLabels.map((label) => (
        <Tooltip
          key={`tooltip-total-label-${label.id}`}
          id={`tooltip-total-label-${label.id}`}
          place="bottom"
          className="p-1 rounded bg-dark"
          style={{ zIndex: 999 }}
          clickable
          noArrow
        >
          {label.name}
        </Tooltip>
      ))}
    </div>
  )
}

export default MonthReportTable
