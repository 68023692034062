import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { getMemberPrivacy, saveMemberPrivacy } from '../../api/member'
import { PrivacyError } from '../../api/models/error'
import { MemberPrivacyRequest, PrivacyFormConfigElement, PrivacyOptionType } from '../../api/models/formsConfig'
import Input from '../../components/forms/components/Input'
import AcceptPrivacyItem from '../../components/Privacy/AcceptPrivacyItem'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'
import { decodeToken, isTokenExpired } from '../../helpers/auth'
import { standarizeLanguage } from '../../helpers/common'
import { isValidIBANNumber } from '../../helpers/validations/ibanValidator'
import { setLanguage } from '../../translations'
import ClubConfigWrapper from '../../wrappers/ClubConfigWrapper'

type DecodeTokenType = {
  iss: string
  iat: number
  exp: number
  nbf: number
  jti: string
  id: string
  email: string
  club: string
  lang?: string
  fullName: string
  tutorName?: string
}

export const AcceptPrivacy = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const urlParams = useParams()
  const { logo, basicInfo } = useClubConfigContext()

  const token = new URLSearchParams(search).get('token') || ''
  const clubCode = urlParams?.clubCode

  const { id, email, club, lang, fullName, tutorName }: DecodeTokenType = token ? decodeToken(token) : {}

  const [memberAccept, setMemberAccept] = useState<MemberPrivacyRequest>({})
  const [privacyConfig, setPrivacyConfig] = useState<PrivacyFormConfigElement[]>([])
  const [finished, setFinished] = useState(false)

  if (!token || token === '' || clubCode !== club || isTokenExpired(token)) {
    return <Navigate to={`/${clubCode}/not-allowed`} />
  }

  useEffect(() => {
    if (!club) {
      return
    }
    if (lang) {
      setLanguage(standarizeLanguage(lang))
    }

    getMemberPrivacy({ token }).then((response) => {
      const fields = Object.values(response).flatMap((field) => field)
      setPrivacyConfig(fields)
      setMemberAccept(
        fields
          .filter((field) => field.showInForm && field.type !== PrivacyOptionType.FIELD)
          .reduce((acc, field) => ({ ...acc, [field.name]: false }), {})
      )
    })
  }, [])

  const requiredFields = privacyConfig.filter((field) => field.required)
  const disabled = requiredFields.some((field) => !memberAccept[field.name])

  const handleSubmit = async () => {
    if (memberAccept.bankAccount && !(await isValidIBANNumber(memberAccept.bankAccount as string)).valid) {
      return toast.error(t('validation.invalid_iban'))
    }
    saveMemberPrivacy({ token, acceptances: memberAccept })
      .then(() => {
        setFinished(true)
      })
      .catch((e) => {
        const error = e.response?.data
        if (error?.code === PrivacyError.REQUIRED) {
          toast.error(t(`api_error.${error.code}`))
        } else {
          toast.error(t('notifications.error'))
        }
      })
  }

  return (
    <ClubConfigWrapper>
      <div className="row justify-content-center mt-5 mb-5">
        {logo && <img src={`${process.env.REACT_APP_LOGO_BASE_URL}${logo}`} alt="logo" className="mb-4" style={{ maxWidth: 250, maxHeight: 75 }} />}
        <div className="d-flex flex-column align-items-center ml-3">
          <p className="mb-6 mb-sm-3 text-lg">{t('accept_privacy.card_title')}</p>
          <p className="mb-4 h4">{basicInfo?.name}</p>
        </div>
      </div>

      {finished ? (
        <div className="text-center">
          <i className="fa fa-check-circle color-success text-xl text-center mb-2"></i>
          <h3 className="text-center">{t('accept_privacy.form_ok')}</h3>
          <p>{t('accept_privacy.wellcome')}</p>
        </div>
      ) : (
        <div style={{ maxWidth: '90%', margin: '0 auto' }}>
          <p>
            {lang === 'en_GB'
              ? `${t('accept_privacy.description2')} ${t('accept_privacy.description', { fullName })} ${tutorName ? t('accept_privacy.description_tutor', { tutorName }) : '.'} `
              : `${t('accept_privacy.description', { fullName })} ${tutorName ? t('accept_privacy.description_tutor', { tutorName }) : ''} ${t('accept_privacy.description2')}`}
          </p>
          <div>
            {privacyConfig.map((field) =>
              field.type === PrivacyOptionType.FIELD && field.showInForm ? (
                <div className="mt-5" key={field.name}>
                  <Input
                    label={t(`accept_privacy.${field.name}`)}
                    value={memberAccept[field.name] as string}
                    onChange={(e) => setMemberAccept((prev) => ({ ...prev, [field.name]: e.target.value }))}
                  />
                </div>
              ) : (
                <AcceptPrivacyItem key={field.name} field={field} setObject={setMemberAccept} />
              )
            )}
            <div className="col-12 mt-2 card-body">
              <button type="submit" className="float-right btn btn-primary" onClick={handleSubmit} disabled={disabled}>
                {t('app.send')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ClubConfigWrapper>
  )
}

export default AcceptPrivacy
