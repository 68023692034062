import { GroupBase, StylesConfig } from 'react-select'

import { MultiValueOption } from '.'

export const customStyles: StylesConfig<MultiValueOption, true, GroupBase<MultiValueOption>> = {
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 4
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'transparent'
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
    padding: 1
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'white',
    padding: 1
  })
}
