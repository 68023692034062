import { Dispatch, SetStateAction, useState } from 'react'

import { deleteAttendanceLabel, updateAttendanceLabel } from '../../../../../api/attendance'
import { AttendanceLabelResponse } from '../../../../../api/models/attendance'
import { Option } from '../../../../../api/models/club'
import { EditRound } from '../../../../../assets/img/EditRound'
import ColorPicker from '../../../../forms/components/ColorPicker'

export interface AttendanceLabelCardItemProps extends AttendanceLabelResponse {
  editAvailable?: boolean
  setElements: Dispatch<SetStateAction<Array<AttendanceLabelResponse>>>
}

export const AttendanceLabelCardItem = ({ name, color, id, editAvailable, setElements }: AttendanceLabelCardItemProps) => {
  const [editable, setEditable] = useState(false)
  const [item, setItem] = useState(name)
  const [colorItem, setColorItem] = useState<Option>()

  const onDelete = () => {
    deleteAttendanceLabel(id).then((response) => {
      if (response.success && response.data) {
        setElements(response.data)
      }
    })
  }

  const onEdit = () => {
    setEditable(true)
  }

  const onSave = () => {
    setEditable(false)
    updateAttendanceLabel({ id, name: item, colorId: Number(colorItem?.value) }).then((response) => {
      if (response.success && response.data) {
        setElements(response.data)
      }
    })
  }

  return (
    <tr key={id}>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          {editable ? (
            <>
              <ColorPicker value={{ value: id, label: color }} onChange={setColorItem} />
              <input type="text" value={item} onChange={(e) => setItem(e.target.value)} />
            </>
          ) : (
            <div className="d-flex row align-items-center">
              <span className={`d-flex color-picker-item m-1 mr-2 bg-${color}`}></span>
              <p className="mb-0">{name}</p>
            </div>
          )}

          {editAvailable && (
            <div className="row align-items-center">
              <div className="mr-1">
                {editable ? (
                  <a onClick={onSave}>
                    <i className={`fa fa-check-circle color-success`}></i>
                  </a>
                ) : (
                  <a onClick={onEdit}>
                    <EditRound />
                  </a>
                )}
              </div>
              <a onClick={onDelete}>
                <i className="fa fa-minus-circle color-danger mr-1"></i>
              </a>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default AttendanceLabelCardItem
