import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAllAttendanceSheets, getAttendanceLabels } from '../../api/attendance'
import { AttendanceLabelResponse, AttendanceSheet } from '../../api/models/attendance'
import Layout from '../../components/common/Layout'
import AnnualReport from '../../components/reports/Attendance/AnnualReport'
import MonthReport from '../../components/reports/Attendance/MonthReport'
import SeasonReport from '../../components/reports/Attendance/SeasonReport'

export const AttendanceReports = () => {
  const { t } = useTranslation()

  const [attendanceLabels, setAttendanceLabels] = useState<AttendanceLabelResponse[]>([])
  const [attendanceSheets, setAttendanceSheets] = useState<AttendanceSheet[]>([])

  useEffect(() => {
    getAllAttendanceSheets({}).then((response) => setAttendanceSheets(response))

    getAttendanceLabels().then((response) => {
      setAttendanceLabels(response)
    })
  }, [])

  // const [startDate, setStartDate] = useState(subYears(new Date(), 1))
  // const [endDate, setEndDate] = useState(new Date())

  // const onSubmitSearch = () => {
  //   getMembersRegistrationData({ startDate, endDate }).then((data) => {
  //     setReportData(data)
  //   })
  // }

  // useEffect(() => {
  //   getMembersRegistrationData({}).then((data) => {
  //     setReportData(data)
  //   })
  // }, [])

  return (
    <Layout>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <h1 className="m-0 text-dark">{t('reports.title', { type: t('menu.attendancereport') })}</h1>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid pt-3">
          <div className="row mb-4">
            <div>
              <p className="mb-1">{t('reports.attendance.legend')}:</p>
              <div className="row">
                {attendanceLabels.map((label) => (
                  <div key={label.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 10 }}>
                    <div
                      key={label.color}
                      className={`bg-${label.color}`}
                      style={{
                        width: 15,
                        minWidth: 15,
                        height: 15,
                        borderRadius: '100%',
                        marginBottom: 1.5,
                        borderWidth: 1,
                        borderColor: 'grey',
                        borderStyle: 'solid',
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10
                      }}
                    ></div>
                    <p className="mb-0">{label.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card card-custom card-outline card-outline-tabs w-100">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-four-month-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-month"
                      role="tab"
                      aria-controls="custom-tabs-four-month"
                      aria-selected="false"
                    >
                      {t('reports.attendance.categories.month')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-four-season-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-season"
                      role="tab"
                      aria-controls="custom-tabs-four-season"
                      aria-selected="true"
                    >
                      {t('reports.attendance.categories.season')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-four-annual-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-annual"
                      role="tab"
                      aria-controls="custom-tabs-four-annual"
                      aria-selected="false"
                    >
                      {t('reports.attendance.categories.annual')}
                    </a>
                  </li>
                  {/*  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-four-settings-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-settings"
                      role="tab"
                      aria-controls="custom-tabs-four-settings"
                      aria-selected="false"
                    >
                      {t('reports.attendance.categories.custom')}
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-four-tabContent">
                  <div className="tab-pane fade active show" id="custom-tabs-four-month" role="tabpanel" aria-labelledby="custom-tabs-four-month-tab">
                    <MonthReport attendanceSheets={attendanceSheets} />
                  </div>
                  <div className="tab-pane fade" id="custom-tabs-four-season" role="tabpanel" aria-labelledby="custom-tabs-four-season-tab">
                    <SeasonReport attendanceSheets={attendanceSheets} />
                  </div>
                  <div className="tab-pane fade" id="custom-tabs-four-annual" role="tabpanel" aria-labelledby="custom-tabs-four-annual-tab">
                    <AnnualReport attendanceSheets={attendanceSheets} />
                  </div>
                  {/*
                  <div className="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                    Pellentesque vestibulum commodo nibh nec blandit. Maecenas neque magna, iaculis tempus turpis ac, ornare sodales tellus. Mauris
                    eget blandit dolor. Quisque tincidunt venenatis vulputate. Morbi euismod molestie tristique. Vestibulum consectetur dolor a
                    vestibulum pharetra. Donec interdum placerat urna nec pharetra. Etiam eget dapibus orci, eget aliquet urna. Nunc at consequat
                    diam. Nunc et felis ut nisl commodo dignissim. In hac habitasse platea dictumst. Praesent imperdiet accumsan ex sit amet
                    facilisis.
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AttendanceReports
