import HeaderLogo from '../../../components/common/HeaderLogo'
import Sidebar from '../Sidebar'

export const MainSidebar = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <HeaderLogo />
      <Sidebar />
    </aside>
  )
}

export default MainSidebar
