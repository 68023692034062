import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { SkermoFederation, SkermoIntegration, SkermoLoginUrl, TransferFencerResponse } from './models/integration'

const SKERMO_INTEGRATION_URL = 'skermo-integration'
const FEDERATIONS_URL = 'skermo/federations-list'
const SKERMO_URL = 'skermo/url'
const SKERMO_FENCER_URL = 'skermo/fencer'

export const getSkermoKeys: () => Promise<SkermoIntegration> = () => {
  return axios.get<SkermoIntegration>(SKERMO_INTEGRATION_URL).then((response: AxiosResponse) => response?.data)
}

export const saveSkermoKeys: (keys: SkermoIntegration) => Promise<SuccessResponse<{}>> = (keys) => {
  return axios.post<SuccessResponse<{}>>(SKERMO_INTEGRATION_URL, keys).then((response: AxiosResponse) => response?.data)
}

export const getFederations: () => Promise<SuccessResponse<Array<SkermoFederation>>> = () => {
  return axios.get<SuccessResponse<Array<SkermoFederation>>>(FEDERATIONS_URL).then((response: AxiosResponse) => response?.data)
}

export const getSkermoUrl: () => Promise<SuccessResponse<SkermoLoginUrl>> = () => {
  return axios.get<SuccessResponse<SkermoLoginUrl>>(SKERMO_URL).then((response: AxiosResponse) => response?.data)
}

export const transferFencer: (memberId: string) => Promise<SuccessResponse<TransferFencerResponse> & { error?: string }> = (memberId) => {
  return axios
    .post<SuccessResponse<TransferFencerResponse> & { error?: string }>(`${SKERMO_FENCER_URL}/${memberId}`)
    .then((response: AxiosResponse) => response?.data)
}
