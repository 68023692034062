// @ts-nocheck
import './styles.css'

import { ChangeEvent } from 'react'
import Modal from 'react-modal'

import CropImage from '../../common/CropImage'
import { ModalProps } from '../ContactModal'

interface CropImageModalProps extends ModalProps {
  event: ChangeEvent<HTMLInputElement>
  onSave: (image: File) => void
}

export const CropImageModal = ({ isOpen, closeModal, event, onSave }: CropImageModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      setAppElement={'#app-body'}
      overlayClassName={'overlay'}
      className="modal-element modal-content modal-image"
      shouldFocusAfterRender
      preventScroll
      shouldReturnFocusAfterClose
    >
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body mx-auto">
        <CropImage e={event} onClose={closeModal} onSave={onSave} />
      </div>
    </Modal>
  )
}

export default CropImageModal
