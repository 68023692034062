import '../styles.css'

import { useEffect, useState } from 'react'

import { AttendanceReportResponse } from '../../../../../api/models/reports'
import { getAttendanceAnnualReport } from '../../../../../api/reports'
import ByMonthsReportTable from '../../SeasonReport/components/ByMonthsReportTable'

interface AnnualReportTableProps {
  id: number
}

export const AnnualReportTable = ({ id }: AnnualReportTableProps) => {
  const [attendanceReport, setAttendanceReport] = useState<AttendanceReportResponse | null>(null)

  useEffect(() => {
    getAttendanceAnnualReport({ id }).then((response) => {
      setAttendanceReport(response)
    })
  }, [])

  if (!id || !attendanceReport) {
    return null
  }

  return <ByMonthsReportTable attendanceReport={attendanceReport} />
}

export default AnnualReportTable
