import { useEffect, useState } from 'react'

import { getClubs } from '../../../api/club'
import { ClubResponse } from '../../../api/models/club'
import ListTitle from '../../common/ListTitle'
import ClubListItem from '../ClubListItem'

const fields = ['name', 'code', 'nif', 'email']

export const ClubList = () => {
  const [clubs, setClubs] = useState<Array<ClubResponse>>([])

  useEffect(() => {
    getClubs().then((response) => {
      setClubs(response)
    })
  }, [])

  return (
    <div className="card-body">
      <table className="float-right table table-bordered table-striped">
        <ListTitle fields={fields} />
        <tbody>
          {clubs.map((club) => (
            <ClubListItem key={club.code} {...club} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ClubList
