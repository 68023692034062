import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { createClub, getClub, updateClub } from '../../api/club'
import { getFederations } from '../../api/integration'
import { ClubResponse, Option } from '../../api/models/club'
import { uploadImage } from '../../api/uploadFiles'
import CustomToast from '../../components/common/CustomToast'
import Layout from '../../components/common/Layout'
import { ClubForm } from '../../components/forms/ClubForm'
import { NO_CLUB_CODE } from '../../contexts/ClubConfigContext'
import { addClubForm } from '../../helpers/forms/club'
import { myClubForm } from '../../helpers/forms/myclub'

export type ClubErrors = Partial<{
  [key in keyof ClubResponse]?: string
}>

export const CreateClub = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const url = useLocation()
  const urlParams = useParams()
  const clubCode = urlParams?.clubCode !== NO_CLUB_CODE ? urlParams?.clubCode : false
  const editMyClub = url.pathname?.includes('my-club')

  const [club, setClub] = useState<Partial<ClubResponse>>({})
  const [errors, setErrors] = useState<ClubErrors>({})
  const [loading, setLoading] = useState(false)
  const [federationsOptions, setFederationsOptions] = useState<Array<Option>>([])

  useEffect(() => {
    if (!editMyClub) {
      getFederations().then((response) => {
        if (response.success) {
          setFederationsOptions(
            response.data.map((federation) => ({ label: `${federation.acronym} - ${federation.name}`, value: federation.acronym }))
          )
        }
      })
    }
  }, [])

  useEffect(() => {
    if (clubCode || editMyClub) {
      setLoading(true)
      getClub({ clubCode: clubCode as string }).then((response) => {
        setClub(response)
        setLoading(false)
      })
    }
  }, [clubCode])

  useEffect(() => {
    if (club.federationId) {
      const federationName = federationsOptions.find((federation) => federation.value === club.federationId)?.label
      setClub((prev) => ({ ...prev, federationName }))
    }
  }, [club.federationId])

  const handleSubmit = () => {
    if (clubCode || editMyClub) {
      updateClub(club as ClubResponse).then((response) => {
        if (response.success && club.logo) {
          const formData = new FormData()
          formData.append('file', club.logo)
          formData.append('clubCode', club.code!)
          uploadImage(formData).then(() => {
            toast.custom(<CustomToast text={t('notifications.updated')} />)
            if (!editMyClub) {
              navigate(`/master/clubs`)
            }
          })
        } else {
          toast.custom(<CustomToast text={t('notifications.updated')} />)
          if (!editMyClub) {
            navigate(`/master/clubs`)
          }
        }
      })
    } else {
      createClub(club as ClubResponse)
        .then((response) => {
          if (response.success && club.logo) {
            const formData = new FormData()
            formData.append('file', club.logo)
            formData.append('clubCode', club.code!)
            uploadImage(formData).then(() => {
              toast.custom(<CustomToast text={t('notifications.created')} />)
              if (!editMyClub) {
                navigate(`/master/clubs`)
              }
            })
          }
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error?.errors) {
            setErrors(error.errors)
          }
        })
    }
  }

  const formConfig = editMyClub ? myClubForm : addClubForm({ federationsOptions })

  return (
    <Layout>
      <section className="content">
        <div className="col-12">
          <h1 className="pt-3">{clubCode || editMyClub ? t('clubs.edit_club') : t('clubs.create_club')}</h1>
        </div>
        <ClubForm
          club={club}
          errors={errors}
          setClub={setClub}
          handleSubmit={handleSubmit}
          formConfig={formConfig}
          loading={loading}
          isCreate={!(clubCode || editMyClub)}
        />
      </section>
    </Layout>
  )
}

export default CreateClub
