import Logo from '../components/common/Logo'
import LoginForm from '../components/forms/LoginForm'
import ClubConfigWrapper from '../wrappers/ClubConfigWrapper'

export const Login = () => {
  return (
    <ClubConfigWrapper>
      <div className="hold-transition login-page">
        <div className="login-box">
          <Logo />
          <LoginForm />
        </div>
        <div className="position-absolute mb-3" style={{ bottom: 0, color: 'gray' }}>
          Powered by{' '}
          <a href="https://www.skermo.org/" target="_blank" rel="noreferrer">
            <img src={require('../assets/img/skermo_bn.png')} width={200} />
          </a>
        </div>
      </div>
    </ClubConfigWrapper>
  )
}

export default Login
