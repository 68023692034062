import { Field } from './club'

export interface SuccessResponse<T> {
  success: boolean
  data: T
}

export interface SuccessWithErrorsResponse<T> extends Partial<SuccessResponse<T>> {
  errors?: { [k in string]: string[] }
}

export type PaginatedData<P = unknown> = {
  data: P[]
  total: number
  currentPage: number
  from: number
  to: number
  lastPage: number
  firstPageUrl: string | null
  lastPageUrl: string | null
  nextPageUrl: string | null
  prevPageUrl: string | null
  links?: {
    url: string | null
    label: string
    active: boolean
  }[]
}

export enum Weapon {
  E = 'epee',
  F = 'foil',
  S = 'sabre'
}

export enum Laterality {
  R = 'right',
  L = 'left'
}

export enum DocumentationType {
  DNI = 'DNI',
  NIE = 'NIE',
  SS = 'SS',
  PASSPORT = 'PPT'
}

export enum Country {
  AF = 'Afghanistan',
  AL = 'Albania',
  DZ = 'Algeria',
  DS = 'American Samoa',
  AD = 'Andorra',
  AO = 'Angola',
  AI = 'Anguilla',
  AQ = 'Antarctica',
  AG = 'Antigua and Barbuda',
  AR = 'Argentina',
  AM = 'Armenia',
  AW = 'Aruba',
  AU = 'Australia',
  AT = 'Austria',
  AZ = 'Azerbaijan',
  BS = 'Bahamas',
  BH = 'Bahrain',
  BD = 'Bangladesh',
  BB = 'Barbados',
  BY = 'Belarus',
  BE = 'Belgium',
  BZ = 'Belize',
  BJ = 'Benin',
  BM = 'Bermuda',
  BT = 'Bhutan',
  BO = 'Bolivia',
  BA = 'Bosnia and Herzegovina',
  BW = 'Botswana',
  BV = 'Bouvet Island',
  BR = 'Brazil',
  IO = 'British Indian Ocean Territory',
  BN = 'Brunei Darussalam',
  BG = 'Bulgaria',
  BF = 'Burkina Faso',
  BI = 'Burundi',
  KH = 'Cambodia',
  CM = 'Cameroon',
  CA = 'Canada',
  CV = 'Cape Verde',
  KY = 'Cayman Islands',
  CF = 'Central African Republic',
  TD = 'Chad',
  CL = 'Chile',
  CN = 'China',
  CX = 'Christmas Island',
  CC = 'Cocos (Keeling) Islands',
  CO = 'Colombia',
  KM = 'Comoros',
  CG = 'Congo',
  CK = 'Cook Islands',
  CR = 'Costa Rica',
  HR = 'Croatia (Hrvatska)',
  CU = 'Cuba',
  CY = 'Cyprus',
  CZ = 'Czech Republic',
  DK = 'Denmark',
  DJ = 'Djibouti',
  DM = 'Dominica',
  DO = 'Dominican Republic',
  TP = 'East Timor',
  EC = 'Ecuador',
  EG = 'Egypt',
  SV = 'El Salvador',
  GQ = 'Equatorial Guinea',
  ER = 'Eritrea',
  EE = 'Estonia',
  ET = 'Ethiopia',
  FK = 'Falkland Islands (Malvinas)',
  FO = 'Faroe Islands',
  FJ = 'Fiji',
  FI = 'Finland',
  FR = 'France',
  FX = 'France, Metropolitan',
  GF = 'French Guiana',
  PF = 'French Polynesia',
  TF = 'French Southern Territories',
  GA = 'Gabon',
  GM = 'Gambia',
  GE = 'Georgia',
  DE = 'Germany',
  GH = 'Ghana',
  GI = 'Gibraltar',
  GK = 'Guernsey',
  GR = 'Greece',
  GL = 'Greenland',
  GD = 'Grenada',
  GP = 'Guadeloupe',
  GU = 'Guam',
  GT = 'Guatemala',
  GN = 'Guinea',
  GW = 'Guinea-Bissau',
  GY = 'Guyana',
  HT = 'Haiti',
  HM = 'Heard and Mc Donald Islands',
  HN = 'Honduras',
  HK = 'Hong Kong',
  HU = 'Hungary',
  IS = 'Iceland',
  IN = 'India',
  IM = 'Isle of Man',
  ID = 'Indonesia',
  IR = 'Iran (Islamic Republic of)',
  IQ = 'Iraq',
  IE = 'Ireland',
  IL = 'Israel',
  IT = 'Italy',
  CI = 'Ivory Coast',
  JE = 'Jersey',
  JM = 'Jamaica',
  JP = 'Japan',
  JO = 'Jordan',
  KZ = 'Kazakhstan',
  KE = 'Kenya',
  KI = 'Kiribati',
  KP = 'Korea, Democratic Peoples Republic of',
  KR = 'Korea, Republic of',
  XK = 'Kosovo',
  KW = 'Kuwait',
  KG = 'Kyrgyzstan',
  LA = 'Lao Peoples Democratic Republic',
  LV = 'Latvia',
  LB = 'Lebanon',
  LS = 'Lesotho',
  LR = 'Liberia',
  LY = 'Libyan Arab Jamahiriya',
  LI = 'Liechtenstein',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  MO = 'Macau',
  MK = 'Macedonia',
  MG = 'Madagascar',
  MW = 'Malawi',
  MY = 'Malaysia',
  MV = 'Maldives',
  ML = 'Mali',
  MT = 'Malta',
  MH = 'Marshall Islands',
  MQ = 'Martinique',
  MR = 'Mauritania',
  MU = 'Mauritius',
  TY = 'Mayotte',
  MX = 'Mexico',
  FM = 'Micronesia, Federated States of',
  MD = 'Moldova, Republic of',
  MC = 'Monaco',
  MN = 'Mongolia',
  ME = 'Montenegro',
  MS = 'Montserrat',
  MA = 'Morocco',
  MZ = 'Mozambique',
  MM = 'Myanmar',
  NA = 'Namibia',
  NR = 'Nauru',
  NP = 'Nepal',
  NL = 'Netherlands',
  AN = 'Netherlands Antilles',
  NC = 'New Caledonia',
  NZ = 'New Zealand',
  NI = 'Nicaragua',
  NE = 'Niger',
  NG = 'Nigeria',
  NU = 'Niue',
  NF = 'Norfolk Island',
  MP = 'Northern Mariana Islands',
  NO = 'Norway',
  OM = 'Oman',
  PK = 'Pakistan',
  PW = 'Palau',
  PS = 'Palestine',
  PA = 'Panama',
  PG = 'Papua New Guinea',
  PY = 'Paraguay',
  PE = 'Peru',
  PH = 'Philippines',
  PN = 'Pitcairn',
  PL = 'Poland',
  PT = 'Portugal',
  PR = 'Puerto Rico',
  QA = 'Qatar',
  RE = 'Reunion',
  RO = 'Romania',
  RU = 'Russian Federation',
  RW = 'Rwanda',
  KN = 'Saint Kitts and Nevis',
  LC = 'Saint Lucia',
  VC = 'Saint Vincent and the Grenadines',
  WS = 'Samoa',
  SM = 'San Marino',
  ST = 'Sao Tome and Principe',
  SA = 'Saudi Arabia',
  SN = 'Senegal',
  RS = 'Serbia',
  SC = 'Seychelles',
  SL = 'Sierra Leone',
  SG = 'Singapore',
  SK = 'Slovakia',
  SI = 'Slovenia',
  SB = 'Solomon Islands',
  SO = 'Somalia',
  ZA = 'South Africa',
  GS = 'South Georgia South Sandwich Islands',
  ES = 'Spain',
  LK = 'Sri Lanka',
  SH = 'St. Helena',
  PM = 'St. Pierre and Miquelon',
  SD = 'Sudan',
  SR = 'Suriname',
  SJ = 'Svalbard and Jan Mayen Islands',
  SZ = 'Swaziland',
  SE = 'Sweden',
  CH = 'Switzerland',
  SY = 'Syrian Arab Republic',
  TW = 'Taiwan',
  TJ = 'Tajikistan',
  TZ = 'Tanzania, United Republic of',
  TH = 'Thailand',
  TG = 'Togo',
  TK = 'Tokelau',
  TO = 'Tonga',
  TT = 'Trinidad and Tobago',
  TN = 'Tunisia',
  TR = 'Turkey',
  TM = 'Turkmenistan',
  TC = 'Turks and Caicos Islands',
  TV = 'Tuvalu',
  UG = 'Uganda',
  UA = 'Ukraine',
  AE = 'United Arab Emirates',
  GB = 'United Kingdom',
  US = 'United States',
  UM = 'United States minor outlying islands',
  UY = 'Uruguay',
  UZ = 'Uzbekistan',
  VU = 'Vanuatu',
  VA = 'Vatican City State',
  VE = 'Venezuela',
  VN = 'Vietnam',
  VG = 'Virgin Islands (British)',
  VI = 'Virgin Islands (U.S.)',
  WF = 'Wallis and Futuna Islands',
  EH = 'Western Sahara',
  YE = 'Yemen',
  ZR = 'Zaire',
  ZM = 'Zambia',
  ZW = 'Zimbabwe'
}

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  SELECT = 'select',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  TEXTAREA = 'textarea',
  INPUT_GROUP = 'input-group',
  COLOR = 'color',
  PASSWORD = 'password',
  FILE = 'file',
  IMAGE = 'image',
  MULTI_SELECT = 'multi-select',
  PHONE = 'phone'
}

export enum PaymentMethod {
  D = 'direct_debit',
  T = 'bank_transfer',
  C = 'credit_card',
  E = 'cash'
}

export enum TutorType {
  FATHER = 'father',
  MOTHER = 'mother',
  LEGAL = 'legal'
}

export enum Category {
  PRE = 'PRE',
  M7 = 'M7',
  M9 = 'M9',
  M11 = 'M11',
  M13 = 'M13',
  M15 = 'M15',
  M17 = 'M17',
  M20 = 'M20',
  ABS = 'ABS'
}

export interface FormSectionElement<T extends any> {
  title?: string
  fields: Field<T>[]
  startCollapsed?: boolean
  includeNumber?: boolean
  conditionalHide?: boolean
}

export type FormErrors<T extends any> = Partial<{
  [key in keyof T]?: string
}>
