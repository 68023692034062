import { useTranslation } from 'react-i18next'

import { DEFAULT_COLUMN_SIZE } from '../../../../helpers/layout'
import FormControl, { FormFieldProps } from '../FormControl'

export const Select = ({
  name,
  label,
  options = [],
  required = false,
  disabled = false,
  value,
  columnSize = DEFAULT_COLUMN_SIZE,
  onChange,
  size,
  error,
  removeError
}: FormFieldProps) => {
  const { t } = useTranslation()

  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error} labelClassName={size === 'small' ? 'col-form-label-sm' : ''}>
      <select
        className={size === 'small' ? 'form-control-sm' : 'custom-select'}
        name={name as string | undefined}
        onChange={onChange}
        disabled={disabled}
        onFocus={removeError}
        value={value as string | undefined}
      >
        <option value="">{t('filter.no_selected')}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.value ? t(option.label!) : option.value}
          </option>
        ))}
      </select>
    </FormControl>
  )
}

export default Select
