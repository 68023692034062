import en from 'react-phone-number-input/locale/en.json'
import es from 'react-phone-number-input/locale/es.json'
import it from 'react-phone-number-input/locale/it.json'

import i18n, { AVAILABLE_LANGUAGES } from '..'
import ca_ES from '../ca_ES.json'
import gl_ES from '../gl_ES.json'
import ca_ES_phone from './ca_ES.json'
import gl_ES_phone from './gl_ES.json'

export const phoneInputLangauges = () => {
  const language = i18n.language

  switch (language) {
    case AVAILABLE_LANGUAGES.es_ES:
      return es
    case AVAILABLE_LANGUAGES.en_GB:
      return en
    case AVAILABLE_LANGUAGES.it_IT:
      return it
    case AVAILABLE_LANGUAGES.ca_ES:
      return {
        ...ca_ES_phone,
        ...ca_ES.common.countries
      }
    case AVAILABLE_LANGUAGES.gl_ES:
      return {
        ...gl_ES_phone,
        ...gl_ES.common.countries
      }
    default:
      return en
  }
}

export default phoneInputLangauges
