import { PropsWithChildren } from 'react'
import { useParams } from 'react-router'
import Select, { components, ControlProps } from 'react-select'

import { Option } from '../../../api/models/club'
import { standarizeLanguage } from '../../../helpers/common'
import { SCREEN_SIZE } from '../../../helpers/layout'
import { AVAILABLE_LANGUAGES_LABELS } from '../../../translations'

const Control = ({ children, ...props }: PropsWithChildren<ControlProps<Option>>) => (
  <components.Control {...props}>
    <i className="fa fa-globe ml-2" style={{ color: 'gray' }}></i> {children}
  </components.Control>
)

export const LanguageSelector = ({ url = 'preinscription' }: { url?: string }) => {
  const clubCode = localStorage.getItem('clubCode')

  const lang = useParams<{ lang: string }>()?.lang || 'es_ES'

  return (
    <div className={`mb-4 d-flex align-self-end ${window.innerWidth > SCREEN_SIZE.md ? 'col-3' : 'col-6'}`} style={{ flex: 0 }}>
      <Select
        options={Object.keys(AVAILABLE_LANGUAGES_LABELS).map((language) => ({
          value: language,
          label: AVAILABLE_LANGUAGES_LABELS[language as keyof typeof AVAILABLE_LANGUAGES_LABELS]
        }))}
        defaultValue={{
          value: standarizeLanguage(lang),
          label: AVAILABLE_LANGUAGES_LABELS[standarizeLanguage(lang) as keyof typeof AVAILABLE_LANGUAGES_LABELS]
        }}
        components={{ Control }}
        styles={{
          container: (base) => ({
            ...base,
            width: '100%'
          })
        }}
        onChange={(option) => (window.location.href = `/${clubCode}/${url}/${(option as Option)?.value}`)}
      />
    </div>
  )
}

export default LanguageSelector
