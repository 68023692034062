import { RouteObject } from 'react-router-dom'

import { CreateInfoRequest } from '../../pages/CommercialManagement/CreateInfoRequest'
import ListInfoRequest from '../../pages/CommercialManagement/ListInfoRequest'

export enum InfoRequestRouterPaths {
  LIST_INFO_REQUESTS = ':clubCode/commercial-management/info-requests',
  CREATE_INFO_REQUEST = ':clubCode/commercial-management/info-request/create',
  EDIT_INFO_REQUEST = ':clubCode/commercial-management/info-request/:id/edit'
}

export const infoRequestRoutes: RouteObject[] = [
  {
    path: InfoRequestRouterPaths.LIST_INFO_REQUESTS,
    Component: ListInfoRequest
  },
  {
    path: InfoRequestRouterPaths.CREATE_INFO_REQUEST,
    Component: CreateInfoRequest
  },
  {
    path: InfoRequestRouterPaths.EDIT_INFO_REQUEST,
    Component: CreateInfoRequest
  }
]
