import { Module } from './permission'

export enum PermissionGroup {
  MEMBER = 'members',
  CLUB = 'clubs',
  USER = 'users',
  ROLE = 'roles',
  GROUP = 'groups',
  ACTIVITY = 'activities',
  REPORT = 'reports',
  COMMERCIAL_MANAGEMENT = 'commercial-management'
}

export interface Permission {
  id: number
  name: string
  parent?: PermissionGroup
  onlyMaster?: boolean
}

export interface RoleResponse {
  id: number
  name: string
  description: string
  permissions: Permission[]
  modules?: Module[]
}

export type PermissionResponse = {
  [key in PermissionGroup]: Permission[]
}
