import { createContext, ReactNode, useState } from 'react'

import ContactModal from '../components/modals/ContactModal'
import ErrorModal from '../components/modals/ErrorModal'
import SuccessModal from '../components/modals/SuccessModal'
import useShowModal from '../hooks/useShowModal'

interface IUIContext {
  updateAfterCloseModal?: boolean
  closeModal: () => void
  openModal: () => void
  setUpdateAfterCloseModal: (_: boolean) => void
  showErrorModal: (_: ReactNode) => void
  closeErrorModal: () => void
  showSuccessModal: (_: ReactNode) => void
  closeSuccessModal: () => void
}

export const UIContext = createContext<IUIContext>({
  closeModal: () => {},
  openModal: () => {},
  setUpdateAfterCloseModal: () => {},
  showErrorModal: () => {},
  closeErrorModal: () => {},
  showSuccessModal: () => {},
  closeSuccessModal: () => {}
})

interface UIProviderProps {
  children: ReactNode
}

export const UIProvider = ({ children }: UIProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [updateAfterCloseModal, setUpdateAfterCloseModal] = useState(false)
  const [errorChildren, setErrorChildren] = useState<ReactNode>()
  const [successChildren, setSuccessChildren] = useState<ReactNode>()
  const { isModalShown: isErrorModalShown, openModal: showErrorModal, closeModal: closeErrorModal } = useShowModal()
  const { isModalShown: isSuccessModalShown, openModal: showSuccessModal, closeModal: closeSuccessModal } = useShowModal()

  return (
    <UIContext.Provider
      value={{
        updateAfterCloseModal,
        setUpdateAfterCloseModal,
        closeModal: () => setIsOpen(false),
        openModal: () => setIsOpen(true),
        showErrorModal: (children) => {
          setErrorChildren(children)
          showErrorModal()
        },
        closeErrorModal,
        showSuccessModal: (children) => {
          setSuccessChildren(children)
          showSuccessModal()
        },
        closeSuccessModal
      }}
    >
      {children}
      <ContactModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
      <ErrorModal isOpen={isErrorModalShown} closeModal={closeErrorModal}>
        {errorChildren}
      </ErrorModal>
      <SuccessModal isOpen={isSuccessModalShown} closeModal={closeSuccessModal}>
        {successChildren}
      </SuccessModal>
    </UIContext.Provider>
  )
}
