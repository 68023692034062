// eslint-disable-next-line simple-import-sort/imports
import './styles.css'

import { Dispatch, SetStateAction, useState } from 'react'
import { Option } from 'react-multi-select-component'

import { paramCardActions } from '../..'
import { ParamType } from '../../../../../api/models/params'
import { createParam } from '../../../../../api/params'

interface ParamCardInputProps {
  setElements: Dispatch<SetStateAction<Array<Option>>>
  type: Exclude<ParamType, ParamType.COLOR | ParamType.KNOWN_OPTIONS>
}

export const ParamCardInput = ({ setElements, type }: ParamCardInputProps) => {
  const [param, setParam] = useState<string>()

  const onSave = () => {
    if (!param || !param.length) return
    createParam({ value: param, type }).then(() => {
      setElements((prev) => [...prev, { value: param, label: param }])
      setParam('')
      paramCardActions[type]().then((response) => {
        setElements(response.map((element) => ({ value: element.id, label: element.value })))
      })
    })
  }

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          <input type="text" className="param-input" value={param} onChange={(e) => setParam(e.target.value)} />

          <a onClick={onSave}>
            <i className={`fa fa-check-circle color-success`}></i>
          </a>
        </div>
      </td>
    </tr>
  )
}

export default ParamCardInput
