import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { login } from '../../../api/auth'
import { AuthError } from '../../../api/models/error'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../../contexts/hooks/useClubConfigContext'
import { useInputValue } from '../../../hooks/useInputValue'
import ErrorMessage from '../../common/ErrorMessage'
import InputWithoutLabel from '../components/InputWithoutLabel'

export const LoginForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { saveUserInfo } = useAuthContext()
  const { clubCode } = useClubConfigContext()

  const email = useInputValue('')
  const password = useInputValue('')

  const [error, setError] = useState('')

  const onSubmit = (e: any) => {
    e.preventDefault()
    login({ clubCode, email: email.value, password: password.value })
      .then((session) => {
        saveUserInfo(session)
        navigate(`/${clubCode}/dashboard`)
      })
      .catch((err) => {
        const errorRes = err?.response?.data
        switch (errorRes?.code) {
          case AuthError.NOT_VALIDATE:
          case AuthError.CLUB_DONT_MATCH:
            setError(t('auth.failed_login'))
            break
        }
      })
  }

  return (
    <div className="card">
      <div className="card-body login-card-body">
        <p className="login-box-msg">{t('auth.title')}</p>
        {error && <ErrorMessage message={error} />}
        <form onSubmit={onSubmit}>
          <InputWithoutLabel name="email" type="email" required placeholder={t('label.email')} iconClass="fa-envelope" {...email} />
          <InputWithoutLabel name="password" type="password" required placeholder={t('label.password')} iconClass="fa-lock" {...password} />

          <div className="row justify-content-end pr-2">
            <button type="submit" className="btn btn-primary btn-block" onClick={onSubmit}>
              {t('auth.sign_in')}
            </button>
          </div>
        </form>
        {/* <p className="mt-2">
          <a href="{{route('auth.forgotpassword',request()->clubcode)}}">{t('auth.forgot_password')}</a>
        </p> */}
      </div>
    </div>
  )
}

export default LoginForm
