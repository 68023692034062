import { useLayoutEffect, useRef } from 'react'

interface NestedCheckboxProps {
  indeterminate: boolean
  checked: boolean
  id: number | string
  onChange: () => void
}

export const NestedCheckbox = (props: NestedCheckboxProps) => {
  const { indeterminate, checked, id, onChange } = props
  const inputRef = useRef<HTMLInputElement>(null)

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
      inputRef.current.checked = checked
    }
  }, [indeterminate, checked])

  return (
    <input
      ref={inputRef}
      type="checkbox"
      className="form-check-input"
      id={`module-${id}`}
      checked={checked}
      onChange={() => {
        onChange()
      }}
    />
  )
}

export default NestedCheckbox
