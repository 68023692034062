import { useEffect, useState } from 'react'

import { Option } from '../../../api/models/club'
import { ParamResponse, ParamType } from '../../../api/models/params'
import { getMemberTypes, getOrigins, getSuspendedReasons } from '../../../api/params'
import ParamCardInput from './components/ParamCardInput'
import ParamCardItem from './components/ParamCardItem'

interface ParamCardProps {
  title: string
  type: Exclude<ParamType, ParamType.COLOR | ParamType.KNOWN_OPTIONS>
}

export const paramCardActions: { [key in Exclude<ParamType, ParamType.COLOR | ParamType.KNOWN_OPTIONS>]: () => Promise<ParamResponse[]> } = {
  [ParamType.ORIGIN]: getOrigins,
  [ParamType.SUSPENDED_REASON]: getSuspendedReasons,
  [ParamType.MEMBER_TYPE]: getMemberTypes
}

export const ParamCard = ({ title, type }: ParamCardProps) => {
  const [editAvailable, setEditAvailable] = useState(false)
  const [elements, setElements] = useState<Array<Option>>([])

  useEffect(() => {
    paramCardActions[type]().then((response) => {
      setElements(response.map((element) => ({ value: element.id, label: element.value })))
    })
  }, [])

  return (
    <div className="col-lg-6 col-md-6 col-sm-12 px-1">
      <div className="card card-custom card-outline">
        <div className="card-header">
          <h5 className="card-title text-bold">{title}</h5>
          <div className="card-tools">
            <a onClick={() => setEditAvailable(!editAvailable)} className="btn btn-tool">
              <i className={`fas ${editAvailable ? 'fa-check' : 'fa-pen'}`}></i>
            </a>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-sm">
            <tbody>
              {elements.map((element: Option) => (
                <ParamCardItem key={element.value} {...element} type={type} editAvailable={editAvailable} setAction={setElements} />
              ))}
              {editAvailable && <ParamCardInput setElements={setElements} type={type} />}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ParamCard
