import { ClubResponse, DatabaseInfo, Field, Option } from '../../api/models/club'
import { Country, FieldType, FormSectionElement } from '../../api/models/common'
import { UserResponse } from '../../api/models/user'

export interface ClubFormConfig {
  federationsOptions: Option[]
}

export const addClubForm: (_: ClubFormConfig) => FormSectionElement<ClubResponse & DatabaseInfo>[] = ({ federationsOptions }) => {
  return [
    {
      title: 'clubs.basic_information',
      fields: [
        {
          type: FieldType.TEXT,
          name: 'name',
          label: 'label.name',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'code',
          label: 'label.code',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'alias',
          label: 'label.alias'
        },
        {
          type: FieldType.TEXT,
          name: 'documentValue',
          label: 'label.nif',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'email',
          label: 'label.email',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'phone',
          label: 'label.phone',
          required: true
        },
        {
          type: FieldType.COLOR,
          name: 'primaryColor',
          label: 'clubs.primary_color'
        },
        {
          type: FieldType.COLOR,
          name: 'secondaryColor',
          label: 'clubs.secondary_color'
        },
        {
          type: FieldType.COLOR,
          name: 'tertiaryColor',
          label: 'clubs.tertiary_color'
        },
        {
          type: FieldType.FILE,
          name: 'logo',
          label: 'clubs.select_logo'
        },
        {
          type: FieldType.TEXT,
          name: 'webUrl',
          label: 'clubs.url_web'
        }
      ]
    },
    {
      title: 'clubs.address_fiscal',
      fields: [
        {
          type: FieldType.TEXT,
          name: 'addressFiscal',
          label: 'label.address',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'townFiscal',
          label: 'label.town',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'postalCodeFiscal',
          label: 'label.postal_code',
          required: true
        },
        {
          type: FieldType.SELECT,
          name: 'countryFiscal',
          label: 'label.country',
          options: Object.keys(Country).map((country) => ({ value: country, label: 'common.countries.' + country })),
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'coordinatesFiscalX',
          label: 'label.coordinates_x'
        },
        {
          type: FieldType.TEXT,
          name: 'coordinatesFiscalY',
          label: 'label.coordinates_y'
        }
      ]
    },
    {
      title: 'clubs.address_social',
      fields: [
        {
          type: FieldType.TEXT,
          name: 'addressSocial',
          label: 'label.address',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'townSocial',
          label: 'label.town',
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'postalCodeSocial',
          label: 'label.postal_code',
          required: true
        },
        {
          type: FieldType.SELECT,
          name: 'countrySocial',
          label: 'label.country',
          options: Object.keys(Country).map((country) => ({ value: country, label: 'common.countries.' + country })),
          required: true
        },
        {
          type: FieldType.TEXT,
          name: 'coordinatesSocialX',
          label: 'label.coordinates_x'
        },
        {
          type: FieldType.TEXT,
          name: 'coordinatesSocialY',
          label: 'label.coordinates_y'
        }
      ]
    },
    {
      title: 'members.extra_info',
      fields: [
        {
          type: FieldType.SELECT,
          name: 'federationId',
          label: 'clubs.federation',
          options: federationsOptions
        },
        {
          type: FieldType.TEXT,
          name: 'foundationYear',
          label: 'clubs.year_foundation'
        },
        {
          type: FieldType.TEXTAREA,
          name: 'comments',
          label: 'label.observations'
        }
      ]
    },
    {
      title: 'clubs.database_info',
      fields: [
        {
          type: FieldType.TEXT,
          name: 'host',
          label: 'clubs.host',
          objectKey: 'databaseInfo'
        },
        {
          type: FieldType.TEXT,
          name: 'database',
          label: 'clubs.database',
          objectKey: 'databaseInfo'
        },
        {
          type: FieldType.TEXT,
          name: 'username',
          label: 'clubs.username',
          objectKey: 'databaseInfo'
        },
        {
          type: FieldType.PASSWORD,
          name: 'password',
          label: 'label.password',
          objectKey: 'databaseInfo'
        },
        {
          type: FieldType.TEXT,
          name: 'port',
          label: 'clubs.port',
          objectKey: 'databaseInfo'
        }
      ]
    }
  ]
}

export const mainUserForm: Field<UserResponse>[] = [
  {
    type: FieldType.TEXT,
    name: 'name',
    label: 'label.name',
    objectKey: 'mainUser'
  },
  {
    type: FieldType.TEXT,
    name: 'lastname',
    label: 'label.lastname',
    objectKey: 'mainUser'
  },
  {
    type: FieldType.TEXT,
    name: 'email',
    label: 'label.email',
    objectKey: 'mainUser',
    required: true
  },
  {
    type: FieldType.TEXT,
    name: 'phone',
    label: 'label.phone',
    objectKey: 'mainUser'
  }
]
