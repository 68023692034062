import './styles.css'

import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { FileType } from '../../../api/models/files'
import { deleteImage } from '../../../api/uploadFiles'
import { ModalProps } from '../ContactModal'

interface ShowImageModalProps extends ModalProps {
  id: number
  imagePath: string
  fieldName: string
  onSave: (image: File | null) => void
  onChangePreview?: (file: string | null) => void
}

export const ShowImageModal = ({ isOpen, closeModal, id, imagePath, onSave, onChangePreview }: ShowImageModalProps) => {
  const { t } = useTranslation()

  const onDelete = () => {
    deleteImage({ id, type: FileType.MEMBER })
      .then(() => {
        onSave(null)
        onChangePreview?.(null)
      })
      .finally(() => closeModal())
  }

  return (
    <Modal
      isOpen={isOpen}
      // @ts-ignore
      setAppElement={'#app-body'}
      overlayClassName={'overlay'}
      className="modal-element modal-content modal-show-image mx-auto"
      shouldFocusAfterRender
      preventScroll
      shouldReturnFocusAfterClose
    >
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body mx-auto">
        <img src={imagePath} width={350} />
        <div className="d-flex justify-content-end w-100 mt-3">
          <button onClick={onDelete} className="close-modal btn btn-danger mr-2 align-self-end" data-bs-dismiss="modal">
            <i className="fas fa-trash-alt mr-1"></i>
            {t('app.remove')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ShowImageModal
