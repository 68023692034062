import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line simple-import-sort/imports
import ReactSelect, { components, MultiValueGenericProps } from 'react-select'

import { saveAttendance } from '../../../api/attendance'
import { AttendanceDateLabel, AttendanceLabelResponse, AttendanceSheet as AttendanceSheetModel } from '../../../api/models/attendance'
import { Option } from '../../../api/models/club'
import CustomToast from '../../common/CustomToast'
import { AttendanceSheetProps } from '../AttendanceSheet'
import { customStyles } from './styles'

export type MultiValueOption = AttendanceLabelResponse & Option & { color: string }

const MultiValueContainer = (props: MultiValueGenericProps<MultiValueOption>) => {
  return (
    <div className={`badge bg-${props.data.color} mr-1`} style={{ padding: '0.25em' }}>
      <components.MultiValueContainer {...props} />
    </div>
  )
}

export const AttendanceTable = (sheet: AttendanceSheetProps) => {
  const { attendance, attendanceLabels, labelOptions, selectedDate, loading } = sheet
  const { t } = useTranslation()

  const [attendanceSheet, setAttendanceSheet] = useState<AttendanceSheetModel>()
  const [attendedMembers, setAttendedMembers] = useState<Array<number>>([])
  const [selectedLabels, setSelectedLabels] = useState<Array<AttendanceDateLabel>>([])

  useEffect(() => {
    setAttendanceSheet(sheet)
    setAttendedMembers(attendance || [])
    setSelectedLabels(attendanceLabels || [])
  }, [sheet])

  const getDefaultLabelsForMember = (memberId: number) => {
    const saved = selectedLabels?.find((label) => label.member === memberId)?.labels?.map((label) => label.id)
    return labelOptions.filter((label) => saved?.includes(label.id))
  }

  const onChange = (id: number) => {
    if (!attendedMembers?.includes(id)) {
      setAttendedMembers([...attendedMembers, id])
    } else {
      setAttendedMembers(attendedMembers.filter((member) => member !== id))
    }
  }

  const handleSubmit = () => {
    const attendanceRequest = {
      date: selectedDate,
      attendance: sheet.members.map((member) => ({
        member: member.id!,
        attended: attendedMembers.includes(member.id!),
        labels: selectedLabels.filter((label) => label.member === member.id)[0]?.labels || []
      }))
    }

    saveAttendance(attendanceRequest).then((response) => {
      if (response.success) {
        toast.custom(<CustomToast text={t('notifications.created')} />)
      }
    })
  }

  return (
    <>
      <div className="table-responsive overflow-auto">
        <table id="attendanceSheet" className="table table-bordered table-striped table-sm">
          <thead>
            <tr>
              <td>{t('label.name')}</td>
              <td className="fit">{t('label.category')}</td>
              <td className="fit">{t('attendance.label_attendance')}</td>
              <td>{t('attendance.label_training')}</td>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr className="text-center loader-container overlay flex-column">
                <i className="fas fa-3x fa-sync-alt mb-2"></i>
                <span>{t('app.loading')}</span>
              </tr>
            )}
            {attendanceSheet?.members?.map((member, index) => (
              <tr key={index}>
                <td className="text-sm align-middle">
                  {member.name} {member.lastname}
                </td>
                <td className="w-auto text-sm text-center align-middle">{member.category}</td>
                <td className="text-center align-middle">
                  <div className="icheck-primary d-inline">
                    <input
                      type="checkbox"
                      id={`checkboxPrimary${attendanceSheet.id}-${member.id}`}
                      onChange={() => onChange(member.id!)}
                      checked={attendedMembers.includes(member.id!)}
                    />
                    <label htmlFor={`checkboxPrimary${attendanceSheet.id}-${member.id}`}></label>
                  </div>
                </td>
                <td>
                  <ReactSelect
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    value={getDefaultLabelsForMember(member.id!)}
                    isMulti
                    options={labelOptions}
                    components={{ MultiValueContainer }}
                    styles={customStyles}
                    onChange={(selected) => {
                      if (selectedLabels?.find((label) => label.member === member.id)) {
                        // @ts-ignore
                        setSelectedLabels((prev) => [...prev.filter((lab) => lab.member !== member.id), { member: member.id!, labels: selected }])
                      } else {
                        // @ts-ignore
                        setSelectedLabels((prev) => {
                          return [...prev!, { member: member.id!, labels: selected }]
                        })
                      }
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-12">
          <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>
            {t('app.save')}
          </button>
        </div>
      </div>
    </>
  )
}

export default AttendanceTable
