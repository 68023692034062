import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { getMembers } from '../.../../../../api/member'
import { PaginatedData } from '../.../../../../api/models/common'
import { MemberQueryRequest, MemberResponse } from '../.../../../../api/models/member'

export interface ListTitleProps {
  fields: string[]
  showOrderOptions?: boolean
  filter?: MemberQueryRequest
  setPagination?: Dispatch<SetStateAction<PaginatedData<MemberResponse>>>
}

const orderByFields = ['name', 'lastname', 'birthdate', 'weapon', 'status', 'created_at']

export const ListTitle = ({ fields = [], filter, showOrderOptions, setPagination }: ListTitleProps) => {
  const { t } = useTranslation()

  const onClickArrow = (field: keyof MemberResponse, direction: 'ASC' | 'DESC') => {
    getMembers({ ...filter, orderBy: { field: (field as string).replaceAll('_short', '') as keyof MemberResponse, direction } }).then((response) =>
      setPagination?.(response as PaginatedData<MemberResponse>)
    )
    localStorage.setItem(
      'memberFilter',
      JSON.stringify({ ...filter, orderBy: { field: (field as string).replaceAll('_short', '') as keyof MemberResponse, direction } })
    )
  }

  return (
    <thead>
      <tr>
        {fields.map((field) => (
          <th key={field}>
            <div className="row justify-content-between align-items-center">
              <p className="mb-0 ml-2">{t(`label.${field}`)}</p>
              {showOrderOptions && orderByFields.includes(field.replaceAll('_short', '')) && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <i
                    className="fa fa-caret-up mx-2 text-lg text-gray line-height-07"
                    onClick={() => onClickArrow(field as keyof MemberResponse, 'ASC')}
                  ></i>
                  <i
                    className="fa fa-caret-down mx-2 text-lg text-gray line-height-07"
                    onClick={() => onClickArrow(field as keyof MemberResponse, 'DESC')}
                  ></i>
                </div>
              )}
            </div>
          </th>
        ))}
        <th></th>
      </tr>
    </thead>
  )
}

export default ListTitle
