import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyFormConfigElement, PrivacyFormConfigRequest, PrivacyOptionType } from '../../../../../api/models/formsConfig'
import Checkbox from '../../../../forms/components/Checkbox'

interface PrivacyFormConfigCardInputProps extends PrivacyFormConfigElement {
  disabled?: boolean
  setPrivacyConfig: Dispatch<SetStateAction<PrivacyFormConfigRequest>>
}

export const PrivacyFormConfigCardInput = ({
  id,
  name,
  type,
  showInForm,
  required,
  defaultDoc,
  hasCustomDoc,
  customDoc,
  documentPath,
  disabled,
  setPrivacyConfig
}: PrivacyFormConfigCardInputProps) => {
  const { t } = useTranslation()

  const toggleShowInForm = ({ name, value }: { name: keyof PrivacyFormConfigRequest; value: boolean }) => {
    setPrivacyConfig((prev) => ({ ...prev, [name]: { ...prev[name], showInForm: value } }))
    if (!value) {
      setPrivacyConfig((prev) => ({ ...prev, [name]: { ...prev[name], required: false } }))
      setPrivacyConfig((prev) => ({ ...prev, [name]: { ...prev[name], defaultDoc: false } }))
    }
  }

  return (
    <tr>
      <td className="fit text-sm py-3">{t(`accept_privacy.${name}`)}</td>
      {disabled ? (
        <>
          <td className="fixed">
            <i className={`fa ${showInForm ? 'fa-check text-success' : 'fa-ban text-danger'} text-lg`}></i>
          </td>
          <td className="fixed">
            <i className={`fa ${required ? 'fa-check text-success' : 'fa-times text-danger'} text-lg`}></i>
          </td>
          {type !== PrivacyOptionType.FIELD && (
            <td>
              <div className="d-flex flex-row align-items-center">
                {documentPath && (
                  <a href={`${process.env.REACT_APP_LOGO_BASE_URL}${documentPath}`} target="_blank" rel="noreferrer">
                    <i className="fa fa-file-pdf-o text-xl mr-1"></i>
                  </a>
                )}
              </div>
            </td>
          )}
        </>
      ) : (
        <>
          <td className="fixed">
            <Checkbox name={`showInForm${id}`} value={showInForm} onChange={() => toggleShowInForm({ name, value: !showInForm })} />
          </td>
          <td className="fixed">
            <Checkbox
              name={`required${id}`}
              value={required}
              onChange={() => setPrivacyConfig((prev) => ({ ...prev, [name]: { ...prev[name], required: !required } }))}
              disabled={disabled || !showInForm}
            />
          </td>
          {type !== PrivacyOptionType.FIELD && (
            <>
              <td className="fixed">
                <Checkbox
                  name={`defaultDoc${id}`}
                  value={defaultDoc}
                  onChange={() => setPrivacyConfig((prev) => ({ ...prev, [name]: { ...prev[name], defaultDoc: !defaultDoc } }))}
                  disabled={disabled || !showInForm}
                />
              </td>
              <td className="text-sm">
                <div className="d-flex flex-row align-items-center">
                  {documentPath && (
                    <a href={`${process.env.REACT_APP_LOGO_BASE_URL}${documentPath}`} target="_blank" rel="noreferrer">
                      <i className="fa fa-file-pdf-o text-xl mr-1"></i>
                    </a>
                  )}
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id={`exampleInputFile${id}`}
                        onChange={(e) => {
                          setPrivacyConfig((prev) => ({ ...prev, [name]: { ...prev[name], customDoc: e.target.files![0] } }))
                        }}
                        disabled={disabled || !showInForm || defaultDoc}
                      />
                      <label className="custom-file-label" htmlFor={`exampleInputFile${id}`}>
                        {customDoc ? customDoc.name : t('Seleccionar archivo')}
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <i className="fas fa-paperclip"></i>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </>
          )}
        </>
      )}
    </tr>
  )
}

export default PrivacyFormConfigCardInput
