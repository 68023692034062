import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { MemberRegistrationResponse } from '../../../api/models/reports'
import { languageForLocaleDate } from '../../../translations'

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController)

interface MemberRegistrationReportProps {
  reportData: Array<MemberRegistrationResponse>
  height?: number
}

export const MemberRegistrationReport = ({ reportData, height = 350 }: MemberRegistrationReportProps) => {
  const { t } = useTranslation()

  const data = {
    labels: reportData.map((data) =>
      new Date(`${data.year}-${data.month}-01`).toLocaleDateString(languageForLocaleDate, { month: 'long', year: 'numeric' })
    ),
    datasets: [
      {
        type: 'bar' as const,
        label: t('reports.member.new_registrations'),
        data: reportData.map((data) => data.newRegistrations),
        borderColor: 'green',
        backgroundColor: 'lightgreen',
        borderWidth: 2,
        borderRadius: {
          topLeft: Number.MAX_VALUE,
          topRight: Number.MAX_VALUE
        },
        stack: 'stack0',
        minBarThickness: 20,
        maxBarThickness: 40
      },
      {
        type: 'bar' as const,
        label: t('reports.member.terminations'),
        data: reportData.map((data) => data.terminations * -1),
        borderColor: 'red',
        backgroundColor: '#ff8989',
        borderWidth: 2,
        borderRadius: {
          bottomLeft: Number.MAX_VALUE,
          bottomRight: Number.MAX_VALUE
        },
        stack: 'stack0',
        minBarThickness: 20,
        maxBarThickness: 40
      },
      {
        type: 'line' as const,
        label: t('reports.member.active_members'),
        data: reportData.map((data) => data.activeMembers),
        borderColor: 'orange',
        borderWidth: 3,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 6,
        backgroundColor: 'gold'
      }
    ]
  }

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  }

  return <Chart type="bar" data={data} options={options} style={{ width: '100%', minHeight: 300, maxHeight: height }} />
}

export default MemberRegistrationReport
