import { useTranslation } from 'react-i18next'

import ChangelogCard from '../components/cards/ChangelogCard'
import Layout from '../components/common/Layout'

export const Changelog = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid pt-5">
          <ChangelogCard />
        </div>
      </section>
    </Layout>
  )
}

export default Changelog
