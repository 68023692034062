import { useTranslation } from 'react-i18next'

import Layout from '../components/common/Layout'

export const NotGranted = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid mt-5">
          <div className="alert alert-danger alert-dismissible">
            <h5>
              <i className="icon fas fa-ban"></i> {t('notifications.unauthorized_title')}
            </h5>
            <p>{t('notifications.unauthorized')}</p>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <button className="btn btn-primary" onClick={() => window.history.go(-2)}>
            {t('app.go_back')}
          </button>
        </div>
      </section>
    </Layout>
  )
}

export default NotGranted
