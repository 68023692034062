import axios, { AxiosResponse } from 'axios'

import { ClubConfigResponse, ClubResponse, ClubSettingsResponse, GetClubRequest } from './models/club'
import { SuccessResponse } from './models/common'

export const GET_CLUB_CONFIG = (clubCode: string) => `club/${clubCode}/config`
export const GET_CLUB_SETTINGS = (clubCode: string) => `club/${clubCode}/settings`
export const GET_CLUBS = 'clubs'
export const CLUB_URL = 'club'

// TODO change to an api with only CONFIG for club.
export const getClubConfig: (_: GetClubRequest) => Promise<ClubConfigResponse> = ({ clubCode }) => {
  return axios.get<ClubConfigResponse>(GET_CLUB_CONFIG(clubCode)).then((response: AxiosResponse) => response?.data)
}

export const getClubs: () => Promise<ClubResponse[]> = () => {
  return axios.get<ClubResponse[]>(GET_CLUBS).then((response: AxiosResponse) => response?.data)
}

export const getClub: (_: GetClubRequest) => Promise<ClubResponse> = ({ clubCode }) => {
  return axios.get<ClubResponse>(`${CLUB_URL}/${clubCode}`).then((response: AxiosResponse) => response?.data)
}

export const createClub: (_: ClubResponse) => Promise<SuccessResponse<{}>> = (club) => {
  return axios.post<SuccessResponse<{}>>(CLUB_URL, club).then((response: AxiosResponse) => response?.data)
}

export const updateClub: (_: ClubResponse) => Promise<SuccessResponse<{}>> = (club) => {
  return axios.patch<SuccessResponse<{}>>(`${CLUB_URL}/${club.code}`, club).then((response: AxiosResponse) => response?.data)
}

export const getClubSettings: (_: GetClubRequest) => Promise<ClubSettingsResponse> = ({ clubCode }) => {
  return axios.get<ClubSettingsResponse>(GET_CLUB_SETTINGS(clubCode)).then((response: AxiosResponse) => response?.data)
}

export const updateClubSettings: (request: ClubSettingsResponse & GetClubRequest) => Promise<SuccessResponse<ClubSettingsResponse>> = ({
  clubCode,
  ...request
}) => {
  return axios.patch<SuccessResponse<ClubSettingsResponse>>(GET_CLUB_SETTINGS(clubCode), request).then((response: AxiosResponse) => response?.data)
}
