import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'

import { UserResponse } from '../../api/models/user'
import { createUser, getUser, updateUser } from '../../api/user'
import CustomToast from '../../components/common/CustomToast'
import Layout from '../../components/common/Layout'
import { UserForm } from '../../components/forms/UserForm'
import useAuthContext from '../../contexts/hooks/useAuthContext'
import useClubConfigContext from '../../contexts/hooks/useClubConfigContext'

export type UserErrors = Partial<{
  [key in keyof UserResponse | 'nameLastname']?: string
}>

export const CreateUser = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clubCode } = useClubConfigContext()
  const { user: userInfo, isMasterUser } = useAuthContext()

  const urlParams = useParams()
  const userId = urlParams?.id || false

  const url = useLocation()
  const editProfile = url?.pathname?.includes('profile/edit')

  const [user, setUser] = useState<Partial<UserResponse>>({})
  const [errors, setErrors] = useState<UserErrors>({})
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    if (userId || editProfile) {
      updateUser(user as UserResponse)
        .then(() => {
          toast.custom(<CustomToast text={t('notifications.updated')} />)
          navigate(`/${clubCode}/users`)
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error.errors) {
            setErrors(error.errors)
          }
        })
    } else {
      createUser(user as UserResponse)
        .then(() => {
          toast.custom(<CustomToast text={t('notifications.created')} />)
          navigate(`/${clubCode}/users`)
        })
        .catch((e) => {
          const error = e?.response?.data
          if (error.errors) {
            setErrors(error.errors)
          }
        })
    }
  }

  useEffect(() => {
    if (userId || (editProfile && userInfo?.id)) {
      const userRequestId = editProfile && userInfo ? userInfo.id : userId || ''
      if (Object.keys(user).length === 0) setLoading(true)
      getUser(userRequestId!).then((response) => {
        setUser(response)
        setLoading(false)
      })
    }
    if (!userId && !isMasterUser) {
      setUser({ ...user, club: userInfo?.clubId })
    }
  }, [userId, editProfile, userInfo])

  return (
    <Layout>
      <section className="content">
        <div className="col-12">
          <h1 className="pt-3">{userId || editProfile ? t('users.update_user') : t('users.create_user')}</h1>
        </div>
        <UserForm user={user} errors={errors} setUser={setUser} handleSubmit={handleSubmit} loading={loading} />
      </section>
    </Layout>
  )
}

export default CreateUser
