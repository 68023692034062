import { Dispatch, SetStateAction, useState } from 'react'

import { Option } from '../../../../../api/models/club'
import { ParamType } from '../../../../../api/models/params'
import { deleteParam, editParam } from '../../../../../api/params'
import { EditRound } from '../../../../../assets/img/EditRound'

export interface ParamCardItemProps extends Option {
  type: Exclude<ParamType, ParamType.COLOR | ParamType.KNOWN_OPTIONS>
  editAvailable?: boolean
  setAction: Dispatch<SetStateAction<Array<Option>>>
}

export const ParamCardItem = ({ value, label, type, editAvailable, setAction }: ParamCardItemProps) => {
  const [editable, setEditable] = useState(false)
  const [item, setItem] = useState(label)

  const onDelete = () => {
    deleteParam(value).then((response) => {
      if (response.success && response.data) {
        setAction(response.data.map((origin) => ({ value: origin.id, label: origin.value })))
      }
    })
  }

  const onEdit = () => {
    setEditable(true)
  }

  const onSave = () => {
    setEditable(false)
    editParam({ type, id: value as number, value: item }).then((response) => {
      if (response.success && response.data) {
        setAction(response.data.map((origin) => ({ value: origin.id, label: origin.value })))
      }
    })
  }

  return (
    <tr key={value}>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          {editable ? <input type="text" value={item} onChange={(e) => setItem(e.target.value)} /> : <p className="mb-0">{label}</p>}

          {editAvailable && (
            <div className="row align-items-center">
              <div className="mr-1">
                {editable ? (
                  <a onClick={onSave}>
                    <i className={`fa fa-check-circle color-success`}></i>
                  </a>
                ) : (
                  <a onClick={onEdit}>
                    <EditRound />
                  </a>
                )}
              </div>
              <a onClick={onDelete}>
                <i className="fa fa-minus-circle color-danger mr-1"></i>
              </a>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default ParamCardItem
