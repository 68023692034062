import './style.css'

import { E164Number } from 'libphonenumber-js'
import PhoneInputWithCountrySelect, { Country } from 'react-phone-number-input'

import useClubConfigContext from '../../../../contexts/hooks/useClubConfigContext'
import { DEFAULT_COLUMN_SIZE } from '../../../../helpers/layout'
import phoneInputLangauges from '../../../../translations/react-phone-number-input'
import FormControl, { FormFieldProps } from '../FormControl'

interface PhoneInputProps extends Omit<FormFieldProps, 'onChange' | 'value'> {
  value: E164Number | string
  onChange: (_: E164Number) => void
}

export const PhoneInput = (props: PhoneInputProps) => {
  const {
    name,
    label,
    required = false,
    disabled = false,
    value,
    columnSize = DEFAULT_COLUMN_SIZE,
    onChange,
    error,
    size,
    placeholder,
    removeError
  } = props

  const { clubCountry } = useClubConfigContext()
  return (
    <FormControl label={label} columnSize={columnSize} required={required} error={error} labelClassName={size === 'small' ? 'col-form-label-sm' : ''}>
      <PhoneInputWithCountrySelect
        className={size === 'small' ? 'form-control-sm' : 'form-control'}
        value={value as string}
        name={name as string}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={removeError}
        defaultCountry={clubCountry as Country}
        labels={phoneInputLangauges()}
      />
    </FormControl>
  )
}

export default PhoneInput
