import { isValidPhoneNumber } from 'react-phone-number-input'
import { RowHook } from 'react-spreadsheet-import/types/types'

import { DocumentationType, TutorType } from '../../api/models/common'
import { MemberResponse, PaymentData } from '../../api/models/member'
import { TutorImportFields } from '../../components/modals/ImportMembersModal'
import i18n from '../../translations'
import { formatInternationalPhone } from '../format'
import { isValidIBANNumber } from './ibanValidator'
import { DNIValidation, NIEValidation } from './locale/spanishDocuments'

const validateTutor: RowHook<'birthdate' | keyof TutorImportFields> = (data, addError) => {
  if (data?.birthdate && new Date(data.birthdate as string).getFullYear() + 18 > new Date().getFullYear()) {
    if (!data.tutorName) {
      addError('tutorName', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (!data.tutorLastname) {
      addError('tutorLastname', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (!data.tutorDocumentType) {
      addError('tutorDocumentType', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (!data.tutorDocumentValue) {
      addError('tutorDocumentValue', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (!data.tutorEmail) {
      addError('tutorEmail', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (!data.tutorPhone) {
      addError('tutorPhone', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (!data.tutorType) {
      addError('tutorType', { message: i18n.t('validation.required_tutor'), level: 'error' })
    }
    if (
      (data.tutorDocumentType === DocumentationType.DNI && !DNIValidation(data.tutorDocumentValue as string)) ||
      (data.tutorDocumentType === DocumentationType.NIE && !NIEValidation(data.tutorDocumentValue as string))
    ) {
      addError('tutorDocumentValue', {
        message: i18n.t('validation.dni'),
        level: 'error'
      })
    }
  }
  return data
}

const validateSpanishDocument: RowHook<'nationality' | 'documentType' | 'documentValue'> = (data, addError) => {
  if (data.nationality !== 'ES' && ![DocumentationType.NIE, DocumentationType.PASSPORT].includes(data.documentType as DocumentationType)) {
    addError('documentType', { message: i18n.t('validation.foreign_document_type'), level: 'error' })
  }
  if (
    (data.documentType === DocumentationType.DNI && !DNIValidation(data.documentValue as string)) ||
    (data.documentType === DocumentationType.NIE && !NIEValidation(data.documentValue as string))
  ) {
    addError('documentValue', {
      message: i18n.t('validation.dni'),
      level: 'error'
    })
  }

  return data
}

export const validateMember: RowHook<keyof MemberResponse | keyof TutorImportFields | keyof PaymentData> = async (data, addError) => {
  const transformedData = {
    ...data,
    lastname: (data?.lastname as string)?.toUpperCase(),
    phone: data.phone ? formatInternationalPhone(data.phone as string) : undefined,
    phone2: data.phone2 ? formatInternationalPhone(data.phone2 as string) : undefined,
    tutorPhone: data.tutorPhone ? formatInternationalPhone(data.tutorPhone as string) : undefined,
    country: data.country || 'ES',
    nationality: data.nationality || 'ES',
    documentType: data.documentType || DocumentationType.DNI,
    tutorType: data.tutorName && !data.tutorType ? TutorType.LEGAL : data.tutorType || TutorType.LEGAL
  }
  // Validation
  if (transformedData.phone && !isValidPhoneNumber(transformedData.phone as string)) {
    addError('phone', { message: i18n.t('validation.invalid_format'), level: 'error' })
  }
  if (transformedData.phone2 && !isValidPhoneNumber(transformedData.phone2 as string)) {
    addError('phone2', { message: i18n.t('validation.invalid_format'), level: 'error' })
  }
  if (transformedData.tutorPhone && !isValidPhoneNumber(transformedData.tutorPhone as string)) {
    addError('tutorPhone', { message: i18n.t('validation.invalid_format'), level: 'error' })
  }
  if (
    transformedData.email &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      transformedData.email as string
    )
  ) {
    addError('email', { message: i18n.t('validation.email'), level: 'error' })
  }
  if (
    transformedData.email2 &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      transformedData.email2 as string
    )
  ) {
    addError('email2', { message: i18n.t('validation.email'), level: 'error' })
  }
  if (
    transformedData.tutorEmail &&
    !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      transformedData.tutorEmail as string
    )
  ) {
    addError('tutorEmail', { message: i18n.t('validation.email'), level: 'error' })
  }
  if (transformedData?.iban && !(await isValidIBANNumber(transformedData.iban as string)).valid) {
    addError('iban', { message: i18n.t('validation.invalid_iban'), level: 'error' })
  }
  validateSpanishDocument(transformedData, addError, [])
  validateTutor(transformedData, addError, [])

  // Transformation
  return transformedData
}
