import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getMemberAttendance } from '../../../api/memberAttendance'
import { MemberAttendanceResponse } from '../../../api/models/memberAttendance'
import FormSectionHeader from '../../forms/components/FormSectionHeader'
import MemberAttendanceItem from './MemberAttendanceItem'

export interface MemberAttendanceProps {
  memberId: number
}

export const MemberAttendance = ({ memberId }: MemberAttendanceProps) => {
  const { t } = useTranslation()

  const [attendance, setAttendance] = useState<MemberAttendanceResponse | null>(null)

  useEffect(() => {
    getMemberAttendance(memberId.toString()).then((attendance) => {
      setAttendance(attendance)
    })
  }, [memberId])

  if (!attendance || !memberId) {
    return null
  }

  return (
    <div className="card">
      <FormSectionHeader title={t('menu.attendance')} />
      <div className="card-body">
        <MemberAttendanceItem
          actualMonth={attendance.actualMonth}
          actualSeason={attendance.actualSeason}
          previousMonth={attendance.previousMonth}
          previousSeason={attendance.previousSeason}
          name={t('member_attendance.general')}
          color="light"
        />
        {attendance.labels.map((label, index) => (
          <MemberAttendanceItem
            key={index}
            actualMonth={label.actualMonth}
            actualSeason={label.actualSeason}
            previousMonth={label.previousMonth}
            previousSeason={label.previousSeason}
            name={label.name}
            color={label.color}
          />
        ))}
      </div>
    </div>
  )
}

export default MemberAttendance
