import axios, { AxiosResponse } from 'axios'

import { SuccessResponse } from './models/common'
import { ContactInfoRequest, ContactRequest, InfoRequestResponse } from './models/contact'

export const CONTACT_URL = 'contact/send'
export const INFO_REQUESTS_URL = 'info-requests'
export const INFO_REQUEST_URL = 'info-request'

export const contact: (_: ContactRequest) => Promise<SuccessResponse<{}>> = ({ email, reason, clubCode, observations }) => {
  return axios.post<SuccessResponse<{}>>(CONTACT_URL, { email, clubCode, reason, observations }).then((response: AxiosResponse) => response?.data)
}

export const getInfoRequests: () => Promise<InfoRequestResponse[]> = () => {
  return axios.get<InfoRequestResponse[]>(INFO_REQUESTS_URL).then((response: AxiosResponse) => response?.data)
}

export const getInfoRequest: (id: number | string) => Promise<InfoRequestResponse> = (id) => {
  return axios.get<InfoRequestResponse>(`${INFO_REQUEST_URL}/${id}`).then((response: AxiosResponse) => response?.data)
}

export const createContactInfoRequest: (_: ContactInfoRequest & { clubCode: string }) => Promise<SuccessResponse<{}>> = ({ clubCode, ...body }) => {
  return axios.post<SuccessResponse<{}>>(`${clubCode}/${INFO_REQUEST_URL}`, body).then((response: AxiosResponse) => response?.data)
}

export const createInfoRequest: (_: InfoRequestResponse) => Promise<SuccessResponse<{}>> = (body) => {
  return axios.post<SuccessResponse<{}>>(INFO_REQUEST_URL, body).then((response: AxiosResponse) => response?.data)
}

export const updateInfoRequest: (_: InfoRequestResponse) => Promise<SuccessResponse<{}>> = ({ id, ...body }) => {
  return axios.patch<SuccessResponse<{}>>(`${INFO_REQUEST_URL}/${id}`, body).then((response: AxiosResponse) => response?.data)
}
