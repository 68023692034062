import useAuthContext from '../contexts/hooks/useAuthContext'
import useClubConfigContext from '../contexts/hooks/useClubConfigContext'

export const useNotGranted = (permission: string) => {
  const { clubCode: contextClubCode } = useClubConfigContext()
  const { permissions: contextPermission } = useAuthContext()

  const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions') || '') : contextPermission
  const clubCode = localStorage.getItem('clubCode') || contextClubCode

  if (!permissions?.includes(permission)) {
    return clubCode
  }

  return null
}

export default useNotGranted
