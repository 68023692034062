import { PaginatedData } from '../../../api/models/common'
import { UserResponse } from '../../../api/models/user'
import Pagination from '../../../components/common/Pagination'
import useAuthContext from '../../../contexts/hooks/useAuthContext'
import ListTitle from '../../common/ListTitle'
import UserListItem from '../UserListItem'

const fields = ['name', 'lastname', 'email', 'phone', 'clubCode', 'role']

interface UserListProps extends PaginatedData<UserResponse> {
  setPagination: (_: PaginatedData<UserResponse>) => void
}

export const UserList = (props: UserListProps) => {
  const { links, data: users, setPagination } = props

  const { isMasterUser } = useAuthContext()

  return (
    <div className="card-body">
      <table id="tableUsers" className="float-right table table-bordered table-striped">
        <ListTitle fields={!isMasterUser ? fields.filter((field) => field !== 'clubCode') : fields} />
        <tbody>{users?.map((user) => <UserListItem key={user.id} {...user} />)}</tbody>
      </table>
      {links && <Pagination {...props} setPagination={setPagination} />}
    </div>
  )
}

export default UserList
